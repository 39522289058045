import React from 'react'

import PelicanSelectionControlGroup from 'uikit/components/SelectionControlGroup'

const Input = props => (
  <PelicanSelectionControlGroup
    {...props}
    theme="alemira"
  />
)

export default Input
