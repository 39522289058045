import React from 'react'
import { compose } from 'recompose'
import { reduxForm } from 'redux-form'

import Button from 'uikit/components/Button'
import FormField from 'uikit/components/FormField'
import Input from 'uikit/components/Input'
import Spinner from 'uikit/components/Spinner'
import { isEmail } from 'utils/validator'
import { resetPassword } from 'domains/user'
import {
  MediumHeader,
  Paragraph,
} from 'uikit/components/Typography'

import { t } from 'i18next'
import styles from './assets/Forms.module.scss'

const ResetPasswordForm = ({
  handleSubmit, error, submitting, submitFailed, submitSucceeded,
}) => (
  <form className={styles.Form} onSubmit={handleSubmit}>
    <MediumHeader className={styles.Header}>
      {t('Auth:header_reset_password')}
    </MediumHeader>

    <Paragraph className={styles.Description}>
      {t('Auth:reset_password_text')}
    </Paragraph>
    <br />

    {submitting && (
      <div className={styles.Spinner}>
        <Spinner />
      </div>
    )}

    {!submitSucceeded
      && !submitting && (
      <React.Fragment>
        <FormField
          name="email"
          label={t('Auth:email')}
          fashion="slim"
          fullWidth
          component={Input}
        />

        <div className={styles.ErrorMessage}>{error && submitFailed && <span>{error}</span>}</div>
        <Button fullWidth>
          {t('Auth:send')}
        </Button>
      </React.Fragment>
    )}

    {submitSucceeded
      && !submitting && (
      <p className={styles.SuccessMessage}>
        {t('Auth:reset_password_send_email')}
      </p>
    )}
  </form>
)

const validate = (values) => {
  const errors = {}
  const errorMessage = t('Auth:wrong_email')

  if (!isEmail(values.email)) {
    errors._error = errorMessage
  }

  return errors
}

const onSubmit = ({ email }, dispatch, { form }) => {
  dispatch(
    resetPassword({
      meta: { form },
      email,
    }),
  )
}

const enhance = compose(
  reduxForm({
    form: 'resetPassword',
    validate,
    onSubmit,
  }),
)

export default enhance(ResetPasswordForm)
