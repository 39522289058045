import { get } from 'lodash'
import { normalize } from 'normalizr'
import { routerRedux } from 'dva/router'
import HttpStatus from 'http-status-codes'

import { createAction } from 'utils/store'

import * as api from './api'
import {
  FETCH_SCHOOL_REQUEST,
  FETCH_SCHOOL_REQUEST_SUCCESS,
  FETCH_SCHOOL_REQUEST_FAIL,
} from './constants'
import schoolSchema from './schema'

function* fetchSchool({
  courseLineId,
  schoolId,
  entity,
  key,
}, {
  call,
  put,
}) {
  try {
    const response = yield call(api.getSchool,
      {
        schoolId,
        courseLineId,
      })

    const school = get(response, 'data')
    yield put(createAction(FETCH_SCHOOL_REQUEST_SUCCESS, {
      entity,
      key,
      data: normalize(school, schoolSchema),
    }))
  } catch (error) {
    yield put(createAction(FETCH_SCHOOL_REQUEST_FAIL, {
      entity,
      key,
      error,
    }))
    if (error.response.status >= HttpStatus.INTERNAL_SERVER_ERROR) {
      throw error
    }
    yield put(routerRedux.replace('/404/'))
  }
}

export default {
  [FETCH_SCHOOL_REQUEST]: fetchSchool,
}
