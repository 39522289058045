import { trim } from 'lodash'

// eslint-disable-next-line no-useless-escape,max-len
const EMAIL_REGEX = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i


// validation
export function isEmail(email) {
  return EMAIL_REGEX.test(trim(email))
}


// sanitizers
export function formatText(value) {
  return trim(value)
}
