import { schema } from 'normalizr'

import { readMapper } from './adapters'
import {
  MODEL,
} from './constants'

export const alemiraCourseSchema = new schema.Entity(
  MODEL,
  {},
  {
    processStrategy: readMapper,
  },
)
