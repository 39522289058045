import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { isUndefined, get } from 'lodash'
import { compose } from 'recompose'
import { connect } from 'dva'
import {
  change,
  clearSubmitErrors,
  getFormMeta,
  formValueSelector,
  reduxForm,
} from 'redux-form'
import { withRouter } from 'dva/router'
import { t } from 'i18next'
import { Trans } from 'react-i18next'
import cn from 'classnames'

import Button, { BUTTON_THEMES, BUTTON_SIZES } from 'uikit/components/Button'
import camelToSnake from 'utils/mappers/camelToSnake'
import FormField from 'uikit/components/FormField'
import Input from 'uikit/components/Input'
import Link from 'uikit/components/Link'
import { showInformation } from 'uikit/components/Notification'
import SelectionControlGroup from 'uikit/components/SelectionControlGroup'
import { MediumHeader } from 'uikit/components/Typography'
import {
  createUser,
  getFreeLogin,
  selectFreeLogin,
} from 'domains/user'
import {
  selectSelectedPurchase,
} from 'domains/payment'
import { joinCourse } from 'domains/course'
import { reachGoal, GOAL_NAMES } from 'domains/ym'
import {
  formatText,
  isEmail,
} from 'utils/validator'

import {
  isMainPagePath,
  isLessonPagePath,
  isCoursePagePath,
} from 'utils/urls'

import styles from './assets/Forms.module.scss'

const FORM_NAME = 'registerForm'

const LINKS = {
  TERMS_OF_SERVICE: '/pages/policy/',
  RULES           : '/pages/rules/',
  OFERTA          : '/pages/oferta/',
}

class RegisterUserForm extends Component {
  state = {
    loginEdited: false,
  }

  componentDidMount() {
    const { getFreeLogin } = this.props

    getFreeLogin()
  }

  componentDidUpdate() {
    const {
      freeLogin,
      registeredByParent,
      dispatch,
    } = this.props
    const {
      loginEdited,
    } = this.state

    if (isUndefined(freeLogin)) {
      return
    }

    if (loginEdited) {
      return
    }


    if (registeredByParent) {
      this.setState({
        loginEdited: true,
      }, () => {
        dispatch(change(FORM_NAME, 'username', freeLogin))
      })
    } else {
      dispatch(change(FORM_NAME, 'username', null))
    }
  }

  render() {
    const {
      handleSubmit,
      submitting,
      registeredByParent,
      location,
    } = this.props

    return (
      <React.Fragment>
        <form className={styles.Form} onSubmit={handleSubmit}>
          <MediumHeader className={styles.Header}>
            {
              (location.pathname === '/add-course/' || location.pathname === '/add-course')
                ? t('Auth:header_course')
                : t('Auth:header_registration')
            }
          </MediumHeader>

          <FormField
            component={SelectionControlGroup}
            controlType="radio"
            simpleValue
            valueKey="value"
            name="registeredByParent"
            options={[
              {
                label: t('Auth:apply_child'),
                value: true,
              },
              {
                label: t('Auth:self_education'),
                value: false,
              },
            ]}
          />
          <br />
          <br />
          <FormField
            component={Input}
            name="firstName"
            label={registeredByParent ? t('Auth:child_name') : t('Auth:name')}
            fashion="slim"
            fullWidth
            format={formatText}
          />
          <br />
          <br />
          <FormField
            component={Input}
            name="lastName"
            label={t('Auth:surname')}
            fashion="slim"
            fullWidth
            format={formatText}
          />
          <br />
          <br />
          {registeredByParent && (
            <React.Fragment>
              <FormField
                component={Input}
                name="username"
                label={t('Auth:login')}
                fashion="slim"
                fullWidth
                format={formatText}
              />
              <br />
              <br />
            </React.Fragment>
          )}

          <FormField
            component={Input}
            name="email"
            type="email"
            label={t('Auth:email')}
            fashion="slim"
            fullWidth
            format={formatText}
          />
          <br />
          <br />
          <FormField
            component={Input}
            name="password"
            label={t('Auth:password')}
            fashion="slim"
            type="password"
            fullWidth
            format={formatText}
          />
          <div className={styles.AgreementCheckboxWrapper}>
            <FormField
              className={styles.AgreementCheckbox}
              component={SelectionControlGroup}
              name="acceptTerms"
              controlType="checkbox"
              valueKey="value"
              simpleValue
              options={[
                {
                  value: true,
                },
              ]}
              errorTopMargin="37px"
            />
            <p>
              <Trans i18nKey="Auth:accept_personal">
                <Link to={LINKS.TERMS_OF_SERVICE} />
                <Link to={LINKS.RULES} />
                <Link to={LINKS.OFERTA} />
              </Trans>
            </p>
          </div>
          <Button
            type="submit" fullWidth
            onClick={handleSubmit} disabled={submitting}
          >
            {t('Auth:register')}
          </Button>
        </form>
      </React.Fragment>
    )
  }
}

RegisterUserForm.propTypes = {
  // eslint-disable-next-line react/no-unused-prop-types
  notifyWithMessage: PropTypes.bool,
}

const validate = ({
  firstName, lastName, email, password, acceptTerms, username, registeredByParent,
}, { formMeta }) => {
  const errors = {}
  let errorChosen = false

  if (!firstName) {
    errors.firstName = {
      text: registeredByParent ? t('Auth:enter_child_name') : t('Auth:enter_name'),
      show: !errorChosen && get(formMeta, 'firstName.touched'),
    }
    errorChosen = errorChosen || get(formMeta, 'firstName.touched')
  }
  if (!lastName) {
    errors.lastName = {
      text: registeredByParent ? t('Auth:enter_child_surname') : t('Auth:enter_surname'),
      show: !errorChosen && get(formMeta, 'lastName.touched'),
    }
    errorChosen = errorChosen || get(formMeta, 'lastName.touched')
  }
  if (registeredByParent && !username) {
    errors.username = {
      text: t('Auth:enter_login'),
      show: !errorChosen && get(formMeta, 'username.touched'),
    }
    errorChosen = errorChosen || get(formMeta, 'username.touched')
  }
  if (!(email && isEmail(email))) {
    errors.email = {
      text: t('Auth:enter_email'),
      show: !errorChosen && get(formMeta, 'email.touched'),
    }
    errorChosen = errorChosen || get(formMeta, 'email.touched')
  }
  if (!password) {
    errors.password = {
      text: t('Auth:enter_password'),
      show: !errorChosen && get(formMeta, 'password.touched'),
    }
    errorChosen = errorChosen || get(formMeta, 'password.touched')
  }
  if (isUndefined(acceptTerms) || acceptTerms.length === 0) {
    errors.acceptTerms = {
      text: t('Auth:enter_accept'),
      show: !errorChosen && (get(formMeta, 'acceptTerms.visited') || get(formMeta, 'acceptTerms.touched')),
    }
    errorChosen = errorChosen || get(formMeta, 'acceptTerms.visited') || get(formMeta, 'acceptTerms.touched')
  }

  return errors
}

const getErrorMessages = (dispatch, history) => ({
  'Пользователь с такой эл. почтой уже существует.': {
    field: 'email',
    text : (
      <Trans i18nKey="Auth:error_email_exists">
        <span />
        <Button
          theme={BUTTON_THEMES.UNIMPORTANT_LIGHT}
          size={BUTTON_SIZES.SMALL}
          className={cn(styles.ButtonInError, styles.ButtonMiddle)}
          onClick={() => history.push('/reset-password/')}
        >
          Напомнить пароль
        </Button>
        <span />
        <Button
          theme={BUTTON_THEMES.UNIMPORTANT_LIGHT}
          size={BUTTON_SIZES.SMALL}
          className={cn(styles.ButtonInError, styles.ButtonBottom)}
          onClick={() => {
            dispatch(change(FORM_NAME, 'registeredByParent', true))
            dispatch(clearSubmitErrors(FORM_NAME))
          }}
        >
          Да, записать ребёнка
        </Button>
      </Trans>
    ),
  },
  'Пользователь с таким логином уже существует.': {
    field: 'username',
    text : t('Auth:error_username_exists'),
  },
})

const onSubmit = (values, dispatch, props) => {
  const {
    form,
    location: {
      query: { code, next },
      pathname,
    },
    notifyWithMessage,
    history,
    selectedPurchase,
  } = props

  const formName = form
  const courseCode = code

  const credentials = {
    ...values,
    acceptTerms: values.acceptTerms[0],
  }

  if (isMainPagePath(pathname)) {
    dispatch(reachGoal(GOAL_NAMES.USER_REGISTERS_ON_MAIN_PAGE))
  } else if (isCoursePagePath(pathname)) {
    dispatch(reachGoal(GOAL_NAMES.USER_REGISTERS_ON_COURSE_PAGE))
  } else if (isLessonPagePath(pathname)) {
    dispatch(reachGoal(GOAL_NAMES.USER_REGISTERS_ON_LESSON_PAGE))
  }

  if (credentials.username === null) {
    delete credentials.username
  }

  const purchase = get(selectedPurchase, 'id', null)

  dispatch(
    createUser({
      formName,
      courseCode,
      personalInfo: camelToSnake(credentials),
      purchase,
      stayOnPage  : notifyWithMessage,
      onSuccess   : () => {
        if (!purchase && notifyWithMessage) {
          showInformation({ message: t('Auth:verify_email_message') })
        }
      },
      next,
      errorMessages: getErrorMessages(dispatch, history),
    }),
  )
}

const selectFormValues = formValueSelector(FORM_NAME)
const selectFormMeta = getFormMeta(FORM_NAME)

const enhance = compose(
  withRouter,

  connect(state => ({
    initialValues: {
      registeredByParent: true,
    },
    registeredByParent: selectFormValues(state, 'registeredByParent'),
    username          : selectFormValues(state, 'username'),
    freeLogin         : selectFreeLogin(state),
    formMeta          : selectFormMeta(state),
    selectedPurchase  : selectSelectedPurchase(state),
  }),
  {
    joinCourse,
    getFreeLogin,
  }),

  reduxForm({
    form       : FORM_NAME,
    shouldError: ({ initialRender }) => !initialRender,
    validate,
    onSubmit,
  }),
)

export default enhance(RegisterUserForm)
