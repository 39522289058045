import { isString } from 'lodash'
import {
  CLEAR_API_ACTION,
  CLEAR_ENTITIES_ACTION,
  CLEAR_ENTITY_ACTION,
} from 'utils/api/constants'

export const createAction = (...args) => {
  let namespace
  let name
  let payload
  let type

  switch (args.length) {
    case 3:
      [namespace, name, payload] = args
      type = `${namespace}/${name}`
      break

    case 2:
      if (isString(args[0]) && isString(args[1])) {
        [namespace, name] = args
        type = `${namespace}/${name}`
      } else {
        [type, payload] = args
      }
      break

    case 1:
      [type] = args
      break

    default:
      throw new Error(
        '`createAction` was called without any arguments.'
          + 'At least action type should be provided',
      )
  }

  return { type, ...payload }
}

export const clearApi = () => ({ type: CLEAR_API_ACTION })
export const clearEntities = () => ({ type: CLEAR_ENTITIES_ACTION })
export const clearEntity = (entity, id) => ({
  type: CLEAR_ENTITY_ACTION,
  entity,
  id,
})
