import {
  get,
  map,
  partial,
} from 'lodash'
import { createSelector } from 'reselect'

import {
  findEntity, selectEntity,
} from 'utils/api'

import { selectCourseLesson } from 'domains/courseLesson'

import {
  MODEL,
  PURCHASE_MODEL,
  PROMOCODE_MODEL,
  COURSE_WITH_GROUP_MODEL,
  PROMOCODE_KEY,
} from './constants'

export const selectCourse = (state, courseId) => selectEntity(state, MODEL, courseId)

export const selectTeacherCourse = (state, courseId) => selectEntity(state, 'teacherCourse', courseId)
export const selectCourseWithGroup = (state, courseId) => selectEntity(state, COURSE_WITH_GROUP_MODEL, courseId)
export const selectCourseLessons = createSelector(
  selectCourse,
  state => partial(selectCourseLesson, state),

  (course, courseLessonSelector) => map(get(course, 'courseLessons'), courseLessonSelector),
)

export const selectJoinCourse = state => get(state, [MODEL, 'joinCourse'])
const selectPurchaseEntity = (state, id) => findEntity(
  state,
  {
    entity: PURCHASE_MODEL,
    by    : 'id',
    key   : id,
  },
)
export const selectPurchaseEntities = (state, ids) => ids.map(id => selectPurchaseEntity(state, id))
export const selectPromoCodeData = state => selectEntity(state, PROMOCODE_MODEL, PROMOCODE_KEY)
export const selectPromoCodeOffers = state => get(selectPromoCodeData(state), 'purchases')
export const selectValidatedPromoCodeId = state => get(selectPromoCodeData(state), ['promocode', 'id'])
export const selectIsCourseBySubscription = (state, courseId) => get(selectCourse(state, courseId), 'bySubscription')

export const selectCourseLessonHintId = state => get(state, [MODEL, 'сourseLessonHintId'])
