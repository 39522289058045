import {
  get,
} from 'lodash'

import { createAction } from 'utils/store'

import * as api from './api'
import {
  UPLOAD_FILE_REQUEST,
  UPLOAD_FILE_SUCCESS,
  UPLOAD_FILE_FAIL,
} from './constants'

function* uploadFile({
  entity,
  key,
  file,
}, {
  call,
  put,
}) {
  try {
    const response = yield call(api.postUploadFile, file)
    const url = get(response, ['data', 'url'])

    yield put(createAction(UPLOAD_FILE_SUCCESS, {
      entity,
      key,
      data: { [key]: url },
    }))
  } catch (error) {
    yield put(createAction(UPLOAD_FILE_FAIL, {
      entity,
      key,
      error,
    }))
  }
}

export default {
  [UPLOAD_FILE_REQUEST]: uploadFile,
}
