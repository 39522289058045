export const MODEL = 'courseLessons'
export const TEACHER_LESSON_MODEL = 'teacherCourseLessons'

// Action types
export const FETCH_COURSE_LESSON_REQUEST = 'FETCH_COURSE_LESSON_REQUEST'
export const FETCH_COURSE_LESSON_SUCCESS = 'FETCH_COURSE_LESSON_SUCCESS'
export const FETCH_COURSE_LESSON_FAIL = 'FETCH_COURSE_LESSON_FAIL'

export const FETCH_COURSE_LESSON_AND_JOIN_COURSE_REQUEST = 'FETCH_COURSE_LESSON_AND_JOIN_COURSE_REQUEST'
export const FETCH_COURSE_LESSON_AND_JOIN_COURSE_SUCCESS = 'FETCH_COURSE_LESSON_AND_JOIN_COURSE_SUCCESS'
export const FETCH_COURSE_LESSON_AND_JOIN_COURSE_FAIL = 'FETCH_COURSE_LESSON_AND_JOIN_COURSE_FAIL'

export const SAVE_COURSE_LESSON_REQUEST = 'SAVE_COURSE_LESSON_REQUEST'
export const SAVE_COURSE_LESSON_SUCCESS = 'SAVE_COURSE_LESSON_SUCCESS'
export const SAVE_COURSE_LESSON_FAIL = 'SAVE_COURSE_LESSON_FAIL'

export const ASSIGN_COURSE_LESSON_REQUEST = 'ASSIGN_COURSE_LESSON_REQUEST'
export const UPDATE_COURSE_LESSON_DATE_ASSIGNMENT = 'UPDATE_COURSE_LESSON_DATE_ASSIGNMENT'
export const UPDATE_COURSE_LESSON_NAME = 'UPDATE_COURSE_LESSON_NAME'
export const UPDATE_COURSE_LESSON_IN_STORE = 'UPDATE_COURSE_LESSON_IN_STORE'
export const SAVE_COURSE_LESSON = 'SAVE_COURSE_LESSON'
export const REMOVE_LESSON_PROBLEM = 'REMOVE_LESSON_PROBLEM'
export const MOVE_LESSON_PROBLEM = 'MOVE_LESSON_PROBLEM'
export const CHANGE_LESSON_PROBLEM_OPTIONS = 'CHANGE_LESSON_PROBLEM_OPTIONS'
export const ADD_PROBLEMS_TO_COURSE_LESSON = 'ADD_PROBLEMS_TO_COURSE_LESSON'

export const PREVENT_LOADING_VIDEO = 'PREVENT_LOADING_VIDEO'
export const ALLOW_LOADING_VIDEO = 'ALLOW_LOADING_VIDEO'

// Key to watch request status
export const getKeyForSaveCourseLesson = courseLessonId => `SAVE.${courseLessonId}`
