import 'react-toastify/dist/ReactToastify.css'
import React from 'react'
import {
  toast as baseToast,
} from 'react-toastify'
import { t } from 'i18next'

import styles from './styles.module.scss'

const DEFAULT_CLOSE_TIMEOUT = 10000

const ALEMIRA_ERROR_EMOJI = '😰' // Anxious Face with Sweat

const Message = () => (
  <div
    className={styles.NotificationWrapper}
  >
    <span role="img" className={styles.Emoji}>
      {ALEMIRA_ERROR_EMOJI}
    </span>
    <div className="title">{t('Alemira:alemira_error_text')}</div>
  </div>
)


export function notify() {
  // using uikit/components/Notification container for toast
  baseToast(
    <Message />,
    {
      className    : styles.Reset,
      bodyClassName: styles.Body,
      closeButton  : false,
      autoClose    : DEFAULT_CLOSE_TIMEOUT,
    },
  )
}
