import { get, mapKeys } from 'lodash'
import createCachedSelector from 're-reselect'
import { createSelector } from 'reselect'
import jsonStableStringify from 'json-stable-stringify'

import {
  API_STATE_NAME,
  ENTITIES_STATE_NAME,
} from './constants'

/**
 * Возвращает состояние api-запроса.
 *
 * @param  {Object} state Стейт стора.
 * @param  {string} entity Имя сущности.
 * @param  {string} key Уникальный ключ api-запроса.
 * @return {object} Состояние api-запроса.
 */
export const selectApiState = (state, entity, key) => get(state, [API_STATE_NAME, entity, key])

/**
 * Возвращает все экземпляры сущности.
 *
 * @param  {Object} state Стейт стора.
 * @param  {string} entity Имя сущности.
 * @param  {Object} options Опции.
 * @return {object} Словарь сущностей.
 */
export const selectEntities = createCachedSelector(
  (state, entity) => get(state, [ENTITIES_STATE_NAME, entity]),
  (_, entity, options) => get(options, 'keyedBy'),

  (dictionary, keyedBy) => keyedBy ? mapKeys(dictionary, keyedBy) : dictionary,
)((_, entity, options) => `${entity}-${jsonStableStringify(options)}`)

/**
 * Возвращает экземпляр сущности с соответсвующим id.
 *
 * @param  {Object} state Стейт стора.
 * @param  {string} entity Имя сущности.
 * @param  {string} id Идентификатор сущности.
 * @return {Object} Экземпляр сущности.
 */
export const selectEntity = (state, entity, id) => get(selectEntities(state, entity), id)

/**
 * Выполняет поиск экземпляра сущности по полю.
 *
 * @param  {Object} state Стейт стора.
 * @param  {Object} options Параметры поиска: entity - имя сущности, by - имя ключа, по которому искать.
 * @return {Object} Экземпляр сущности.
 */
export const findEntity = createSelector(
  (state, { entity, by }) => selectEntities(state, entity, { keyedBy: by }),
  (_, { key }) => key,

  (dictionary, key) => get(dictionary, key)
)

/**
 * Возвращает массив идентификаторов экземпляров сущностей,
 * являющихся результатом запроса к api с соответсвующим ключом.
 *
 * @param  {Object} state Стейт стора.
 * @param  {string} entity Имя сущности.
 * @param  {string} key Уникальный ключ api-запроса.
 * @return {Array} Идентификаторы сущностей загруженных по api-запросу.
 */
export const selectIds = (state, entity, key) => get(selectApiState(state, entity, key), 'ids', [])

const createIdsSelector = entity => (state, key) => selectIds(state, entity, key)

const createEntitiesSelector = entity => state => selectEntities(state, entity)

const mapEntities = (ids, entities) => ids.map(id => entities[id])

/**
 * Создает селектор сущности, для получения сущностей по ключу api-запроса.
 *
 * @param  {string} entity Имя сущности.
 * @return {Function} Селектор сущности по ключу api-запроса.
 */
export const createSelectorForEntityKey = entity => createSelector([
  createIdsSelector(entity),
  createEntitiesSelector(entity),
], mapEntities)

/**
 * Возвращает подсостояние стейта, содержащие все сущности, запрошенные с бэкенда.
 * Требуется для денормализации данных, хранящихся в стейте.
 *
 * @param   {Object} state Стейт стора
 * @return {Object} Подсостояние, содержащие сохраненные сущоности
 */
export const selectEntitiesSubstate = state => get(state, [ENTITIES_STATE_NAME])
