const getStudentName = (student) => {
  let name = ''

  if (student) {
    if (student.lastName || student.firstName) {
      name = student.lastName ? `${student.lastName} ` : ''
      name += student.firstName ? `${student.firstName}` : ''
    } else {
      name = student.username
    }
  }

  return name
}

export default getStudentName
