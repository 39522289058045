export const MODEL = 'courseStats'

// Action types
export const FETCH_COURSE_STATS_REQUEST = 'FETCH_COURSE_STATS_REQUEST'
export const FETCH_COURSE_STATS_SUCCESS = 'FETCH_COURSE_STATS_SUCCESS'
export const FETCH_COURSE_STATS_FAIL = 'FETCH_COURSE_STATS_FAIL'
export const SET_REFETCH_COURSE_STATS = 'SET_REFETCH_COURSE_STATS'

export const FETCH_TEACHER_COURSE_STATS_REQUEST = 'FETCH_TEACHER_COURSE_STATS_REQUEST'
export const FETCH_TEACHER_COURSE_STATS_SUCCESS = 'FETCH_TEACHER_COURSE_STATS_SUCCESS'
export const FETCH_TEACHER_COURSE_STATS_FAIL = 'FETCH_TEACHER_COURSE_STATS_FAIL'

export const SET_REFETCH_TEACHER_COURSE_STATS = 'SET_REFETCH_TEACHER_COURSE_STATS'
