import { merge } from 'lodash/fp'

import { PLAY_AUDIO } from './constants'

export default {
  [PLAY_AUDIO](state, { audioElementId }) {
    return merge(
      state,
      {
        currentlyPlayingAudioElementId: audioElementId,
      }
    )
  },
}
