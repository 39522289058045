import React from 'react'
import { get } from 'lodash'
import { routerRedux } from 'dva/router'
import {
  stopSubmit,
  startSubmit,
  setSubmitSucceeded,
} from 'redux-form'
import HttpStatus from 'http-status-codes'
import { t } from 'i18next'
import { Trans } from 'react-i18next'

import {
  createAction, clearEntities, clearApi,
} from 'utils/store'

import { SITE_CODES } from 'environment'
import { joinCourse } from 'domains/course'
import { selectSiteCode } from 'domains/app'
import {
  selectUser,
  userHasDefaultName,
} from 'domains/user'
import {
  userParams,
} from 'domains/ym'
import { removeAuthorizationToken, setAuthorizationToken } from 'utils/axios'
import { extractValidationError } from 'utils/api/helpers'
import { isExternalUrl } from 'utils/urls'
import { ENTER_NAME_AFTER_LOGIN_HASH } from 'components/Auth/route-constants'
import { notify as alemiraNotify } from 'uikit/alemiraComponents/Notification'

import * as api from './api'
import {
  setUser,
  fetchUser as fetchUserActionCreator,
  removeUser,
  updateUser as updateUserActionCreator,
} from './actions'
import {
  CHANGE_PASSWORD,
  CREATE_USER,
  FETCH_USER,
  SET_USER,
  LOGIN,
  LOGOUT,
  REGISTER_CONFIRM,
  REGISTER_CONFIRM_REQUEST,
  REGISTER_CONFIRM_EXPIRED,
  REGISTER_CONFIRM_SUCCESS,
  RESEND_CONFIRM,
  RESEND_CONFIRM_REQUEST,
  RESEND_CONFIRM_SUCCESS,
  RESEND_CONFIRM_FAIL,
  RESET_PASSWORD,
  RESET_PASSWORD_CONFIRM,
  UPDATE_USER,
  ENTER_STUDENT_NAME,

  GET_AVAILABLE_LOGIN,
  AVAILABLE_LOGIN_REQUEST,
  AVAILABLE_LOGIN_SUCCESS,
  AVAILABLE_LOGIN_FAIL,

  SEND_PURCHASE_REGISTER_CONFIRM,
  AUTHORIZE_WITH_GOOGLE,
  AUTHORIZE_WITH_FACEBOOK,
  NEXT_PAGE_AFTER_SOCIAL_AUTH_KEY,
} from './constants'
import { readMapper, writeMapper } from './adapters'
import { isAuthorized } from './selectors'

const GENERIC_500_ERROR_MSG = t('User:error_message_500')

function* redirectHelper(put, next) {
  if (isExternalUrl(next)) {
    yield put(routerRedux.push(`/redirect/?next=${next}`))
  } else {
    const nextUrl = new URL(next, window.location.origin)
    yield put(routerRedux.push(nextUrl.pathname))
  }
}

function* sendUserParams(_, { put }) {
  yield put(userParams())
}

function* fetchUser(_, { call, put }) {
  try {
    const response = yield call(api.getUser)
    const user = readMapper(response.data)

    yield put(setUser(user))
  } catch (error) {
    const responseStatus = error.response.status
    if (responseStatus === HttpStatus.UNAUTHORIZED) {
      removeAuthorizationToken()
      yield put(removeUser())
    } else {
      throw error
    }
  }
}

function* login({
  login,
  password,
  formName,
  courseCode,
  next,
  isSitLogin,
}, {
  put,
  call,
  select,
}) {
  try {
    const response = yield call(api.login, { login, password })
    const token = get(response, 'data.key')

    setAuthorizationToken(token)

    // Запрашиваем пользователя только если редиректа нет, остальные сами заботятся о пользователе
    if (!next || isSitLogin) {
      yield put.resolve(fetchUserActionCreator())
    }

    if (courseCode) {
      yield put.resolve(joinCourse({ courseCode }))
      return
    }
  } catch (error) {
    if (formName && error.resposne) {
      let errors
      if (isSitLogin) {
        const errorMessage = (error.response.status === 400)
          ? t('Alemira:error_wrong_password')
          : t('User:error_message_unknown')

        errors = { _error: errorMessage }
      } else {
        const errorMessage = (error.response.status === 400)
          ? (
            <span>
              <Trans i18nKey="User:error_message_400">
                <a href="/reset-password/">Восстановить?</a>
              </Trans>
            </span>
          )
          : t('User:error_message_unknown')

        errors = { password: errorMessage }
      }
      yield put(stopSubmit(formName, errors))
    } else if (isSitLogin) {
      alemiraNotify()
    } else {
      throw error
    }
    return
  }
  if (!next) {
    const siteCode = yield select(selectSiteCode)
    const user = yield select(selectUser)
    const hash = userHasDefaultName(user) ? ENTER_NAME_AFTER_LOGIN_HASH : ''

    if (siteCode === SITE_CODES.HELPEGE) {
      yield put(routerRedux.push({
        pathname: '/classroom/',
        hash,
      }))
    } else {
      yield put(routerRedux.push({
        ...(window.location),
        state: { isUserAfterAuth: true },
        hash,
      }))
    }
  } else {
    yield redirectHelper(put, next)
  }
}

function* logout(_, { call, put }) {
  removeAuthorizationToken()
  yield put(removeUser())
  yield put(routerRedux.push('/'))
  yield put(clearEntities())
  yield put(clearApi())

  // "анонимный" запрос, чтобы удалить сессию и не удалять токен на бекенде
  yield call(api.logout)
}

function* changePassword({
  meta: { form }, oldPassword, newPassword,
}, { put, call }) {
  try {
    yield put(startSubmit(form))
    yield call(api.changePassword, { oldPassword, newPassword })
    yield put(stopSubmit(form))
    yield put(setSubmitSucceeded(form))
  } catch (error) {
    const responseStatus = error.response.status
    if (responseStatus >= HttpStatus.INTERNAL_SERVER_ERROR) {
      yield put(stopSubmit(form, { _error: GENERIC_500_ERROR_MSG }))
      throw error
    } else if (responseStatus >= HttpStatus.BAD_REQUEST) {
      const errorMessage = (
        get(error, 'response.data.old_password')
        || t('User:error_message_try_later')
      )
      yield put(stopSubmit(form, { _error: errorMessage }))
    }
  }
}

function* resetPassword({
  meta: { form }, login,
}, { put, call }) {
  const RESET_PASSWORD_ERRORS = {
    'Пользователь с такой почтой не найден': {
      field: 'login',
      text : t('Auth:error_email_not_found'),
    },
    'Что-то не так с адресом почты. Проверьте, нет ли ошибки в написании.': {
      field: 'login',
      text : t('Auth:error_email_is_wrong'),
    },
  }
  try {
    yield put(startSubmit(form))
    yield call(api.resetPassword, login)
    yield put(stopSubmit(form))
    yield put(setSubmitSucceeded(form))
  } catch (error) {
    const responseStatus = error.response.status
    if (responseStatus >= HttpStatus.INTERNAL_SERVER_ERROR) {
      yield put(stopSubmit(form, { _error: GENERIC_500_ERROR_MSG }))
      throw error
    } else if (responseStatus >= HttpStatus.BAD_REQUEST) {
      yield put(stopSubmit(form, extractValidationError(error, RESET_PASSWORD_ERRORS)))
    }
  }
}

function* resetPasswordConfirm({
  meta: { form }, token, uid, password,
}, { call, put }) {
  try {
    yield put(startSubmit(form))
    yield call(api.resetPasswordConfirm, {
      token, uid, password,
    })
    yield put(stopSubmit(form))
    yield put(setSubmitSucceeded(form))
  } catch (error) {
    const responseStatus = error.response.status
    if (responseStatus >= HttpStatus.INTERNAL_SERVER_ERROR) {
      yield put(stopSubmit(form, { _error: GENERIC_500_ERROR_MSG }))
      throw error
    } else if (responseStatus >= HttpStatus.BAD_REQUEST) {
      yield put(stopSubmit(form, extractValidationError(error, {})))
    }
  }
}

function* createUser({
  formName,
  courseCode,
  personalInfo,
  purchase,
  stayOnPage,
  onSuccess,
  next,
  errorMessages = {},
  isSitLogin,
}, { call, put }) {
  try {
    yield put(startSubmit(formName))
    const response = yield call(api.createUser, { personalInfo, purchase })
    const token = get(response, 'data.key')
    setAuthorizationToken(token)
    yield put(fetchUserActionCreator())
    yield put(stopSubmit(formName))
    yield put(setSubmitSucceeded(formName))
  } catch (error) {
    if (formName && error.response) {
      const responseStatus = error.response.status
      if (responseStatus >= HttpStatus.INTERNAL_SERVER_ERROR) {
        yield put(stopSubmit(formName, { _error: GENERIC_500_ERROR_MSG }))
        if (isSitLogin) {
          alemiraNotify()
        } else {
          throw error
        }
      } else if (responseStatus >= HttpStatus.BAD_REQUEST) {
        yield put(stopSubmit(formName, extractValidationError(error, errorMessages)))
      }
    } else if (isSitLogin) {
      alemiraNotify()
    } else {
      throw error
    }
    return
  }
  if (courseCode) {
    yield put(joinCourse({ courseCode }))
  }
  if (stayOnPage) {
    yield put(routerRedux.push({
      hash : '',
      state: {
        isUserAfterRegistration: true,
        registrationEmail      : personalInfo.email,
      },
    }))
  } else {
    yield redirectHelper(put, next || '/classroom/')
  }
  // FIXME: setSubmitSucceeded and setSubmitFailed don't work
  onSuccess()
}

function* updateUser({ update }, { call, put }) {
  const response = yield call(api.updateUser, writeMapper(update))
  const user = readMapper(response.data)

  yield put(setUser(user))
}

function* registerConfirm({
  key,
  searchString,
}, {
  call, put, select,
}) {
  try {
    yield put(createAction(REGISTER_CONFIRM_REQUEST))
    const response = yield call(api.registerConfirm, { key, searchString })
    const isAuthorizedUser = yield select(isAuthorized)
    if (!isAuthorizedUser) {
      const token = get(response, 'data.key')
      setAuthorizationToken(token)
    }
    yield put(fetchUserActionCreator())
    yield put(createAction(REGISTER_CONFIRM_SUCCESS))
    const courseId = get(response, 'data.redirect_to_course')
    if (courseId) {
      yield put(routerRedux.push(`/classroom/${courseId}/`))
    } else {
      yield put(routerRedux.push('/classroom/'))
    }
  } catch (error) {
    const responseStatus = error.response.status
    if (responseStatus >= HttpStatus.INTERNAL_SERVER_ERROR) {
      throw error
    } else if (responseStatus >= HttpStatus.BAD_REQUEST) {
      yield put(createAction(REGISTER_CONFIRM_EXPIRED))
    }
  }
}

function* resendRegisterConfirm(payload, { call, put }) {
  try {
    yield put(createAction(RESEND_CONFIRM_REQUEST))
    yield call(api.resendRegisterConfirmEmail)
    yield put(createAction(RESEND_CONFIRM_SUCCESS))
  } catch (error) {
    const responseStatus = error.response.status
    if (responseStatus >= HttpStatus.INTERNAL_SERVER_ERROR) {
      throw error
    } else if (responseStatus >= HttpStatus.BAD_REQUEST) {
      yield put(createAction(RESEND_CONFIRM_FAIL))
    }
  }
}

function* sendPurchaseRegisterConfirm({
  purchase,
  onSuccess,
  onError,
}, { call }) {
  try {
    yield call(api.sendPurchaseRegisterConfirm, { purchase })
    if (onSuccess) onSuccess()
  } catch (error) {
    if (onError) {
      onError()
    } else {
      throw error
    }
  }
}

function* getFreeLogin(payload, { call, put }) {
  try {
    yield put(createAction(AVAILABLE_LOGIN_REQUEST))
    const response = yield call(api.getFreeLogin)
    const { login } = response.data
    yield put(createAction(AVAILABLE_LOGIN_SUCCESS, { login }))
  } catch (error) {
    const responseStatus = error.response.status
    if (responseStatus >= HttpStatus.INTERNAL_SERVER_ERROR) {
      throw error
    } else if (responseStatus >= HttpStatus.BAD_REQUEST) {
      yield put(createAction(AVAILABLE_LOGIN_FAIL))
    }
  }
}

function* enterStudentName(payload, { put }) {
  try {
    yield put(updateUserActionCreator(payload))
  } catch (error) {
    const responseStatus = error.response.status

    if (responseStatus >= HttpStatus.INTERNAL_SERVER_ERROR) {
      throw error
    }
  }

  yield put(routerRedux.push({ hash: '' }))
}

function* authorizeWithGoogle({ code }, { call, put }) {
  try {
    const response = yield call(api.postSocialGoogleLogin, { code })
    const token = get(response, 'data.key')

    setAuthorizationToken(token)
    yield put(fetchUserActionCreator())
    const next = window.localStorage.getItem(NEXT_PAGE_AFTER_SOCIAL_AUTH_KEY) || '/classroom/'
    window.localStorage.removeItem(NEXT_PAGE_AFTER_SOCIAL_AUTH_KEY)
    yield put(routerRedux.push(next))
  } catch (error) {
    const responseStatus = error.response.status

    if (responseStatus >= HttpStatus.INTERNAL_SERVER_ERROR) {
      throw error
    } else {
      yield put(routerRedux.push('/404/'))
    }
  }
}

function* authorizeWithFacebook({ code }, { call, put }) {
  try {
    const response = yield call(api.postSocialFacebookLogin, { code })
    const token = get(response, 'data.key')

    setAuthorizationToken(token)
    yield put(fetchUserActionCreator())
    const next = window.localStorage.getItem(NEXT_PAGE_AFTER_SOCIAL_AUTH_KEY) || '/classroom/'
    window.localStorage.removeItem(NEXT_PAGE_AFTER_SOCIAL_AUTH_KEY)
    yield put(routerRedux.push(next))
  } catch (error) {
    const responseStatus = error.response.status

    if (responseStatus >= HttpStatus.INTERNAL_SERVER_ERROR) {
      throw error
    } else {
      yield put(routerRedux.push('/404/'))
    }
  }
}

export default {
  [CHANGE_PASSWORD]               : changePassword,
  [CREATE_USER]                   : createUser,
  [FETCH_USER]                    : fetchUser,
  [LOGIN]                         : login,
  [LOGOUT]                        : logout,
  [RESET_PASSWORD]                : resetPassword,
  [RESET_PASSWORD_CONFIRM]        : resetPasswordConfirm,
  [UPDATE_USER]                   : updateUser,
  [REGISTER_CONFIRM]              : registerConfirm,
  [RESEND_CONFIRM]                : resendRegisterConfirm,
  [GET_AVAILABLE_LOGIN]           : getFreeLogin,
  [ENTER_STUDENT_NAME]            : enterStudentName,
  [SEND_PURCHASE_REGISTER_CONFIRM]: sendPurchaseRegisterConfirm,
  [SET_USER]                      : sendUserParams,
  [AUTHORIZE_WITH_GOOGLE]         : authorizeWithGoogle,
  [AUTHORIZE_WITH_FACEBOOK]       : authorizeWithFacebook,
}
