import {
  get,
  isEmpty,
  every,
  values,
  mapKeys,
  mapValues,
} from 'lodash'

export const getPublicSolution = problem => get(problem, ['markup', 'publicSolution'])

export const getPrivateSolution = problem => get(problem, ['markup', 'solution'])

export const hasProblemPublicSolution = problem => !isEmpty(getPublicSolution(problem))

export const hasProblemPrivateSolution = problem => !isEmpty(getPrivateSolution(problem))

export const getProblemAnswers = problem => get(problem, ['markup', 'answers'])

export const isProblemAnswersEmpty = (problem) => {
  const answers = getProblemAnswers(problem)

  return isEmpty(answers) || every(
    values(answers),
    markerAnswer => isEmpty(markerAnswer),
  )
}

export const getProblemCorrectAnswerFormValues = problem => mapKeys(
  mapValues(
    getProblemAnswers(problem),
    (answer, markerId) => ({ markerId, markerAnswer: { user_answer: answer } })
  ),
  (answer, markerId) => `marker_${markerId}`,
)
