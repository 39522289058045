import { get } from 'lodash'
import {
  normalize,
  schema,
} from 'normalizr'

import { createAction } from 'utils/store'
import { setBackendAvailable, setBackendUnavailable } from 'domains/app'

import * as api from './api'
import {
  MODEL,
  FETCH_COURSE_LESSON_STATS_REQUEST,
  FETCH_COURSE_LESSON_STATS_SUCCESS,
  FETCH_COURSE_LESSON_STATS_FAIL,
} from './constants'
import { readMapper } from './adapters'

function* fetchCourseLessonStats({
  entity,
  key,
  lessonId,
}, {
  call,
  put,
}) {
  try {
    const response = yield call(api.getCourseLessonStats, lessonId)
    const courseStats = get(response, 'data')

    yield put(setBackendAvailable())

    yield put(createAction(FETCH_COURSE_LESSON_STATS_SUCCESS, {
      entity,
      key,
      data: normalize(courseStats, new schema.Entity(
        MODEL,
        {},
        {
          idAttribute    : () => lessonId,
          processStrategy: readMapper,
        },
      )),
    }))
  } catch (error) {
    yield put(setBackendUnavailable())

    yield put(createAction(FETCH_COURSE_LESSON_STATS_FAIL, {
      entity,
      key,
      error,
    }))

    throw error
  }
}

export default {
  [FETCH_COURSE_LESSON_STATS_REQUEST]: fetchCourseLessonStats,
}
