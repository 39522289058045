import {
  SET_LIBRARY_DATA,
} from './constants'

export default {
  [SET_LIBRARY_DATA](state, data) {
    return {
      ...state,
      ...data,
    }
  },
}
