import {
  get,
  has,
  noop,
  assign,
} from 'lodash'
import cn from 'classnames'
import MaskedInput from 'react-maskedinput'
import PropTypes from 'prop-types'
import React from 'react'
import ReactDOM from 'react-dom'
import { compose } from 'recompose'
import { t } from 'i18next'

import withAutoFocus from 'uikit/hocs/withAutoFocus'

import styles from './styles.module.scss'

const FASHION_TO_CLASSNAME = {
  fat : styles.FashionFat,
  slim: styles.FashionSlim,
}

const THEME_TO_CLASSNAME = {
  alemira: styles.ThemeAlemira,
  dark   : styles.ThemeDark,
  light  : styles.ThemeLight,
}

const SIZE_TO_CLASSNAME = {
  medium: styles.SizeMedium,
}

const ERROR_BOX_WIDTH = 250

class Input extends React.Component {
  static displayName = 'Input'

  static propTypes = {
    type                 : PropTypes.string,
    disableTogglePassword: PropTypes.bool,
    changed              : PropTypes.func,
    mask                 : PropTypes.string,
    onBlur               : PropTypes.func,
    onChange             : PropTypes.func,
    onFocus              : PropTypes.func,
    placeholderChar      : PropTypes.string,
    size                 : PropTypes.oneOf([
      'medium',
    ]),
    fashion: PropTypes.oneOf([
      'fat',
      'slim',
    ]),
    theme: PropTypes.oneOf([
      'dark',
      'light',
      'alemira',
    ]),
    value    : PropTypes.string,
    fullWidth: PropTypes.bool,
  }

  static defaultProps = {
    changed              : noop,
    onBlur               : noop,
    onChange             : noop,
    onFocus              : noop,
    size                 : 'medium',
    fashion              : 'slim',
    theme                : 'dark',
    value                : '',
    fullWidth            : false,
    type                 : 'text',
    disableTogglePassword: false,
  }

  constructor(props) {
    super(props)

    const { value } = props

    this.state = {
      filled           : !!value,
      focused          : false,
      isPasswordVisible: false,
      value,
    }
  }

  componentDidMount() {
    const element = this.control
    const position = element.getBoundingClientRect()
    const isSpaceEnough = (position.x - ERROR_BOX_WIDTH) > 0
    this.setState({
      errorOnLeft: isSpaceEnough,
    })
  }

  componentWillReceiveProps(nextProps) {
    const { value: nextValue } = nextProps
    const { value } = this.state

    if (value !== undefined && nextValue !== value) {
      this.setState({
        value : nextValue,
        filled: !!nextValue,
      })
    }
  }

  refControl = (component) => {
    this.control = ReactDOM.findDOMNode(component)
  }

  onFocus = (event) => {
    const { onFocus } = this.props

    this.setState({ focused: true })
    onFocus(event)
  }

  onBlur = (event) => {
    const { onBlur } = this.props
    const { value } = this.state

    this.setState({
      focused: false,
      filled : !!value,
    })
    onBlur(event)
  }

  onChange = (event, ...args) => {
    const {
      changed,
      onChange,
    } = this.props
    const { value } = event.target

    this.setState({ value })
    onChange(event, ...args)
    changed(value)
  }

  togglePasswordVisibilty = () => {
    this.setState((state) => {
      const { isPasswordVisible } = state
      return { isPasswordVisible: !isPasswordVisible }
    })
  }

  focus() {
    this.control.focus()
  }

  render() {
    const {
      changed,
      className,
      disabled,
      fashion,
      label,
      mask,
      placeholder,
      placeholderChar,
      size,
      theme,
      fullWidth,
      type,
      disableTogglePassword,
      ...props
    } = this.props
    const {
      filled,
      focused,
      value,
      isPasswordVisible,
      errorOnLeft,
    } = this.state

    const touched = get(props, 'meta.touched')
    const error = get(props, 'meta.error')


    const controlSpecificProps = {}
    let Control = 'input'

    if (mask) {
      Control = MaskedInput
      assign(controlSpecificProps, {
        mask,
        placeholderChar,
        size: null,
      })
    }

    if (type === 'password' && isPasswordVisible) {
      controlSpecificProps.type = 'text'
    } else if (type === 'password' && !isPasswordVisible) {
      controlSpecificProps.type = 'password'
    }

    return (
      <span
        className={cn(
          styles.Input,
          className,
          FASHION_TO_CLASSNAME[fashion],
          THEME_TO_CLASSNAME[theme],
          SIZE_TO_CLASSNAME[size],
          {
            [styles.Focused]  : focused,
            [styles.Filled]   : filled || !!mask || !!placeholder,
            [styles.Disabled] : disabled,
            [styles.FullWidth]: fullWidth,
            [styles.Error]    : touched && !!error,
          },
        )}
      >
        <Control
          {...props}
          {...controlSpecificProps}
          className={styles.Control}
          disabled={disabled}
          ref={this.refControl}
          onBlur={this.onBlur}
          onChange={this.onChange}
          onFocus={this.onFocus}
          placeholder={placeholder}
          value={value}
        />
        {(type === 'password' && !disableTogglePassword)
        && (
          <button
            className={styles.PasswordToggle}
            type="button"
            onClick={this.togglePasswordVisibilty}
          >
            <span
              role="img"
              aria-label={t('Input:show_hide_password')}
            >
              {isPasswordVisible ? '🐵' : '🙈'}
            </span>
          </button>
        )
        }
        <span className={styles.Label}>{label}</span>
        {touched
        && ((has(error, 'show') ? error.show : error)) && (
          <span className={
            cn(styles.ErrorMessage,
              { [styles.LeftPosition]: errorOnLeft })}
          >
            {has(error, 'show') ? error.text : error}
          </span>
        )}
      </span>
    )
  }
}

const enhance = compose(
  withAutoFocus,
)

export default enhance(Input)
