import {
  SET_APP_LOADING,
  SITE_CONFIG_LOADED,
  SET_I18NEXT_INITIALIZED,
  SET_BACKEND_AVAILABLE,
  SET_BACKEND_UNAVAILABLE,
  SET_SCROLLBAR_WIDTH,
  SET_CACKLE_SSO_STRING,
} from './constants'

export default {
  [SET_APP_LOADING](state, { isLoading }) {
    return {
      ...state,
      isLoading,
    }
  },
  [SET_I18NEXT_INITIALIZED](state, { isI18NextInitialized }) {
    return {
      ...state,
      isI18NextInitialized,
    }
  },
  [SITE_CONFIG_LOADED](state, {
    code,
    secondLogo,
    promoLink,
  }) {
    return {
      ...state,
      code,
      secondLogo,
      promoLink,
    }
  },
  [SET_BACKEND_AVAILABLE](state) {
    return {
      ...state,
      isBackendAvailable     : true,
      backendUnavailableSince: null,
    }
  },
  [SET_BACKEND_UNAVAILABLE](state, { backendUnavailableSince }) {
    return {
      ...state,
      isBackendAvailable: false,
      backendUnavailableSince,
    }
  },
  [SET_SCROLLBAR_WIDTH](state, { scrollbarWidth }) {
    return {
      ...state,
      scrollbarWidth,
    }
  },
  [SET_CACKLE_SSO_STRING](state, { ssoString }) {
    return {
      ...state,
      cackleSSOString: ssoString,
    }
  },
}
