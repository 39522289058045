import axios from 'utils/axios'

import urls from './urls'

export const getAssignmentLink = (assignmentPath, courseId) => (
  axios.post(
    urls.getAssignmentLink,
    { assignment: assignmentPath, course: courseId },
    { timeout: 30000 },
  )
)
