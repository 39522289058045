import axios from 'utils/axios'

import urls from './urls'

export const getStudentCourse = courseId => axios.get(
  urls.courseForStudent(courseId),
)

export const getTeacherCourse = courseId => axios.get(
  urls.courseForTeacher(courseId),
)

export const getCourseWithGroup = courseId => axios.get(
  urls.courseWithGroup(courseId),
)

export const joinCourse = code => axios.post(urls.joinCourse, { code })

export const sendPromoCode = ({ course, code }) => axios.post(
  urls.promoCode, { course, code }
)
