import LinkStyles from 'uikit/components/Link/styles.module.scss'
// импорт для порядка следования стилей, влияет на стили User
import ModalStyles from 'uikit/components/Modal/styles.module.scss' // eslint-disable-line
import LogoStyles from 'components/Logo/styles.module.scss'
import UserStyles from 'components/User/styles.module.scss'
import FooterStyles from 'components/Footer/styles.module.scss'
import NavigationStyles from 'pages/Course/LessonRun/Navigation/styles.module.scss'

export function applyAppearance() {
  const style = document.createElement('style')
  const {
    ui, header, footer, lessonNavigation,
  } = window.APPEARANCE
  style.type = 'text/css'
  style.innerHTML = `
    .${LinkStyles.ThemeDark} { color: ${ui.link.color.dark}; }
    .${LinkStyles.ThemeDark}:hover { color: ${ui.link.hover.color.dark};}
    .${LinkStyles.ThemeLight} { color: ${ui.link.color.light}; }
    .${LinkStyles.ThemeLight}:hover { color: ${ui.link.hover.color.light};}
    body {
      --link-color: ${ui.link.color.dark};
      --link-color-hover: ${ui.link.hover.color.dark};
    }

    .${LogoStyles.Logo}.${LogoStyles.ThemeDark} {
      background-image: url(${header.logo.backgroundImage.dark});
    }
    .${LogoStyles.Logo}.${LogoStyles.ThemeLight} {
      background-image: url(${header.logo.backgroundImage.light});
    }
    .${UserStyles.User} {
      color: ${ui.link.color.dark};
      box-shadow: ${header.user.boxShadow};
    }
    .${UserStyles.User}:hover {
      color: ${ui.link.hover.color.dark};
      box-shadow: ${header.user.hover.boxShadow};
    }
    .${NavigationStyles.Navigation} { background: ${lessonNavigation.color} }
    .${NavigationStyles.NavigationBefore} {
      background-image: linear-gradient(to bottom, ${lessonNavigation.color}, ${lessonNavigation.transparentColor});
    }
    .${NavigationStyles.NavigationAfter} {
      background-image: linear-gradient(to top, ${lessonNavigation.color}, ${lessonNavigation.transparentColor});
    }

    @media print {
      .${LogoStyles.Logo}.${LogoStyles.ThemeDark}:before {
        content: url(${header.logo.backgroundImage.dark});
      }
      .${LogoStyles.Logo}.${LogoStyles.ThemeLight}:before {
        content: url(${header.logo.backgroundImage.light});
      }
    }
  `

  if (footer) {
    style.innerHTML += `
      .${FooterStyles.ThemeLight} {
        background: ${footer.backgroundColor.light};
        color: ${footer.textColor.light};
      }
      .${FooterStyles.ThemeDark} {
        background: ${footer.backgroundColor.dark};
        color: ${footer.textColor.dark};
      }
    `
  }
  document.getElementsByTagName('head')[0].appendChild(style)
}

export const {
  footer: FooterData,
  ui: { showVincent, showChatra },
  header: {
    promoLink = null,
  },
  editorHost: EDITOR_HOST,
} = window.APPEARANCE
