import { get, isUndefined } from 'lodash'
import { normalize } from 'normalizr'
import HttpStatus from 'http-status-codes'

import { createAction } from 'utils/store'

import * as api from './api'
import {
  FETCH_COURSE_STATS_REQUEST,
  FETCH_COURSE_STATS_SUCCESS,
  FETCH_COURSE_STATS_FAIL,
  FETCH_TEACHER_COURSE_STATS_REQUEST,
  FETCH_TEACHER_COURSE_STATS_SUCCESS,
  FETCH_TEACHER_COURSE_STATS_FAIL,
} from './constants'
import { courseStatsSchema, teacherCourseStatsSchema } from './schema'

function* fetchCourseStats({
  entity,
  key,
  courseId,
}, {
  call,
  put,
}) {
  try {
    const response = yield call(api.getCourseStats, courseId)
    const courseStats = {
      ...get(response, 'data'),
      id: courseId,
    }

    yield put(createAction(FETCH_COURSE_STATS_SUCCESS, {
      entity,
      key,
      data: normalize(courseStats, courseStatsSchema),
    }))
  } catch (error) {
    yield put(createAction(FETCH_COURSE_STATS_FAIL, {
      entity,
      key,
      error,
    }))
    if (isUndefined(error.response) || error.response.status >= HttpStatus.INTERNAL_SERVER_ERROR) {
      throw error
    }
  }
}

function* fetchTeacherCourseStats({
  entity,
  key,
  courseId,
}, {
  call,
  put,
}) {
  try {
    const response = yield call(api.getTeacherCourseStats, courseId)
    const courseStats = {
      ...get(response, 'data'),
      id: courseId,
    }

    yield put(createAction(FETCH_TEACHER_COURSE_STATS_SUCCESS, {
      entity,
      key,
      data: normalize(courseStats, teacherCourseStatsSchema),
    }))
  } catch (error) {
    yield put(createAction(FETCH_TEACHER_COURSE_STATS_FAIL, {
      entity,
      key,
      error,
    }))
    if (isUndefined(error.response) || error.response.status >= HttpStatus.INTERNAL_SERVER_ERROR) {
      throw error
    }
  }
}

export default {
  [FETCH_COURSE_STATS_REQUEST]        : fetchCourseStats,
  [FETCH_TEACHER_COURSE_STATS_REQUEST]: fetchTeacherCourseStats,
}
