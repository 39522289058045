export const BUTTON_THEMES = {
  CLEAN            : 'clean',
  BLACK            : 'black',
  RED              : 'red',
  BLANK_RED        : 'blankRed',
  UNIMPORTANT_LIGHT: 'unimportantLight',
  UNIMPORTANT_DARK : 'unimportantDark',
  GREEN            : 'green',
  YELLOW           : 'yellow',
  WHITE_BORDERED   : 'whiteBordered',
  WHITE            : 'white',
}

export const BUTTON_SIZES = {
  SMALL : 'small',
  MEDIUM: 'medium',
  LARGE : 'large',
}

export const BUTTON_DISABLED_THEMES = {
  LIGHT: 'light',
  DARK : 'dark',
}
