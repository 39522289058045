import { get } from 'lodash'

import { MODEL } from './constants'

export const selectIsDisplayingAddPointsAnimation = state => get(
  state,
  [
    MODEL,
    'lessonRun',
    'pointsAnimations',
    'addPoints',
    'display',
  ]
)

export const selectAddPointsAnimationPoints = state => get(
  state,
  [
    MODEL,
    'lessonRun',
    'pointsAnimations',
    'addPoints',
    'points',
  ]
)

export const selectIsDisplayingLosePointsAnimation = state => get(
  state,
  [
    MODEL,
    'lessonRun',
    'pointsAnimations',
    'losePoints',
    'display',
  ]
)

export const selectLosePointsAnimationPoints = state => get(
  state,
  [
    MODEL,
    'lessonRun',
    'pointsAnimations',
    'losePoints',
    'points',
  ]
)
