import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import moment from 'moment'
/* eslint-disable import/no-webpack-loader-syntax */
import resBundle from 'i18next-resource-store-loader!locales'
/* eslint-enable import/no-webpack-loader-syntax */
import { nameSpaces } from './i18nConfig'
import { dateInit } from './date'

export const LANGUAGES = {
  CHINESE: 'zh',
  ENGLISH: 'en',
  RUSSIAN: 'ru',
}
const { language = LANGUAGES.RUSSIAN } = window.APPEARANCE

dateInit(language)
  .then(() => {
    i18n
      .use(initReactI18next) // passes i18n down to react-i18next
      .init({
        resources    : resBundle,
        ns           : nameSpaces,
        lng          : language,
        interpolation: {
          escapeValue: false, // react already safes from xss
          format(value, format) {
            if (value instanceof Date) {
              /*
               Интерполяция и форматирование даты.
               Пример в файле перевода: {
                "assignment_date": "Выдано {{date, D MMM YYYY}} в {{date, h:mm}}"
               }
              */
              return moment(value).format(format)
            }
            return value
          },
        },
      })
  })


export default i18n
