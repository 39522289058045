import { get } from 'lodash'
import axios from 'utils/axios'

import urls from './urls'

export const findCourseLessonResult = (courseLessonId, studentId) => axios.get(
  urls.results,
  {
    params: {
      course_lesson: courseLessonId,
      student      : studentId,
    },
  },
)

export const getCourseLessonResultForTeacher = (courseLessonId, studentId) => axios.get(
  urls.resultsTeacher,
  {
    params: {
      course_lesson: courseLessonId,
      student      : studentId,
    },
  },
)


export const getCourseLessonResult = resultId => axios.get(urls.result(resultId))

export const createCourseLessonAttempt = (courseLessonId, answers = {}) => axios.post(
  urls.createAttempt,
  {
    course_lesson: courseLessonId,
    completed    : false,
    answers,
  },
)

export const updateCourseLessonResult = (resultId, data, options) => {
  const httpMethod = get(options, 'replace') ? axios.put : axios.patch

  return httpMethod(
    urls.result(resultId),
    data,
  )
}

export const checkDemoCourseLessonResult = data => axios.post(
  urls.resultsCheckDemo,
  data,
)
