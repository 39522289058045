import axios from 'utils/axios'

import urls from './urls'

export const getCourseStats = courseId => axios.get(
  urls.courseStats(courseId),
)

export const getTeacherCourseStats = courseId => axios.get(
  urls.teacherCourseStats(courseId),
)
