import moment from 'moment'

import { LANGUAGES } from './i18n'

function updateLocale(language) {
  if (language === 'ru') {
    moment.updateLocale('ru', {
      monthsShort: {
        format    : 'янв_фев_мар_апр_мая_июня_июля_авг_сен_окт_ноя_дек'.split('_'),
        standalone: 'янв_фев_март_апр_май_июнь_июль_авг_сен_окт_ноя_дек'.split('_'),
      },
    })
  }
}

export const dateInit = (language) => {
  let locale = language
  // moment defaults to English and will throw an
  // error if we attempt to explicitly import 'en'
  if (language === LANGUAGES.ENGLISH) {
    return Promise.resolve()
  }

  if (language === LANGUAGES.CHINESE) {
    // в moment нет просто zh, есть например zh-cn (Chinese Simplified)
    locale = 'zh-cn'
  }

  // динамически подгружаю нужный локальный файл
  return new Promise((resolve, reject) => {
      import(`moment/locale/${locale}`)
        .then(() => {
          moment.locale(locale)
          updateLocale(language)
          resolve()
        })
        .catch(err => reject(err))
  })
}
