import React from 'react'
import { connect } from 'dva'
import dynamic from 'dva/dynamic'
import {
  Router,
  Switch,
  Route,
} from 'dva/router'

import App from 'components/App'
import {
  selectSiteCode,
} from 'domains/app'
import ProtectedRoute from 'utils/routes/ProtectedRoute'
import AnonymousRoute from 'utils/routes/AnonymousRoute'
import { isProduction, SITE_CODES } from './environment'

const createRouter = (app) => {
  const Classroom = dynamic({
    app,
    component: () => import('pages/Classroom'),
  })
  const Course = dynamic({
    app,
    component: () => import('pages/Course'),
  })
  const AlemiraCourse = dynamic({
    app,
    component: () => import('alemiraPages/AlemiraCourse'),
  })
  const MainPelican = dynamic({
    app,
    component: () => import('pelicanPages/Login'),
  })
  const Uikit = dynamic({
    app,
    component: () => import('pages/Uikit'),
  })
  const AddCourse = dynamic({
    app,
    component: () => import('pages/AddCourse'),
  })
  const InfoPage = dynamic({
    app,
    component: () => import('pages/InfoPage'),
  })
  const ChangePassword = dynamic({
    app,
    component: () => import('pages/ChangePassword'),
  })
  const ResetPassword = dynamic({
    app,
    component: () => import('pages/ResetPassword'),
  })
  const ConfirmPassword = dynamic({
    app,
    component: () => import('pages/PasswordConfirm'),
  })
  const RegisterConfirm = dynamic({
    app,
    component: () => import('pages/RegisterConfirm'),
  })
  const NotFound = dynamic({
    app,
    component: () => import('pages/NotFound'),
  })
  const TeacherCourse = dynamic({
    app,
    component: () => import('pages/TeacherCourse'),
  })
  const TeacherCourseGroup = dynamic({
    app,
    component: () => import('pages/TeacherCourseGroup'),
  })
  const Promo = dynamic({
    app,
    component: () => import('pages/Promo'),
  })
  const MainHelpEge = dynamic({
    app,
    component: () => import('helpegePages/Login'),
  })
  const MainRoboborik = dynamic({
    app,
    component: () => import('roboborikPages/Login'),
  })
  const HomePage = connect((state) => {
    switch (selectSiteCode(state)) {
      case SITE_CODES.HELPEGE:
        return {
          component: MainHelpEge,
        }
      case SITE_CODES.ROBOBORIK:
        return {
          component: MainRoboborik,
        }
      case SITE_CODES.PELIKAN:
      case SITE_CODES.EN_PELIKAN:
      case SITE_CODES.CH_PELIKAN:
        return {
          component: MainPelican,
        }
      default:
        return {
          component: MainPelican,
        }
    }
  })(Route)
  const TeacherLessonResults = dynamic({
    app,
    component: () => import('pages/TeacherLessonResults'),
  })
  const StudentDialogResultForTeacher = dynamic({
    app,
    component: () => import('pages/StudentDialogResultForTeacher'),
  })
  const Monitoring = dynamic({
    app,
    component: () => import('pages/Monitoring'),
  })
  const CourseLineStat = dynamic({
    app,
    component: () => import('pages/CourseLineStat'),
  })
  const Redirect = dynamic({
    app,
    component: () => import('pages/Redirect'),
  })
  const CocalcRedirect = dynamic({
    app,
    component: () => import('alemiraPages/CocalcRedirect'),
  })
  const GoogleLoginCallback = dynamic({
    app,
    component: () => import('pages/SocialAuth/GoogleLoginCallback'),
  })
  const FacebookLoginCallback = dynamic({
    app,
    component: () => import('pages/SocialAuth/FacebookLoginCallback'),
  })

  const IKnowTheCode = dynamic({
    app,
    component: () => import('pages/IKnowTheCode'),
  })
  const LessonEditor = dynamic({
    app,
    component: () => import('pages/LessonEditor'),
  })

  const renderApp = () => (
    <App>
      <Switch>
        <AnonymousRoute
          exact
          path="/"
          component={HomePage}
        />
        <Route
          exact
          path="/info/"
          component={Promo}
        />
        {!isProduction && (
          <ProtectedRoute
            exact
            path="/uikit/"
            component={Uikit}
          />
        )}
        <ProtectedRoute
          exact
          path="/classroom/"
          component={Classroom}
        />
        <Route
          exact
          path="/classroom/teacher/:courseId/"
          component={TeacherCourse}
        />
        <ProtectedRoute
          exact
          path="/classroom/teacher/:courseId/group/"
          component={TeacherCourseGroup}
        />
        <ProtectedRoute
          exact
          path="/classroom/teacher/:courseId/lessons/:lessonId/"
          component={TeacherLessonResults}
        />
        <ProtectedRoute
          exact
          path="/classroom/teacher/:courseId/lessons/:lessonId/student/:studentId/"
          component={StudentDialogResultForTeacher}
        />
        <Route
          exact
          path="/classroom/editor/:courseId/lessons/:lessonId/"
          component={LessonEditor}
        />
        <Route
          exact
          path="/classroom/:courseId/dialogs/"
          component={AlemiraCourse}
        />
        <Route
          path="/classroom/:courseId/dialogs/:lessonId/run/"
          component={AlemiraCourse}
        />
        <Route
          path="/classroom/:courseId/"
          component={Course}
        />
        <Route
          path="/pages/:pageSlug/"
          component={InfoPage}
        />
        <Route
          path="/courses/:pageSlug/"
          component={InfoPage}
        />
        <Route
          exact
          path="/register/confirm/:key/"
          component={RegisterConfirm}
        />
        <Route
          path="/add-course/"
          component={AddCourse}
        />
        <Route
          path="/reset-password/"
          component={ResetPassword}
        />
        <ProtectedRoute
          path="/change-password/"
          component={ChangePassword}
        />
        <Route
          path="/password-confirm/:uid/:token/"
          component={ConfirmPassword}
        />
        <ProtectedRoute
          path="/school/:schoolId/course_line/:courseLineId?"
          component={Monitoring}
        />
        <ProtectedRoute
          path="/school/:schoolId/:teacher?/course_stat/"
          component={CourseLineStat}
        />
        <ProtectedRoute
          exact
          path="/iknowthecode/"
          component={IKnowTheCode}
        />
        <Route
          exact
          path="/accounts/google/login/callback/"
          component={GoogleLoginCallback}
        />
        <Route
          exact
          path="/accounts/facebook/login/callback/"
          component={FacebookLoginCallback}
        />
        <Route
          path="/redirect/"
          component={Redirect}
        />
        <Route
          exact
          path="/cocalc-redirect/"
          component={CocalcRedirect}
        />
        <Route
          path="/404/"
          component={NotFound}
        />
        <Route
          component={NotFound}
        />
      </Switch>
    </App>
  )

  return ({ history }) => (
    <Router history={history}>
      <Switch>
        <Route render={renderApp} />
      </Switch>
    </Router>
  )
}

export default createRouter
