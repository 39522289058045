import cn from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

import styles from './styles.module.scss'

const THEME_TO_CLASSNAME = {
  dark : styles.ThemeDark,
  light: styles.ThemeLight,
}

const Spinner = ({
  className,
  theme,
}) => (
  <div
    className={cn(
      styles.Spinner,
      THEME_TO_CLASSNAME[theme],
      className,
    )}
  >
    <div className={styles.Inner}>
      <div className={styles.Mainshell} />
      <div className={styles.Edge} />
      <div className={styles.Cshell} />
      <div className={styles.Inside} />
    </div>
  </div>
)

Spinner.propTypes = {
  theme: PropTypes.oneOf([
    'dark',
    'light',
  ]),
}

Spinner.defaultProps = {
  theme: 'dark',
}

export default Spinner
