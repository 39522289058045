import {
  has,
  isUndefined,
  union,
  keys,
  forEach,
  mergeWith,
  isArray,
} from 'lodash'

import { CLEAR_ENTITIES_ACTION, CLEAR_ENTITY_ACTION } from './constants'

const replaceArrays = (objValue, srcValue) => {
  if (isArray(srcValue)) {
    return srcValue
  }
  return undefined
}
/**
 * В состоянии entities хранятся данные в нормализованном виде
 * (подробнее о нормализации https://redux.js.org/recipes/structuringreducers/normalizingstateshape)
 * Данные разделены по сущностям и по id. Имеет следующий вид:
 *
 * {
 *   [<имя сущности>]: {
 *     [<id экземпляра сущности>]: <экземпляр сущности>,
 *     ...
 *   },
 *   ...
 * }
 *
 * Обрабатываются 3 вида событий:
 * 1. Удаление всех сущностей.
 * 2. Удаление одной сущности или удаление одного объекта сущности по идентификатору.
 * 3. Обновление сущности при наличии в экшне свойства `data.entities`
 */
const entitiesReducer = (state = {}, action) => {
  if (action.type === CLEAR_ENTITIES_ACTION) return {}
  if (action.type === CLEAR_ENTITY_ACTION) {
    if (isUndefined(state[action.entity])) return state
    if (!isUndefined(action.id) && isUndefined(state[action.entity][action.id])) return state

    const newState = { ...state }
    if (isUndefined(action.id)) {
      delete newState[action.entity]
    } else {
      delete newState[action.entity][action.id]
    }
    return newState
  }
  if (has(action, 'data.entities')) {
    const { entities } = action.data
    const entitiesKeys = union(keys(state), keys(entities))
    const newState = {}

    forEach(entitiesKeys, (entity) => {
      const entityState = state[entity]
      const newEntityState = entities[entity]

      if (newEntityState && entityState) {
        newState[entity] = mergeWith({}, entityState, newEntityState, replaceArrays)
      } else if (newEntityState && !entityState) {
        newState[entity] = newEntityState
      } else {
        newState[entity] = entityState
      }
    })

    return newState
  }

  return state
}

export default entitiesReducer
