import {
  PREVENT_LOADING_VIDEO,
  ALLOW_LOADING_VIDEO,
  UPDATE_COURSE_LESSON_NAME,
  SAVE_COURSE_LESSON_SUCCESS,
} from './constants'

export default {
  [PREVENT_LOADING_VIDEO](state) {
    return {
      ...state,
      preventLoadVideo: true,
    }
  },
  [ALLOW_LOADING_VIDEO](state) {
    return {
      ...state,
      preventLoadVideo: false,
    }
  },
  [UPDATE_COURSE_LESSON_NAME](state, action) {
    return {
      ...state,
      [action.courseLessonId]: {
        isChanged: true,
      },
    }
  },
  [SAVE_COURSE_LESSON_SUCCESS](state, action) {
    return {
      ...state,
      [action.data.result]: {
        isChanged: false,
      },
    }
  },
}
