import cn from 'classnames'
import React from 'react'
import PropTypes from 'prop-types'

import styles from './styles.module.scss'

const COLOR_TO_CLASSNAME = {
  black: styles.ColorBlack,
  gray : styles.ColorGray,
}

const SIZE_TO_CLASSNAME = {
  medium: styles.SizeMedium,
  large : styles.SizeLarge,
  small : styles.SizeSmall,
}

const Header1 = ({
  children,
  className,
  size,
  color,
  ...props
}) => (
  <h1
    className={
      cn(
        styles.Header1,
        COLOR_TO_CLASSNAME[color],
        SIZE_TO_CLASSNAME[size],
        className,
      )}
    {...props}
  >
    {children}
  </h1>
)

Header1.propTypes = {
  size: PropTypes.oneOf([
    'large',
    'medium',
    'small',
  ]),
  color: PropTypes.oneOf([
    'black',
    'gray',
  ]),
}

Header1.defaultProps = {
  size : 'large',
  color: 'black',
}

export default Header1
