import QRReader from 'react-qr-reader'
import React from 'react'
import { connect } from 'dva'
import {
  change,
  submit,
} from 'redux-form'

import { parseCredentials } from 'utils/qr'

import { t } from 'i18next'
import styles from './assets/QRReader.module.scss'
import { ReactComponent as QRCard } from './assets/QR-card.svg'
import { ReactComponent as QRIcon } from './assets/QR-icon.svg'

class SignInByQR extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      isScannerOpened: props.isScannerOpened,
      isErrorOccured : false,
    }
  }

  componentDidUpdate(prevProps) {
    const {
      isScannerOpened,
    } = this.props

    if (prevProps.isScannerOpened !== isScannerOpened) {
      this.setState({
        isScannerOpened,
      })
    }
  }

  handleScan = (result) => {
    if (!result) {
      return
    }

    const credentials = parseCredentials(result)

    if (credentials) {
      const { username, password } = credentials
      const { dispatch } = this.props

      dispatch(change('signIn', 'login', username))
      dispatch(change('signIn', 'password', password))
      dispatch(submit('signIn'))
    }
  }

  handleError = (error) => {
    this.setState({ isErrorOccured: true })
    if (error.name !== 'NotAllowedError') {
      throw error
    }
  }

  render() {
    const {
      isScannerOpened,
      onLoad,
      isErrorOccured,
    } = this.state

    if (isScannerOpened) {
      return (
        <div className={styles.Preview}>
          {!isErrorOccured && (
            <QRReader
              onError={this.handleError}
              onScan={this.handleScan}
              legacyMode={false}
              showViewFinder={false}
              onLoad={onLoad}
            />
          )}
          <div className={styles.Footer}>
            <QRCard />
            <span>
              {isErrorOccured
                ? t('Auth:error_qr_permissions')
                : t('Auth:qr_text')
              }
            </span>
          </div>
        </div>
      )
    }

    return (
      <div className={styles.QRReader} onClick={() => this.setState({ isScannerOpened: true })}>
        <QRIcon />
      </div>
    )
  }
}

const enhance = connect()

export default enhance(SignInByQR)
