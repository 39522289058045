export const MODEL = 'animation'

export const INITIAL_STATE = {
  lessonRun: {
    pointsAnimations: {
      addPoints: {
        display: false,
        points : 0,
      },
      losePoints: {
        display: false,
        points : 0,
      },
    },
  },
}

export const DISPLAY_ADD_POINTS_ANIMATION = 'DISPLAY_ADD_POINTS_ANIMATION'
export const HIDE_ADD_POINTS_ANIMATION = 'HIDE_ADD_POINTS_ANIMATION'
export const DISPLAY_LOSE_POINTS_ANIMATION = 'DISPLAY_LOSE_POINTS_ANIMATION'
export const HIDE_LOSE_POINTS_ANIMATION = 'HIDE_LOSE_POINTS_ANIMATION'
