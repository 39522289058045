import axios from 'utils/axios'

import urls from './urls'

export const getInfoPage = infoPageSlug => axios.get(
  urls.infoPage(infoPageSlug),
)

export const confirmGroupSubscription = (email, groupId) => axios.post(
  urls.confirmGroupSubscription(),
  { email, groupId },
)
