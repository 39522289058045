import {
  get,
  camelCase,
  curryRight,
  isObject,
  isEmpty,
  isNil,
  isArray,
  isNumber,
  transform,
  values,
} from 'lodash'
import jsonStableStringify from 'json-stable-stringify'
import { t } from 'i18next'

import { ALL } from './constants'

const stringify = curryRight(jsonStableStringify)({
  replacer: (key, value) => isNumber(value) ? String(value) : value,
})

/**
 * Возвращает ключ по переданным параметрам запроса.
 *
 * Если параметры запроса пустой объект, или undefined или null вернет ключ 'ALL'.
 *
 * Примечание: для объектов имеющих одни и те же ключи и значения вернет одинаковый ключ.
 * @param  {*} query Параметр запроса.
 * @return {string} Ключ, сгенерированный по переданным параметрам.
 */
export function getKeyForQuery(query) {
  if (isObject(query) && !isEmpty(query)) {
    return stringify(query)
  }
  if (!isObject(query) && !isNil(query)) {
    return query.toString()
  }

  return ALL
}

/**
 * Возвращает певую ошибку валидации от ответа АПИ
 *
 * @param  {string} key Ключ.
 * @return {Object} Параметры запроса.
 */
export function getQueryForKey(key) {
  if (key === ALL) return {}

  return JSON.parse(key)
}

const ALL_FIELDS_KEYS = [
  'non_fields_errors',
  '__all__',
]

/**
 * По ответу сервера и переданному словарю сообщений ошибок строит объект
 * ошибками, которые отображаются пользователю
 *
 * Найденные ошибки в userErrorMessages записываются в соответствующие поля,
 * последнее ненайденное записывается в _error.
 *
 * @param error {object} ответ от сервера
 * @param userErrorMessages {object} соответствие ошибок сервера полям и ошибкам клиента
 * @return {object} объект с ошибками
 */
export function extractValidationError(error, userErrorMessages) {
  const responseData = get(error, 'response.data')

  if (!responseData) {
    return false
  }

  return transform(
    responseData,
    (errors, value, key) => {
      let error = isObject(value) ? values(value)[0] : value
      error = isArray(error) ? error[0] : error
      const clientError = get(userErrorMessages, error)
      if (clientError) {
        errors[clientError.field] = clientError.text
      } else if (ALL_FIELDS_KEYS.find(value => value === key)) {
        errors._error = t('wrong_value')
      } else {
        errors[camelCase(key)] = t('wrong_value')
      }
      return errors
    },
    {},
  )
}

/**
 * Искуственная симуляция задержки
 *
 * @param  number время задерки в миллисекундах.
 * @return {promise}
 */
export function delay(ms) {
  return new Promise(resolve => setTimeout(() => resolve(true), ms))
}
