import {
  MODEL,
  INITIAL_STATE,
} from './constants'
import reducers from './reducers'

export default {
  namespace: MODEL,

  state: INITIAL_STATE,

  reducers,
}
