import { get } from 'lodash'
import { t } from 'i18next'
import { notify } from './actions'

export const onError = (error) => {
  if (error.skip) return

  if (error.critical) {
    // TODO при критической ошибке должна отобразиться страница ошибки на весь экран
  } else {
    const message = get(error, 'message') || t('Notify:error_message')

    notify({
      status: 'error',
      title : t('Notify:error_title'),
      message,
    })
  }
}

export const detectScrollbarWidth = () => {
  const scrollDiv = document.createElement('div')

  scrollDiv.style.setProperty('width', '100px')
  scrollDiv.style.setProperty('height', '100px')
  scrollDiv.style.setProperty('overflow', 'scroll')
  scrollDiv.style.setProperty('position', 'absolute')
  scrollDiv.style.setProperty('top', '-9999px')

  document.body.appendChild(scrollDiv)

  const scrollbarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth

  document.body.removeChild(scrollDiv)

  return scrollbarWidth
}
