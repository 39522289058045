export default {
  changePassword         : '/auth/password/change/',
  createUser             : purchase => `/auth/registration/${purchase ? `?purchase=${purchase}` : ''}`,
  login                  : '/auth/login/',
  logout                 : '/auth/logout/',
  resetPassword          : '/auth/password/reset/',
  resetPasswordConfirm   : '/auth/password/reset/confirm/',
  user                   : '/auth/user/',
  registerConfirm        : '/auth/registration/verify-email/',
  resendRegisterConfirm  : '/auth/registration/resend-confirmation/',
  purchaseRegisterConfirm: ({ purchase }) => (
    `/auth/registration/resend-confirmation/?purchase=${purchase}`
  ),
  getFreeLogin       : '/users/get_login/',
  socialGoogleLogin  : '/social/google/login/',
  socialFacebookLogin: '/social/facebook/login/',
}
