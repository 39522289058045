import { schema } from 'normalizr'

import { schema as problemSchema } from 'domains/problem'
import { schema as theorySchema } from 'domains/theory'

import { readMapper } from './adapters'
import { MODEL } from './constants'

const lessonProblemSchema = new schema.Entity(
  MODEL,
  {
    problem: problemSchema,
    theory : theorySchema,
  },
  {
    processStrategy: readMapper,
  },
)


export default lessonProblemSchema
