import { get, map } from 'lodash'

import { createAction } from 'utils/store'
import { t } from 'i18next'
import { normalize } from 'normalizr'
import { getKeyForQuery } from 'utils/api'
import { isAuthorized } from 'domains/user'
import { selectIsCourseBySubscription } from 'domains/course'
import * as api from './api'
import {
  ADD_SELECTED_COURSE,
  FETCH_ASSIGNED_COURSES_REQUEST,
  FETCH_ASSIGNED_COURSES_SUCCESS,
  FETCH_ASSIGNED_COURSES_FAIL,
  EXIT_FROM_COURSE,
  EXIT_FROM_COURSE_SUCCESS,
  MODEL,
} from './constants'
import readMapper from './adapters/readMapper'
import assignedCoursesSchema from './schema'
import { selectCourseForStudentIds } from './selectors'

function* fetchAssignedCourses({
  entity,
  key,
}, {
  call,
  put,
}) {
  try {
    const response = yield call(api.getAssignedCourses)
    const assignedCourses = get(response, 'data')
    const coursesForTeacher = []
    const coursesForStudent = []

    const mappedAssignedCourses = map(assignedCourses, (course) => {
      const mappedCourse = readMapper(course)
      if (mappedCourse.isForTeacher) coursesForTeacher.push(course.id)
      if (mappedCourse.isForStudent) coursesForStudent.push(course.id)
      return mappedCourse
    })

    yield put(createAction(FETCH_ASSIGNED_COURSES_SUCCESS, {
      entity,
      key,
      data: normalize(mappedAssignedCourses, assignedCoursesSchema),
      coursesForTeacher,
      coursesForStudent,
    }))
  } catch (error) {
    yield put(createAction(FETCH_ASSIGNED_COURSES_FAIL, {
      entity,
      key,
      error,
    }))
  }
}

function* exitFromCourse({
  courseId,
  onError,
}, {
  call,
  put,
}) {
  try {
    yield call(api.exitFromCourse, courseId)
    yield put(createAction(MODEL, EXIT_FROM_COURSE_SUCCESS, { courseId }))
  } catch (error) {
    if (onError) {
      onError()
    } else {
      throw Error(t('Course:error_message_try_reload'))
    }
  }
}

function* addSelectedCourse({ id }, effectCreators) {
  const {
    call,
    select,
  } = effectCreators
  if (!(yield select(isAuthorized))) {
    return
  }

  if (!(yield select(selectIsCourseBySubscription, id))) {
    return
  }

  const assignedCoursesKey = getKeyForQuery()
  let studentCourses = yield select(selectCourseForStudentIds)
  if (!studentCourses) {
    yield call(
      fetchAssignedCourses,
      { entity: MODEL, key: assignedCoursesKey },
      effectCreators,
    )
    studentCourses = yield select(selectCourseForStudentIds)
  }
  if (studentCourses.find(value => value === id) > -1) {
    // курс уже есть в ученических курсах
    return
  }

  yield call(api.createSelectedCourse, id)
  yield call(
    fetchAssignedCourses,
    { entity: MODEL, key: assignedCoursesKey },
    effectCreators,
  )
}

export default {
  [FETCH_ASSIGNED_COURSES_REQUEST]: fetchAssignedCourses,
  [EXIT_FROM_COURSE]              : exitFromCourse,
  [ADD_SELECTED_COURSE]           : addSelectedCourse,
}
