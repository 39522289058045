import cn from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import { get, noop } from 'lodash'
import { compose } from 'recompose'

import withAutoFocus from 'uikit/hocs/withAutoFocus'

import styles from './styles.module.scss'

const CONTROL_TYPE_TO_CLASSNAME = {
  checkbox: styles.ControlTypeCheckbox,
  radio   : styles.ControlTypeRadio,
}

const THEME_TO_CLASSNAME = {
  dark   : styles.ThemeDark,
  light  : styles.ThemeLight,
  alemira: styles.ThemeAlemira,
}

const SIZE_TO_CLASSNAME = {
  medium: styles.SizeMedium,
}

const KIND_TO_CLASSNAME = {
  button: styles.KindButton,
}

class SelectionControl extends React.Component {
  static propTypes = {
    inline     : PropTypes.bool,
    controlType: PropTypes.oneOf([
      'checkbox',
      'radio',
    ]),
    kind: PropTypes.oneOf([
      'simple',
      'button',
    ]),
    onChange   : PropTypes.func,
    simpleValue: PropTypes.bool,
    size       : PropTypes.oneOf([
      'medium',
    ]),
    theme: PropTypes.oneOf([
      'dark',
      'light',
      'alemira',
    ]),
    labelKey: PropTypes.string,
    valueKey: PropTypes.string,
  }

  static defaultProps = {
    labelKey   : 'label',
    onChange   : noop,
    simpleValue: true,
    size       : 'medium',
    theme      : 'dark',
    valueKey   : 'value',
  }

  onChange = () => {
    const {
      onChange,
      simpleValue,
      value,
      valueKey,
    } = this.props

    let checkedValue

    if (simpleValue) {
      checkedValue = get(value, valueKey)
    } else {
      checkedValue = value
    }

    onChange(checkedValue)
  }

  render() {
    const {
      checked,
      children,
      className,
      disabled,
      inline,
      kind,
      label,
      labelKey,
      onFocus,
      size,
      theme,
      controlType,
      value,
    } = this.props

    return (
      <label
        className={cn(
          styles.SelectionControl,
          className,
          SIZE_TO_CLASSNAME[size],
          CONTROL_TYPE_TO_CLASSNAME[controlType],
          THEME_TO_CLASSNAME[theme],
          KIND_TO_CLASSNAME[kind],
          {
            [styles.Inline]  : inline,
            [styles.Disabled]: disabled,
            [styles.Checked] : checked,
          }
        )}
      >
        <input
          autoComplete="off"
          className={styles.Control}
          value={value}
          checked={checked}
          type={controlType}
          onChange={this.onChange}
          onFocus={onFocus}
          disabled={disabled}
        />
        <div className={styles.Mark} />
        <span className={styles.Label}>
          {label || children || (labelKey ? get(value, labelKey) : value)}
        </span>
      </label>
    )
  }
}

const enhance = compose(
  withAutoFocus,
)

export default enhance(SelectionControl)
