import { schema } from 'normalizr'
import { readMapper } from './adapters'
import {
  MODEL,
  WEEKS_MODEL,
  COURSE_LINES_MODEL,
  COURSE_LINE_DATA_MODEL,
} from './constants'

const courseLineDataSchema = new schema.Entity(
  COURSE_LINE_DATA_MODEL, {
    weeks: [new schema.Entity(WEEKS_MODEL)],
  },
  {
    idAttribute    : (value, parent) => parent.id,
    processStrategy: (value, parent) => ({
      ...value,
      id: parent.id,
    }),
  }
)
const courseLineSchema = new schema.Entity(
  COURSE_LINES_MODEL,
  {
    data: courseLineDataSchema,
  },
  {
    processStrategy: readMapper,
  },
)

const schoolSchema = new schema.Entity(
  MODEL,
  {
    [COURSE_LINES_MODEL]: [courseLineSchema],
  },
  {
    processStrategy: readMapper,
  },
)

export default schoolSchema
