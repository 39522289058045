import { schema } from 'normalizr'

import { schema as lessonSchema } from 'domains/lesson'

import { readMapper } from './adapters'
import { MODEL, TEACHER_LESSON_MODEL } from './constants'


export const courseLessonSchema = new schema.Entity(
  MODEL,
  {
    lesson: lessonSchema,
  },
  {
    processStrategy: readMapper,
  },
)

export const teacherLessonSchema = new schema.Entity(
  TEACHER_LESSON_MODEL,
  {
  },
  {
    processStrategy: readMapper,
  },
)
