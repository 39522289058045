import {
  isArray,
  isPlainObject,
  map,
  snakeCase,
  transform,
} from 'lodash'

export default function camelToSnake(object) {
  return transform(object, (result, value, key) => {
    let newValue

    if (isPlainObject(value)) {
      newValue = camelToSnake(value)
    } else if (isArray(value)) {
      newValue = map(value, element => isPlainObject(element)
        ? camelToSnake(element)
        : element)
    } else {
      newValue = value
    }

    result[snakeCase(key)] = newValue
  }, {})
}
