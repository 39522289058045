import { get, isEmpty } from 'lodash'
import { routerRedux } from 'dva/router'
import HttpStatus from 'http-status-codes'

import { createAction } from 'utils/store'

import {
  FETCH_COURSE_PROBLEMS_USAGE_REQUEST,
  FETCH_COURSE_PROBLEMS_USAGE_SUCCESS,
  FETCH_COURSE_PROBLEMS_USAGE_FAIL,
} from './constants'
import * as api from './api'

function* fetchCourseProblemsUsage({
  entity,
  key,
  problemIds,
  courseId,
}, {
  call,
  put,
}) {
  try {
    if (isEmpty(problemIds)) {
      yield put(createAction(FETCH_COURSE_PROBLEMS_USAGE_SUCCESS, {
        entity,
        key,
        data: { entities: { [entity]: { [key]: {} } } },
      }))
      return
    }

    const response = yield call(api.getCourseFindProblems, {
      courseId,
      problemIds,
    })
    const problemsUsage = get(response, 'data')

    yield put(createAction(FETCH_COURSE_PROBLEMS_USAGE_SUCCESS, {
      entity,
      key,
      data: { entities: { [entity]: { [key]: problemsUsage } } },
    }))
  } catch (error) {
    yield put(createAction(FETCH_COURSE_PROBLEMS_USAGE_FAIL, {
      entity,
      key,
      error,
    }))
    if (error.response.status >= HttpStatus.INTERNAL_SERVER_ERROR) {
      throw error
    }
    yield put(routerRedux.replace('/404/'))
  }
}

export default {
  [FETCH_COURSE_PROBLEMS_USAGE_REQUEST]: fetchCourseProblemsUsage,
}
