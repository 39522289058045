import { schema } from 'normalizr'
import { courseLessonSchema, teacherLessonSchema } from 'domains/courseLesson'

import { readMapper } from './adapters'
import {
  MODEL,
  COURSE_WITH_GROUP_MODEL,
  PROMOCODE_KEY,
} from './constants'

const purchaseOfferseSchema = new schema.Entity(
  'purchaseOffers',
  {},
  {
    processStrategy: readMapper,
  },
)
export const courseSchema = new schema.Entity(
  MODEL,
  {
    courseLessons : [courseLessonSchema],
    purchaseOffers: [purchaseOfferseSchema],
  },
  {
    processStrategy: readMapper,
  },
)

export const applyPromoCodeSchema = new schema.Entity(
  'promoCodeData',
  {},
  {
    idAttribute    : () => PROMOCODE_KEY,
    processStrategy: readMapper,
  },
)

export const teacherCourseSchema = new schema.Entity(
  'teacherCourse',
  {
    courseLessons: [teacherLessonSchema],
  },
  {
    processStrategy: readMapper,
  },
)

export const courseWithGroupSchema = new schema.Entity(
  COURSE_WITH_GROUP_MODEL,
  {},
  {
    processStrategy: readMapper,
  },
)
