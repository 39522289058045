export const MODEL = 'blocks'

export const BLOCK_MARGIN_BOTTOM = 20

export const INITIAL_STATE = {
  blocks     : [],
  blockHeight: null,
}

export const SET_BLOCK_HEIGHT = 'SET_BLOCK_HEIGHT'

export const GET_BLOCKS = 'GET_BLOCKS'
export const SET_BLOCKS = 'SET_BLOCKS'
