import { schema } from 'normalizr'

import { schema as lessonProblemSchema } from 'domains/lessonProblem'

import { readMapper } from './adapters'
import { MODEL } from './constants'

const lessonSchema = new schema.Entity(
  MODEL,
  {
    problems      : [lessonProblemSchema],
    lessonProblems: [lessonProblemSchema],
  },
  {
    processStrategy: readMapper,
  },
)

export default lessonSchema
