import React from 'react'
import { compose } from 'recompose'
import { connect } from 'dva'
import { withRouter } from 'dva/router'
import { I18nextProvider } from 'react-i18next'

import i18n from 'utils/i18n'

import RegisterTeacherModal from 'components/Auth/RegisterTeacherModal'
import RegisterUserModal from 'components/Auth/RegisterUserModal'
import ResetPasswordModal from 'components/Auth/ResetPasswordModal'
import SigninModal from 'components/Auth/SignInModal'
import EnterNameModal from 'components/Auth/EnterNameModal'
import AlemiraSigninModal from 'alemiraPages/SignInModal'
import Spinner from 'uikit/components/Spinner'
import {
  bootstrapApplication,
  selectIsLoading,
  selectSiteCode,
  loadSiteConfig,
  selectI18NextInitialized,
  setI18NextInitialized,
} from 'domains/app'
import {
  selectUser,
  userHasDefaultName,
} from 'domains/user'
import {
  userParams as sendYMUserParams,
} from 'domains/ym'
import { NotificationContainer } from 'uikit/components/Notification'
import {
  SIGN_IN_HASH,
  SIGN_IN_ON_PAGE_HASH,
  ALEMIRA_SIGN_IN_HASH,
  RESET_PASSWORD,
  REGISTER_TEACHER_HASH,
  REGISTER_USER_HASH,
  REGISTER_USER_ON_PAGE_HASH,
  ENTER_NAME_AFTER_LOGIN_HASH,
} from 'components/Auth/route-constants'
import {
  SITE_CODE_TO_GTM_ID,
  initializeGoogleTagManager,
} from 'integrations/googleTagManager'
import { isProduction } from 'environment'

import { addAppLocaleData } from './localeData'
import './style.scss'

const SEND_YM_USER_PARAMS_DELAY = 5000

addAppLocaleData()

class App extends React.Component {
  componentDidMount() {
    const {
      bootstrapApplication,
      loadSiteConfig,
      setI18NextInitialized,
    } = this.props

    loadSiteConfig()
    bootstrapApplication()
    i18n.on('initialized', () => {
      setI18NextInitialized()
    })
    if (i18n.isInitialized) {
      setI18NextInitialized()
    }
  }

  componentDidUpdate(prevProps) {
    const { siteCode: prevSiteCode } = prevProps
    const { siteCode: currentSiteCode, sendYMUserParams } = this.props

    if (!isProduction) {
      return
    }

    if (!prevSiteCode && currentSiteCode && currentSiteCode in SITE_CODE_TO_GTM_ID) {
      initializeGoogleTagManager(SITE_CODE_TO_GTM_ID[currentSiteCode])
      window.setTimeout(sendYMUserParams, SEND_YM_USER_PARAMS_DELAY)
    }
  }

  onModalClose = () => {
    const { history, location } = this.props
    history.replace({ ...location, hash: '' })
  }

  renderModal() {
    const {
      location,
      user,
    } = this.props

    const props = {
      isOpen : true,
      onClose: this.onModalClose,
    }

    switch (location.hash) {
      case SIGN_IN_HASH:
        return <SigninModal {...props} />

      case SIGN_IN_ON_PAGE_HASH:
        return <SigninModal stayOnPage {...props} />

      case ALEMIRA_SIGN_IN_HASH:
        return <AlemiraSigninModal {...props} />

      case RESET_PASSWORD:
        return <ResetPasswordModal {...props} />

      case REGISTER_TEACHER_HASH:
        return <RegisterTeacherModal {...props} />

      case REGISTER_USER_HASH:
        return <RegisterUserModal {...props} />

      case REGISTER_USER_ON_PAGE_HASH:
        return <RegisterUserModal notifyWithMessage {...props} />

      case ENTER_NAME_AFTER_LOGIN_HASH:
        return (user && userHasDefaultName(user)) ? <EnterNameModal {...props} /> : null

      default:
        return null
    }
  }

  render() {
    const {
      children,
      isLoading,
      isI18NextInitialized,
    } = this.props

    if (isLoading) return <Spinner />

    return (
      <I18nextProvider i18n={i18n}>
        <React.Fragment>
          <NotificationContainer />
          {children}
          {isI18NextInitialized && this.renderModal()}
        </React.Fragment>
      </I18nextProvider>
    )
  }
}

const enhance = compose(
  withRouter,

  connect(
    state => ({
      isLoading           : selectIsLoading(state),
      siteCode            : selectSiteCode(state),
      isI18NextInitialized: selectI18NextInitialized(state),
      user                : selectUser(state),
    }),
    {
      bootstrapApplication,
      loadSiteConfig,
      setI18NextInitialized,
      sendYMUserParams,
    },
  ),
)

export default enhance(App)
