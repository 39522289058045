import { MODEL, INITIAL_STATE } from './constants'
import effects from './effects'
import reducers from './reducers'

export default {
  namespace: MODEL,

  state: INITIAL_STATE,

  effects,

  reducers,
}
