import { createAction } from 'utils/store'

import {
  FETCH_LIBRARY_FILTERS,
  FETCH_LIBRARY_PROBLEMS,
  MODEL,
  SET_LIBRARY_DATA,
} from './constants'

export const fetchLibraryFilters = courseId => createAction(MODEL, FETCH_LIBRARY_FILTERS, { courseId })
export const fetchLibraryProblems = params => createAction(MODEL, FETCH_LIBRARY_PROBLEMS, params)
export const setLibraryData = data => createAction(SET_LIBRARY_DATA, data)
