import { createAction } from 'utils/store'

import {
  MODEL,
  PLAY_AUDIO,
} from './constants'

export const playAudio = audioElementId => createAction(
  MODEL,
  PLAY_AUDIO,
  { audioElementId }
)
