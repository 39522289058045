import {
  SET_USER,
  REMOVE_USER,
  REGISTER_CONFIRM_REQUEST,
  REGISTER_CONFIRM_EXPIRED,
  REGISTER_CONFIRM_SUCCESS,
  RESEND_CONFIRM_REQUEST,
  RESEND_CONFIRM_SUCCESS,
  RESEND_CONFIRM_FAIL,

  AVAILABLE_LOGIN_REQUEST,
  AVAILABLE_LOGIN_SUCCESS,
  AVAILABLE_LOGIN_FAIL,
} from './constants'

export default {
  [SET_USER](state, action) {
    return {
      ...state,
      user: action.user,
    }
  },
  [REMOVE_USER](state) {
    return {
      ...state,
      user: null,
    }
  },

  [REGISTER_CONFIRM_REQUEST](state) {
    return {
      ...state,
      registerConfirm: {
        requesting: true,
        expired   : false,
        success   : false,
      },
    }
  },
  [REGISTER_CONFIRM_SUCCESS](state) {
    return {
      ...state,
      registerConfirm: {
        requesting: false,
        expired   : false,
        success   : true,
      },
    }
  },
  [REGISTER_CONFIRM_EXPIRED](state) {
    return {
      ...state,
      registerConfirm: {
        requesting: false,
        expired   : true,
        success   : false,
      },
    }
  },
  [RESEND_CONFIRM_REQUEST](state) {
    return {
      ...state,
      resendConfirm: {
        requesting: true,
        error     : false,
        success   : false,
      },
    }
  },
  [RESEND_CONFIRM_SUCCESS](state) {
    return {
      ...state,
      resendConfirm: {
        requesting: false,
        error     : false,
        success   : true,
      },
    }
  },
  [RESEND_CONFIRM_FAIL](state) {
    return {
      ...state,
      resendConfirm: {
        requesting: false,
        error     : true,
        success   : false,
      },
    }
  },

  [AVAILABLE_LOGIN_REQUEST](state) {
    return {
      ...state,
      freeLogin: {
        requesting: true,
        error     : false,
        success   : null,
      },
    }
  },

  [AVAILABLE_LOGIN_FAIL](state) {
    return {
      ...state,
      freeLogin: {
        requesting: false,
        error     : true,
        result    : null,
      },
    }
  },

  [AVAILABLE_LOGIN_SUCCESS](state, action) {
    return {
      ...state,
      freeLogin: {
        requesting: false,
        error     : false,
        result    : action.login,
      },
    }
  },
}
