import 'react-toastify/dist/ReactToastify.css'
import React from 'react'
import cn from 'classnames'
import {
  toast as baseToast,
  ToastContainer,
} from 'react-toastify'
import { t } from 'i18next'

import { showVincent } from '../../../utils/appearance'
import styles from './styles.module.scss'
import { ReactComponent as CloseIcon } from './close.svg'
import vincentImg from './img/vincent.png'

const DEFAULT_CLOSE_TIMEOUT = 5000

export const NOTIFICATION_STATUS = {
  INFO   : 'info',
  ERROR  : 'error',
  SUCCESS: 'success',
}

class Message extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      height: 0,
    }
  }

  componentDidMount() {
    const height = this.divElement.clientHeight
    this.setState({ height })
  }

  render() {
    const {
      title, closeToast, status, message, emoji,
    } = this.props

    const { height } = this.state

    return (

      <div
        className={styles.NotificationWrapper} ref={(divElement) => {
          this.divElement = divElement
        }}
      >
        <div
          className={cn(styles.Header, status === NOTIFICATION_STATUS.ERROR ? styles.Error : '')}
        >
          { emoji
          && (
            <span role="img" className={styles.Emoji}>
              {emoji}
            </span>
          )
          }
          <div className="title">{title}</div>
        </div>
        {message && (
          <div className={styles.Message}>{message}</div>
        )}

        <button
          type="button" className={styles.CloseButton}
          onClick={closeToast}
        >
          <CloseIcon />
        </button>

        {showVincent && height > 150 && !emoji
          && (
            <div className={styles.Vincent}>
              <img src={vincentImg} alt={t('Notification:img_vincent_alt')} />
            </div>
          )
        }
      </div>
    )
  }
}


export function notify({
  title,
  message,
  emoji,
  autoClose,
  status = NOTIFICATION_STATUS.INFO,
}) {
  baseToast(
    <Message
      title={title}
      status={status}
      message={message}
      emoji={emoji}
    />,
    {
      className    : styles.Reset,
      bodyClassName: styles.Body,
      closeButton  : false,
      autoClose    : autoClose || DEFAULT_CLOSE_TIMEOUT,
    },
  )
}

const Information = ({
  closeToast,
  message,
  clickable,
}) => (
  <div
    className={styles.InformationWrapper}
  >
    <div className={styles.Information}>{message}</div>

    <button
      type="button" className={cn({
        [styles.CloseButton]     : true,
        [styles.ContentClickable]: !!clickable,
      })}
      onClick={closeToast}
    >
      <CloseIcon />
    </button>

    {showVincent && (
      <div className={styles.Vincent}>
        <img src={vincentImg} alt={t('Notification:img_vincent_alt')} />
      </div>
    )}
  </div>
)

export function showInformation({
  message,
  onClose,
  clickable,
}) {
  return baseToast(
    <Information
      message={message}
      clickable={clickable}
    />,
    {
      className    : styles.Reset,
      bodyClassName: styles.Body,
      closeButton  : false,
      autoClose    : false,
      onClose,
    },
  )
}

export function dismissInformation(informationToastId) {
  baseToast.dismiss(informationToastId)
}

export function NotificationContainer() {
  return (
    <ToastContainer
      hideProgressBar
      className={styles.Container}
    />
  )
}
