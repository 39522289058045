import {
  SITE_CODES,
} from 'environment'

export const MODEL = 'ym'

export const REACH_GOAL = 'REACH_GOAL'
export const USER_PARAMS = 'USER_PARAMS'

export const GOAL_NAMES = {
  USER_BUYS_PAID_COURSE        : 'USER_BUYS_PAID_COURSE',
  USER_BUYS_FREE_COURSE        : 'USER_BUYS_FREE_COURSE',
  USER_REGISTERS_ON_LESSON_PAGE: 'USER_REGISTERS_ON_LESSON_PAGE',
  USER_REGISTERS_ON_COURSE_PAGE: 'USER_REGISTERS_ON_COURSE_PAGE',
  USER_REGISTERS_ON_MAIN_PAGE  : 'USER_REGISTERS_ON_MAIN_PAGE',
  USER_STARTS_SIGN_IN          : 'USER_STARTS_SIGN_IN',
  ONBOARDING_LESSON_CLICK      : 'ONBOARDING_LESSON_CLICK',
  ONBOARDING_CLOSE             : 'ONBOARDING_CLOSE',
}

export const SITE_CODE_TO_YM_COUNTER = {
  [SITE_CODES.PELIKAN]   : 54932353,
  [SITE_CODES.EN_PELIKAN]: null,
  [SITE_CODES.CH_PELIKAN]: null,
  [SITE_CODES.HELPEGE]   : null,
  [SITE_CODES.ROBOBORIK] : null,
}

export const GOAL_NAME_TO_SITE_SPECIFIC_GOAL_NAME = {
  [GOAL_NAMES.USER_REGISTERS_ON_MAIN_PAGE]: {
    [SITE_CODES.PELIKAN]: 'register-user-app-pelican-study',
  },
  [GOAL_NAMES.USER_REGISTERS_ON_COURSE_PAGE]: {
    [SITE_CODES.PELIKAN]: 'register-user-course',
  },
  [GOAL_NAMES.USER_REGISTERS_ON_LESSON_PAGE]: {
    [SITE_CODES.PELIKAN]: 'register-user-lesson',
  },
  [GOAL_NAMES.USER_BUYS_PAID_COURSE]: {
    [SITE_CODES.PELIKAN]: 'button-price',
  },
  [GOAL_NAMES.USER_BUYS_FREE_COURSE]: {
    [SITE_CODES.PELIKAN]: 'button-price-free',
  },
  [GOAL_NAMES.USER_STARTS_SIGN_IN]: {
    [SITE_CODES.PELIKAN]: 'course-button-signin',
  },
  [GOAL_NAMES.ONBOARDING_CLOSE]: {
    [SITE_CODES.PELIKAN]: 'onboarding-close',
  },
  [GOAL_NAMES.ONBOARDING_LESSON_CLICK]: {
    [SITE_CODES.PELIKAN]: 'onboarding-lesson-click',
  },
}
