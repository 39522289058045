import { compose } from 'recompose'
import snakeToCamel from 'utils/mappers/snakeToCamel'
import createMapper from 'utils/mappers/createMapper'
import {
  CLASS_MODEL,
  COURSE_LINES_MODEL,
  STUDENTS_MODEL,
  TOPICS_MODEL,
  LESSONS_MODEL,
  NUMBERS_MODEL,
} from '../constants'

const mapKeys = createMapper({
  class      : CLASS_MODEL,
  courseLines: COURSE_LINES_MODEL,
  students   : STUDENTS_MODEL,
  topics     : TOPICS_MODEL,
  lessons    : LESSONS_MODEL,
  numbers    : NUMBERS_MODEL,
})

export const readMapper = compose(
  mapKeys,
  snakeToCamel,
)

export const readMapperWithClassId = compose(
  mapKeys,
  snakeToCamel,
  (value, parent) => ({ ...value, schoolClass: `${value.id}-${parent.class.id}` })
)

export const readMapperWithSchoolClassLine = compose(
  mapKeys,
  snakeToCamel,
  (value, parent) => ({ ...value, schoolClassLine: `${parent.schoolClass}-${value.id}` })
)
