import { withProps } from 'recompose'

import Header1 from './Header1'

const MediumHeader1 = withProps({
  size : 'medium',
  color: 'gray',
})(Header1)


export default MediumHeader1
