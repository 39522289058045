import { get } from 'lodash'

import { MODEL } from './constants'

export const selectCurrentlyPlayingAudioElementId = state => get(
  state,
  [
    MODEL,
    'currentlyPlayingAudioElementId',
  ]
)
