import axios from 'utils/axios'

import urls from './urls'

export const getCourses = () => axios.get(urls.courses)
export const getCourse = courseId => axios.get(urls.course(courseId))
export const getProblems = (courseId, excludeLessonId, selectedLesson, page) => axios.get(urls.problems, {
  params: {
    course        : courseId,
    exclude_lesson: excludeLessonId,
    lesson        : selectedLesson,
    page,
  },
})
