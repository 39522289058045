export const isTouchDevice = Boolean(
  typeof window !== 'undefined'
  && typeof navigator !== 'undefined'
  && ('ontouchstart' in window || navigator.msMaxTouchPoints > 0)
)

export const isMouseDevice = Boolean(typeof window !== 'undefined' && 'onmousedown' in window)

export const pixelRatio = (typeof window !== 'undefined' && window.devicePixelRatio)
  ? Math.max(window.devicePixelRatio, 1)
  : 1
