import { schema } from 'normalizr'

import { readMapper } from './adapters'
import { MODEL } from './constants'

const problemSchema = new schema.Entity(
  MODEL,
  {},
  {
    processStrategy: readMapper,
  },
)

export default problemSchema
