import React from 'react'

import PelicanInput from 'uikit/components/Input'

const Input = props => (
  <PelicanInput
    {...props}
    theme="alemira"
  />
)

export default Input
