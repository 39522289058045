import { SET_CONFIRM_GROUP_SUBSCRIPTION_RESULT_CODE } from './constants'

export default {
  [SET_CONFIRM_GROUP_SUBSCRIPTION_RESULT_CODE](state, action) {
    return {
      ...state,
      confirmGroupSubscriptionResultCode: action.resultCode,
    }
  },
}
