import {
  transform,
  isPlainObject,
  isArray,
  map,
  camelCase,
} from 'lodash'

export default function snakeToCamel(object) {
  return transform(object, (result, value, key) => {
    let newValue

    if (isPlainObject(value)) {
      newValue = snakeToCamel(value)
    } else if (isArray(value)) {
      newValue = map(value, element => isPlainObject(element)
        ? snakeToCamel(element)
        : element)
    } else {
      newValue = value
    }

    result[camelCase(key)] = newValue
  }, {})
}
