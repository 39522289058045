import {
  FETCH_COURSE_STATS_SUCCESS,
  SET_REFETCH_COURSE_STATS,
  FETCH_TEACHER_COURSE_STATS_SUCCESS,
  SET_REFETCH_TEACHER_COURSE_STATS,
} from './constants'

export default {
  [SET_REFETCH_TEACHER_COURSE_STATS](state) {
    return {
      ...state,
      shouldRefetch: true,
    }
  },
  [SET_REFETCH_COURSE_STATS](state) {
    return {
      ...state,
      shouldRefetch: true,
    }
  },
  [FETCH_COURSE_STATS_SUCCESS](state) {
    return {
      ...state,
      shouldRefetch: false,
    }
  },
  [FETCH_TEACHER_COURSE_STATS_SUCCESS](state) {
    return {
      ...state,
      shouldRefetch: false,
    }
  },
}
