import {
  JOIN_COURSE_REQUEST,
  JOIN_COURSE_SUCCESS,
  JOIN_COURSE_FAIL,
  SET_REFETCH_COURSE,
  FETCH_COURSE_SUCCESS,
  SET_COURSE_LESSON_HINT_ID,
} from './constants'

export default {
  [JOIN_COURSE_REQUEST](state) {
    return {
      ...state,
      joinCourse: {
        requesting: true,
        error     : null,
      },
    }
  },
  [JOIN_COURSE_SUCCESS](state) {
    return {
      ...state,
      joinCourse: {
        requesting: false,
        error     : null,
      },
    }
  },
  [JOIN_COURSE_FAIL](state, action) {
    return {
      ...state,
      joinCourse: {
        requesting: false,
        error     : action.data,
      },
    }
  },
  [SET_REFETCH_COURSE](state) {
    return {
      ...state,
      shouldRefetch: true,
    }
  },
  [FETCH_COURSE_SUCCESS](state) {
    return {
      ...state,
      shouldRefetch: false,
    }
  },
  [SET_COURSE_LESSON_HINT_ID](state, { value }) {
    return {
      ...state,
      сourseLessonHintId: value,
    }
  },
}
