export const MODEL = 'assignedCourses'

// Action types
export const FETCH_ASSIGNED_COURSES_REQUEST = 'FETCH_ASSIGNED_COURSES_REQUEST'
export const FETCH_ASSIGNED_COURSES_SUCCESS = 'FETCH_ASSIGNED_COURSES_SUCCESS'
export const FETCH_ASSIGNED_COURSES_FAIL = 'FETCH_ASSIGNED_COURSES_FAIL'

export const EXIT_FROM_COURSE = 'EXIT_FROM_COURSE'
export const EXIT_FROM_COURSE_SUCCESS = 'EXIT_FROM_COURSE_SUCCESS'

export const ADD_SELECTED_COURSE = 'ADD_SELECTED_COURSE'
