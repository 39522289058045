import axios from 'utils/axios'

import urls from './urls'

export const postUploadFile = (file) => {
  const formData = new FormData()
  formData.append('file', file)
  return axios.post(urls.upload, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}
