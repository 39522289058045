import {
  transform,
  has,
  isFunction,
  isPlainObject,
  assign,
} from 'lodash'

/**
 * Создает функцию преобразующую объект, согласно переданному мап-объекту,
 * при этом если какого-то ключа из исходного объекта нет в мап-объекте ключ и значение
 * будут скопированы в конечный объект без изменений.
 *
 * Мап-объект должен иметь такую структуру:
 * {
 *   [<имя поля в исходном объекте>]: <имя поля в конечном объекте>,
 *   [<имя поля в исходном объекте>]: (value, key, srcObject) => ({
 *      [<имя поля в конечном объекте>]: <значение>
 *      ...
 *    })
 * }
 */
export default map => object => transform(
  object,
  (result, value, key, sourceObject) => {
    let fieldMappingResult = { [key]: value }

    if (has(map, key)) {
      if (isFunction(map[key])) {
        fieldMappingResult = map[key](value, key, sourceObject)

        if (!isPlainObject(fieldMappingResult)) {
          throw new Error(
            `Expected a result of mapping function to be plain object, but get ${typeof fieldMappingResult}`
          )
        }
      } else {
        fieldMappingResult = { [map[key]]: value }
      }
    }

    assign(result, fieldMappingResult)
  },
  {},
)
