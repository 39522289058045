import { GET_BLOCKS } from './constants'
import * as api from './api'
import { setBlocks } from './actions'
import { blocksReadMapper } from './adapters'

function* getBlocks(_, { call, put }) {
  const response = yield call(api.getBlocks)
  const blocks = blocksReadMapper(response.data)
  yield put(setBlocks(blocks))
}

export default {
  [GET_BLOCKS]: getBlocks,
}
