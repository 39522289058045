// Статусы ответов.
export const ANSWER_STATUSES = {
    INCORRECT: 0,
    CORRECT: 1,
    NOT_EVALUATED: 2,
    SKIPPED_CORRECT: 3 // статус присутствует только на фронтенде
};

// Типы занятий.
export const COURSE_LESSON_MODES = {
    LESSON: 1,
    CONTROL_WORK: 2,
    WEBINAR: 3,
    DIAGNOSTICS: 4
};

export const EVALUABLE_COURSE_LESSON_MODES = [
    COURSE_LESSON_MODES.CONTROL_WORK, COURSE_LESSON_MODES.DIAGNOSTICS
];

// Статусы задач.
export const PROBLEM_STATUSES = {
    INCORRECT: 0,
    CORRECT: 1,
    PROGRESS: 2,
    SKIPPED: 3,
    NO_ANSWER: 4,
    NOT_EVALUATED: 5,
    ANSWERED: 6
};

// Типы задач.
export const PROBLEM_TYPES = {
    PROBLEM: 1,
    TRAINER: 2,
    THEORY: 3
};

export const PROBLEM_TYPE_CONSTANTS = {
    [PROBLEM_TYPES.PROBLEM]: {NAME: 'problem', ROUTE: 'problems'},
    [PROBLEM_TYPES.TRAINER]: {NAME: 'trainer', ROUTE: 'trainers'},
    [PROBLEM_TYPES.THEORY]: {NAME: 'theory', ROUTE: 'theories'}
};

// Названия типов ролей
export const ROUTE_ROLES = {
    STUDENT: 'student',
    TEACHER: 'teacher',
    PARENT: 'parent'
};

// Названия типов задач.
export const PROBLEM_TYPE_NAMES = Object.keys(PROBLEM_TYPES)
    .reduce((names, name) => {
        names[PROBLEM_TYPES[name]] = name;
        return names;
    }, {});

export const NAME_MAX_LENGTH = 30;
