import { schema } from 'normalizr'

import {
  MODEL,
  CLASS_MODEL,
  COURSE_LINES_MODEL,
  COURSE_LINE_DATA_MODEL,
  STUDENTS_MODEL,
  TOPICS_MODEL,
  LESSONS_MODEL,
  NUMBERS_MODEL,
  BY_LESSON_MODEL,
  LESSON_MARKS_MODEL,
  BY_NUMBER_MODEL,
  NUMBER_MARKS_MODEL,
  BY_TOPIC_MODEL,
  TOPIC_MARKS_MODEL,
} from './constants'

import {
  readMapperWithClassId,
  readMapperWithSchoolClassLine,
} from './adapters'


const byStudentId = {
  processStrategy: (value, parent) => ({
    ...value,
    schoolClassLine: parent.schoolClassLine,
  }),
  idAttribute: (value, parent) => `${parent.schoolClassLine}-${value.studentId}`,
}
const byMarkAndStudentId = {
  idAttribute: (value, parent) => `${parent.schoolClassLine}-${value.id}-${parent.studentId}`,
}

const courseLineDataSchema = new schema.Entity(
  COURSE_LINE_DATA_MODEL,
  {
    students: [
      new schema.Entity(
        STUDENTS_MODEL,
        {},
        {
        },
      )],
    topics: [
      new schema.Entity(
        TOPICS_MODEL,
        {},
        {
          processStrategy: (value, parent) => ({
            ...value,
            schoolClassLine: parent.schoolClassLine,
          }),
          idAttribute: (value, parent) => `${parent.schoolClassLine}-${value.id}`,
        },
      )],
    lessons: [
      new schema.Entity(
        LESSONS_MODEL,
        {},
        {
          processStrategy: (value, parent) => ({
            ...value,
            schoolClassLine: parent.schoolClassLine,
          }),
          idAttribute: (value, parent) => `${parent.schoolClassLine}-${value.id}`,
        },
      )],
    numbers: [
      new schema.Entity(
        NUMBERS_MODEL,
        {},
        {
          processStrategy: (value, parent) => ({
            ...value,
            schoolClassLine: parent.schoolClassLine,
          }),
          idAttribute: (value, parent) => `${parent.schoolClassLine}-${value.id}`,
        },
      )],
    byLesson: [new schema.Entity(
      BY_LESSON_MODEL,
      {
        marks: [new schema.Entity(
          LESSON_MARKS_MODEL,
          {},
          {
            ...byMarkAndStudentId,
          }
        )],
      },
      { ...byStudentId }
    )],
    byNumber: [new schema.Entity(
      BY_NUMBER_MODEL,
      {
        marks: [new schema.Entity(
          NUMBER_MARKS_MODEL,
          {},
          {
            ...byMarkAndStudentId,
          }
        )],
      },
      { ...byStudentId }
    )],
    byTopic: [new schema.Entity(
      BY_TOPIC_MODEL,
      {
        marks: [new schema.Entity(
          TOPIC_MARKS_MODEL,
          {},
          {
            ...byMarkAndStudentId,
          }
        )],
      },
      { ...byStudentId }
    )],
  },
  {
    idAttribute    : (value, parent) => parent.schoolClassLine,
    processStrategy: (value, parent) => ({
      ...value,
      id             : parent.id,
      schoolClassLine: parent.schoolClassLine,
    }),
  }
)
const courseLineSchema = new schema.Entity(
  COURSE_LINES_MODEL,
  {
    data: courseLineDataSchema,
  },
  {
    idAttribute    : (value, parent) => `${parent.schoolClass}-${value.id}`,
    processStrategy: readMapperWithSchoolClassLine,
  },
)

const courseLineStatSchema = new schema.Entity(
  MODEL,
  {
    [CLASS_MODEL]       : new schema.Entity(CLASS_MODEL),
    [COURSE_LINES_MODEL]: [courseLineSchema],
  },
  {
    idAttribute    : (value, parent) => (`${value.id}-${parent.class.id}`),
    processStrategy: readMapperWithClassId,
  },
)

export default courseLineStatSchema
