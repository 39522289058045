import { get, isUndefined } from 'lodash'
import { normalize } from 'normalizr'
import HttpStatus from 'http-status-codes'

import { createAction, clearEntity } from 'utils/store'
import { notify as alemiraNotify } from 'uikit/alemiraComponents/Notification'

import {
  writeMapper,
} from './adapters'
import * as api from './api'
import {
  ANONYMOUS_RESULT_ID,
  FETCH_COURSE_LESSON_RESULT_REQUEST,
  FETCH_COURSE_LESSON_RESULT_SUCCESS,
  FETCH_COURSE_LESSON_RESULT_FAIL,
  CREATE_COURSE_LESSON_RESULT_REQUEST,
  CREATE_COURSE_LESSON_RESULT_SUCCESS,
  CREATE_COURSE_LESSON_RESULT_FAIL,
  UPDATE_COURSE_LESSON_RESULT_REQUEST,
  UPDATE_COURSE_LESSON_RESULT_SUCCESS,
  UPDATE_COURSE_LESSON_RESULT_FAIL,
  FETCH_OR_CREATE_ACTIVE_COURSE_LESSON_RESULT_REQUEST,
  FETCH_OR_CREATE_ACTIVE_COURSE_LESSON_RESULT_SUCCESS,
  FETCH_OR_CREATE_ACTIVE_COURSE_LESSON_RESULT_FAIL,
  CHECK_DEMO_COURSE_LESSON_RESULT_REQUEST,
  CHECK_DEMO_COURSE_LESSON_RESULT_SUCCESS,
  CHECK_DEMO_COURSE_LESSON_RESULT_FAIL,
  CLEAR_AND_CHECK_DEMO_COURSE_LESSON_RESULT_REQUEST,
  CLEAR_AND_CHECK_DEMO_COURSE_LESSON_RESULT_SUCCESS,
  CLEAR_AND_CHECK_DEMO_COURSE_LESSON_RESULT_FAIL,
  FETCH_COURSE_LESSON_RESULT_FOR_TEACHER_REQUEST,
  FETCH_COURSE_LESSON_RESULT_FOR_TEACHER_FAIL,
  FETCH_COURSE_LESSON_RESULT_FOR_TEACHER_SUCCESS,
  MODEL,
} from './constants'
import { courseLessonResultSchema, demoCourseLessonResultSchema } from './schema'

function* fetchOrCreateActiveCourseLessonResult({
  courseLessonId,
  studentId,
  entity,
  key,
  isAuthorized,
  demoResult,
  isAlemira,
}, {
  call,
  put,
}) {
  if (!isAuthorized) {
    yield put(createAction(FETCH_OR_CREATE_ACTIVE_COURSE_LESSON_RESULT_SUCCESS, {
      entity,
      key,
      data: {},
    }))
    return
  }

  const answers = (
    !isUndefined(demoResult) && (`${demoResult.courseLesson}` === courseLessonId)
      ? writeMapper(demoResult.answers)
      : {}
  )

  try {
    let response = yield call(api.findCourseLessonResult, courseLessonId, studentId)
    let result = get(response, ['data', 'results', 0])

    if (!result) {
      response = yield call(api.createCourseLessonAttempt, courseLessonId, answers)
      result = get(response, ['data'])
    }

    if (!isUndefined(demoResult)) {
      yield put(clearEntity(MODEL, ANONYMOUS_RESULT_ID))
    }

    yield put(createAction(FETCH_OR_CREATE_ACTIVE_COURSE_LESSON_RESULT_SUCCESS, {
      entity,
      key,
      data: normalize(result, courseLessonResultSchema),
    }))
  } catch (error) {
    yield put(createAction(FETCH_OR_CREATE_ACTIVE_COURSE_LESSON_RESULT_FAIL, {
      entity,
      key,
      error,
    }))
    if (isAlemira) {
      alemiraNotify()
    } else if (error.response.status >= HttpStatus.INTERNAL_SERVER_ERROR) {
      throw error
    }
  }
}

function* fetchCourseLessonResult({
  courseLessonId,
  entity,
  key,
}, {
  call,
  put,
}) {
  try {
    const response = yield call(api.findCourseLessonResult, courseLessonId)
    const result = get(response, ['data', 'results', 0])

    yield put(createAction(FETCH_COURSE_LESSON_RESULT_SUCCESS, {
      entity,
      key,
      data: normalize(result, courseLessonResultSchema),
    }))
  } catch (error) {
    yield put(createAction(FETCH_COURSE_LESSON_RESULT_FAIL, {
      entity,
      key,
      error,
    }))
    throw error
  }
}

function* fetchCourseLessonResultForTeacher({
  courseLessonId,
  studentId,
  entity,
  key,
}, {
  call,
  put,
}) {
  try {
    const response = yield call(api.getCourseLessonResultForTeacher, courseLessonId, studentId)
    const result = get(response, 'data')

    yield put(createAction(FETCH_COURSE_LESSON_RESULT_FOR_TEACHER_SUCCESS, {
      entity,
      key,
      data: normalize(result, courseLessonResultSchema),
    }))
  } catch (error) {
    if (error.response.status === HttpStatus.NOT_FOUND) {
      yield put(createAction(FETCH_COURSE_LESSON_RESULT_FOR_TEACHER_SUCCESS, {
        entity,
        key,
        data: {},
      }))
      return
    }
    yield put(createAction(FETCH_COURSE_LESSON_RESULT_FOR_TEACHER_FAIL, {
      entity,
      key,
      error,
    }))
    throw error
  }
}

function* createCourseLessonAttempt({
  courseLessonId,
  entity,
  key,
}, {
  call,
  put,
}) {
  try {
    const response = yield call(api.findCourseLessonResult, writeMapper({ courseLessonId }))
    const result = get(response, 'data')

    yield put(createAction(CREATE_COURSE_LESSON_RESULT_SUCCESS, {
      entity,
      key,
      data: normalize(result, courseLessonResultSchema),
    }))
  } catch (error) {
    yield put(createAction(CREATE_COURSE_LESSON_RESULT_FAIL, {
      entity,
      key,
      error,
    }))
    throw error
  }
}

// TODO:
// нам надо в запрос обновления еще добавлять dste_updated=<value>, значение берется из текущего результата
//
// это чтобы разные вкладки не конфликтовали между собой
//
// при отказе обновления, надо перезапрашивать результат, обновлять состояние,
// и пытаться обновить с новым date_updated, если это не получится
// (например, во второй вкладке израсходовали все попытки), то ничего не делать
function* updateCourseLessonResult({
  courseLessonResultId,
  data,
  entity,
  key,
  options,
  isAlemira,
}, {
  call,
  put,
}) {
  try {
    const response = yield call(
      api.updateCourseLessonResult,
      courseLessonResultId,
      writeMapper(data),
      options,
    )

    const result = get(response, 'data')

    yield put(createAction(UPDATE_COURSE_LESSON_RESULT_SUCCESS, {
      entity,
      key,
      data: normalize(result, courseLessonResultSchema),
    }))
  } catch (error) {
    yield put(createAction(UPDATE_COURSE_LESSON_RESULT_FAIL, {
      entity,
      key,
      error,
    }))
    if (isAlemira) {
      alemiraNotify()
    } else {
      throw error
    }
  }
}

function* clearAndCheckDemoCourseLessonResult({
  courseLessonId,
  isAuthorized,
  entity,
  key,
}, {
  call,
  put,
}) {
  if (isAuthorized) {
    yield put(createAction(CLEAR_AND_CHECK_DEMO_COURSE_LESSON_RESULT_SUCCESS, {
      entity,
      key,
      data: {},
    }))
    return
  }

  yield put(clearEntity(MODEL, ANONYMOUS_RESULT_ID))

  try {
    const response = yield call(api.checkDemoCourseLessonResult, writeMapper({ courseLesson: courseLessonId }))
    const result = get(response, 'data')

    yield put(createAction(CLEAR_AND_CHECK_DEMO_COURSE_LESSON_RESULT_SUCCESS, {
      entity,
      key,
      data: normalize(result, demoCourseLessonResultSchema),
    }))
  } catch (error) {
    yield put(createAction(CLEAR_AND_CHECK_DEMO_COURSE_LESSON_RESULT_FAIL, {
      entity,
      key,
      error,
    }))
    if (error.response.status >= HttpStatus.INTERNAL_SERVER_ERROR) {
      throw error
    }
  }
}

function* checkDemoCourseLessonResult({
  courseLessonId,
  entity,
  key,
  data,
}, {
  call,
  put,
}) {
  try {
    data.courseLesson = courseLessonId
    const response = yield call(api.checkDemoCourseLessonResult, writeMapper(data))
    const result = get(response, 'data')

    yield put(createAction(CHECK_DEMO_COURSE_LESSON_RESULT_SUCCESS, {
      entity,
      key,
      data: normalize(result, demoCourseLessonResultSchema),
    }))
  } catch (error) {
    yield put(createAction(CHECK_DEMO_COURSE_LESSON_RESULT_FAIL, {
      entity,
      key,
      error,
    }))
    if (error.response.status >= HttpStatus.INTERNAL_SERVER_ERROR) {
      throw error
    }
  }
}
export default {
  [FETCH_COURSE_LESSON_RESULT_REQUEST]                 : fetchCourseLessonResult,
  [CREATE_COURSE_LESSON_RESULT_REQUEST]                : createCourseLessonAttempt,
  [UPDATE_COURSE_LESSON_RESULT_REQUEST]                : updateCourseLessonResult,
  [FETCH_OR_CREATE_ACTIVE_COURSE_LESSON_RESULT_REQUEST]: fetchOrCreateActiveCourseLessonResult,
  [CHECK_DEMO_COURSE_LESSON_RESULT_REQUEST]            : checkDemoCourseLessonResult,
  [CLEAR_AND_CHECK_DEMO_COURSE_LESSON_RESULT_REQUEST]  : clearAndCheckDemoCourseLessonResult,
  [FETCH_COURSE_LESSON_RESULT_FOR_TEACHER_REQUEST]     : fetchCourseLessonResultForTeacher,
}
