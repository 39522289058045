import { createAction } from 'utils/store'
import { ALL } from 'utils/api'

import {
  MODEL,
  FETCH_ASSIGNED_COURSES_REQUEST,
  EXIT_FROM_COURSE,
  ADD_SELECTED_COURSE,
} from './constants'

export const fetchAssignedCourses = () => createAction(
  MODEL,
  FETCH_ASSIGNED_COURSES_REQUEST,
  {
    entity: MODEL,
    key   : ALL,
  }
)
export const exitFromCourse = data => createAction(MODEL, EXIT_FROM_COURSE, data)

export const addSelectedCourse = courseId => createAction(
  MODEL,
  ADD_SELECTED_COURSE,
  { id: courseId }
)
