import axios from 'utils/axios'
import {
  forEach,
  keys,
} from 'lodash'

import { OUTPUT_IMAGE_EXTENSION } from './constants'
import urls from './urls'

export const login = credentials => axios.post(urls.login, credentials)

export const logout = () => axios.post(urls.logout)

export const changePassword = ({ oldPassword, newPassword }) => axios.post(
  urls.changePassword,
  {
    old_password: oldPassword,
    password    : newPassword,
  },
)


export const resetPassword = email => axios.post(urls.resetPassword, { email })

export const resetPasswordConfirm = credentials => axios.post(
  urls.resetPasswordConfirm,
  credentials,
)

export const registerConfirm = ({ key, searchString }) => axios.post(
  searchString ? `${urls.registerConfirm}${searchString}` : urls.registerConfirm,
  { key },
)

export const createUser = ({ personalInfo, purchase }) => axios.post(urls.createUser(purchase), personalInfo)

export const getUser = () => axios.get(urls.user)

export const updateUser = (update) => {
  const updateFormData = new FormData()

  forEach(
    keys(update),
    (key) => {
      if (key === 'avatar') {
        updateFormData.set(key, update[key], `avatar.${OUTPUT_IMAGE_EXTENSION}`)
      } else {
        updateFormData.set(key, update[key])
      }
    }
  )

  return axios.patch(
    urls.user,
    updateFormData,
    { headers: { 'Content-type': 'multipart/form-data' } },
  )
}

export const resendRegisterConfirmEmail = () => axios.post(
  urls.resendRegisterConfirm
)

export const sendPurchaseRegisterConfirm = ({ purchase }) => axios.post(
  urls.purchaseRegisterConfirm({ purchase }),
)

export const getFreeLogin = () => axios.get(
  urls.getFreeLogin
)

export const postSocialGoogleLogin = ({ code }) => axios.post(
  urls.socialGoogleLogin,
  { code },
)

export const postSocialFacebookLogin = ({ code }) => axios.post(
  urls.socialFacebookLogin,
  { code },
)
