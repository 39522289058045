const nameSpaces = [
  'Auth',
  'ChartByWeek',
  'ChartHorizont',
  'Monitoring',
  'User',
  'UserProfile',
  'Solution',
  'CourseLesson',
  'Course',
  'TeacherCourse',
  'TeacherLessonResults',
  'TeacherCourseGroup',
  'CourseLineStat',
  'ChangePassword',
  'PasswordConfirm',
  'NotFound',
  'ResetPassword',
  'Notify',
  'Header',
  'AddCourse',
  'JoinCourseCard',
  'SchoolStatisticCard',
  'InfoPage',
  'RegisterConfirm',
  'Input',
  'VerifyEmail',
  'Notification',
  'YoutubePlayer',
  'MarksResults',
  'Pelican',
  'SubscriptionMessage',
  'PromoCode',
  'Classroom',
]

const path = 'src/client/locales/'

const languages = ['zh', 'ru', 'en']

module.exports = {
  nameSpaces,
  path,
  languages,
}
