import { createAction } from 'utils/store'

import {
  MODEL,
  FETCH_SCHOOL_REQUEST,
} from './constants'

export const fetchSchool = ({ schoolId, courseLineId }) => createAction(
  MODEL,
  FETCH_SCHOOL_REQUEST,
  {
    entity: MODEL,
    key   : schoolId,
    schoolId,
    courseLineId,
  },
)
