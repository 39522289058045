import { get, map } from 'lodash'
import { denormalize } from 'normalizr'
import { PROBLEM_TYPES } from 'legacy/constants'

import {
  selectEntity,
  selectEntitiesSubstate,
  selectApiState,
} from 'utils/api'

import {
  MODEL,
  TEACHER_LESSON_MODEL,
  getKeyForSaveCourseLesson,
} from './constants'
import { courseLessonSchema, teacherLessonSchema } from './schema'

export const selectProblems = (state, path) => get(state, [MODEL, ...path])

export const selectCourseLesson = (state, lessonId) => denormalize(
  selectEntity(state, MODEL, lessonId),
  courseLessonSchema,
  selectEntitiesSubstate(state),
)

export const selectAlemiraCourseLesson = (state, { courseLessonId: lessonId }) => denormalize(
  selectEntity(state, MODEL, lessonId),
  courseLessonSchema,
  selectEntitiesSubstate(state),
)

export const selectTeacherCourseLesson = (state, lessonId) => denormalize(
  selectEntity(state, TEACHER_LESSON_MODEL, lessonId),
  teacherLessonSchema,
  selectEntitiesSubstate(state),
)

export const selectStudentCourseLessonForTeacher = (state, lessonId) => {
  const courseLesson = selectTeacherCourseLesson(state, lessonId)
  if (courseLesson) {
    courseLesson.lesson.problems = courseLesson.lesson.lessonProblems
  }
  return courseLesson
}

export const selectCourseLessonIsChanged = (state, courseLessonId) => get(
  state,
  [MODEL, courseLessonId, 'isChanged'],
)

export const selectCourseLessonIsSaving = (state, courseLessonId) => (
  get(selectApiState(state, MODEL, getKeyForSaveCourseLesson(courseLessonId)), 'isFetching')
)

export const selectUpdatableCourseLessonData = (state, courseLessonId) => {
  const courseLesson = selectEntity(state, TEACHER_LESSON_MODEL, courseLessonId)
  return {
    id    : courseLessonId,
    lesson: {
      id            : courseLesson.lesson.id,
      name          : courseLesson.lesson.name,
      lessonProblems: map(courseLesson.lesson.lessonProblems, (lessonProblem) => {
        if (lessonProblem.type === PROBLEM_TYPES.PROBLEM) {
          return {
            id     : lessonProblem.id,
            options: lessonProblem.options,
            type   : lessonProblem.type,
            problem: {
              id: lessonProblem.problem.id,
            },
          }
        }
        // PROBLEM_TYPES.THEORY
        return {
          id     : lessonProblem.id,
          options: lessonProblem.options,
          type   : lessonProblem.type,
          theory : {
            id: lessonProblem.theory.id,
          },
        }
      }),
    },
  }
}

export const selectTeacherCourseLessonsByIds = (state, ids) => ids.map(
  id => selectTeacherCourseLesson(state, id)
)

export const selectCourseLessonProblems = (state, lessonId) => {
  const courseLesson = selectCourseLesson(state, lessonId)
  return get(courseLesson, ['lesson', 'problems'])
}

export const selectCourseLessonProblemIdbyProblemIndex = (state, lessonId, problemIndex) => {
  const courseLesson = selectCourseLesson(state, lessonId)
  return get(courseLesson, ['lesson', 'problems', problemIndex - 1, 'id'])
}

export const selectPreventLoadVideo = state => get(state, [MODEL, 'preventLoadVideo'])
