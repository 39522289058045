import {
  get,
  stubFalse,
  isUndefined,
  isEqual,
} from 'lodash'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import {
  branch,
  componentFromProp,
  compose,
  defaultProps,
  lifecycle,
  renderComponent,
  setPropTypes,
  withProps,
} from 'recompose'

import { getKeyForQuery } from './helpers'
import { selectApiState } from './selectors'

const Spinner = componentFromProp('Spinner')

/**
 * Higher order component для загруки данных, относящихся к какому-либо домену.
 * ApiTransport передают загруженные данные и состояние о загрузке (`isFetching`)
 * вниз по дереву компонент.
 * Загруженные данные сохраняются в стор, локального стейта ApiTransport не имеет.
 *
 * ApiTransport(
 *   propsMapper: (ownerProps: Object) => Object | Object,
 * ): HigherOrderComponent
 */
const ApiTransport = createProps => compose(
  withProps(createProps),

  defaultProps({
    dataPropName       : 'data',
    fetchStatusPropName: 'isFetching',
    shouldRefetch      : stubFalse,
    getKeyForQuery,
    isDataStale        : isUndefined,
  }),

  setPropTypes({
    // название сущности, экземпляры которой будут запрошены
    entity             : PropTypes.string.isRequired,
    // параметры запроса
    query              : PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    // селектор, для получения из стора запрошенных данных
    dataSelector       : PropTypes.func.isRequired,
    // генератор ключа по запросу, по этому ключу данные будут храниться в api стейте стора
    getKeyForQuery     : PropTypes.func,
    // имя пропа, по которому в оборачиваемый компонент будут переданы загруженные данные
    dataPropName       : PropTypes.string,
    // имя пропа, по которому в оборачиваемый компонент будет передано состояние загрузки
    fetchStatusPropName: PropTypes.string,
    // экшен, инициирущий загрузку данных
    apiAction          : PropTypes.func.isRequired,
    // компонент, который показывается во время запроса
    Spinner            : PropTypes.func,
    // если true, данные  будут загружены повторно, даже если были загружены ранее
    shouldRefetch      : PropTypes.func,
    // если true, данные будут загружены при добавлении компонента
    isDataStale        : PropTypes.func,
  }),

  connect(
    (state, {
      dataPropName,
      dataSelector,
      fetchStatusPropName,
      getKeyForQuery,
      entity,
      query,
    }) => {
      const keyForQuery = getKeyForQuery(query)
      const apiState = selectApiState(state, entity, keyForQuery)
      const isFetching = get(apiState, 'isFetching')
      const data = dataSelector(state, query)

      return ({
        [dataPropName]       : data,
        [fetchStatusPropName]: isFetching,
      })
    },
    (dispatch, { apiAction, query }) => ({
      fetch: () => dispatch(apiAction(query)),
    })
  ),

  lifecycle({
    componentDidMount() {
      const {
        fetch,
        fetchStatusPropName,
        dataPropName,
        [fetchStatusPropName]: isFetching,
        [dataPropName]: data,
        isDataStale,
      } = this.props

      // Если загрузка еще не начата или данные не заданы, начинаем
      if (isUndefined(isFetching) || (isDataStale(data) && !isFetching)) fetch()
    },
    componentDidUpdate(prevProps) {
      const {
        fetch,
        query,
        fetchStatusPropName,
        shouldRefetch,
        [fetchStatusPropName]: isFetching,
      } = this.props

      // При изменении параметров запроса, производит повторный вызов
      if (!isFetching && (shouldRefetch(prevProps, this.props) || !isEqual(query, prevProps.query))) {
        fetch()
      }
    },
  }),

  // Спинер показывается если он передан, и если загрузка еще не началась или началась, но не закончилась
  branch(
    ({
      fetchStatusPropName,
      [fetchStatusPropName]: isFetching,
      Spinner,
    }) => (isUndefined(isFetching) || isFetching) && Spinner,
    renderComponent(Spinner),
  ),
)

export default ApiTransport
