import PropTypes from 'prop-types'
import React from 'react'

import Modal from 'uikit/components/Modal'

import SignInForm from './SignInForm'
import styles from './styles.module.scss'

const Signin = ({
  isOpen,
}) => (
  <Modal
    isOpen={isOpen}
    hideCloseButton
    innerWrapperClassname={styles.Modal}
  >
    <SignInForm />
  </Modal>
)

Signin.propTypes = {
  isOpen: PropTypes.bool,
}

export default Signin
