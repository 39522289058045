export const MODEL = 'infoPage'

export const SUBSCRIPTION_RESULT_CODES = {
  SUCCESS     : 1,
  ALREADY_DONE: 2,
  EMAIL_ERROR : 3,
  OTHER_ERROR : 4,
}

// Action types
export const FETCH_INFO_PAGE_REQUEST = 'FETCH_INFO_PAGE_REQUEST'
export const FETCH_INFO_PAGE_SUCCESS = 'FETCH_INFO_PAGE_SUCCESS'
export const FETCH_INFO_PAGE_FAIL = 'FETCH_INFO_PAGE_FAIL'
export const CONFIRM_GROUP_SUBSCRIPTION = 'CONFIRM_GROUP_SUBSCRIPTION'
export const SET_CONFIRM_GROUP_SUBSCRIPTION_RESULT_CODE = 'SET_CONFIRM_GROUP_SUBSCRIPTION_RESULT_CODE'
