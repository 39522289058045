import { schema } from 'normalizr'

import { readMapper } from './adapters'
import { MODEL, ANONYMOUS_RESULT_ID } from './constants'

export const courseLessonResultSchema = new schema.Entity(
  MODEL,
  {},
  {
    processStrategy: readMapper,
  },
)

export const demoCourseLessonResultSchema = new schema.Entity(
  MODEL,
  {},
  {
    idAttribute    : () => ANONYMOUS_RESULT_ID,
    processStrategy: readMapper,
  },
)
