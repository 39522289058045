import axios from 'axios'
import { urlHasParameters } from 'utils/urls'

import { API_BASE } from 'environment'

const instance = axios.create({
  baseURL: API_BASE,
})

const AUTHORIZATION_TOKEN_STORAGE_KEY = 'AUTHORIZATION_TOKEN_STORAGE_KEY'

export const setAuthorizationToken = (token) => {
  instance.defaults.headers.common.Authorization = `Token ${token}`
  window.localStorage.setItem(AUTHORIZATION_TOKEN_STORAGE_KEY, token)
}

export const removeAuthorizationToken = () => {
  delete instance.defaults.headers.common.Authorization
  window.localStorage.removeItem(AUTHORIZATION_TOKEN_STORAGE_KEY)
}

// Базовые настройки для всех запросов
instance.defaults.headers.get.Accept = 'application/json'
instance.defaults.headers.post['Content-Type'] = 'application/json'
instance.defaults.headers.put['Content-Type'] = 'application/json'
instance.defaults.timeout = 15000

// nginx-конфиг требует, чтобы url заканчивался слэшом,
// перед тем как выполнить запрос убеждаемся, что он есть
instance.interceptors.request.use((config) => {
  if (!urlHasParameters(config.url) && config.url[config.url.length - 1] !== '/') {
    config.url += '/'
  }
  return config
})

const token = window.localStorage.getItem(AUTHORIZATION_TOKEN_STORAGE_KEY)

if (token) {
  setAuthorizationToken(token)
}

export default instance

export const hasAuthorizationToken = () => Boolean(instance.defaults.headers.common.Authorization)
