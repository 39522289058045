export function parseCredentials(value) {
  const matchParts = value.match(/(?:pelican:\/\/)(.+)(?:@auth)/i)

  if (!matchParts) {
    return false
  }

  const [username, password] = matchParts[1].split(':')

  if (!matchParts || matchParts.length !== 2) {
    return false
  }

  return {
    username,
    password,
  }
}
