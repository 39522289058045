import React from 'react'
import { isString } from 'lodash'
import { Field as ReduxFormField } from 'redux-form'

const wrappedCustomComponents = new WeakMap()
const wrappedNativeComponents = new Map()

const wrapComponent = WrappedComponent => ({
  input,
  ...props
}) => (
  <WrappedComponent
    input={input}
    {...props}
    {...input}
    onBlur={() => input.onBlur(input.value)}
  />
)

const getWrappedComponent = (Component, map) => {
  if (!map.has(Component)) {
    map.set(Component, wrapComponent(Component))
  }

  return map.get(Component)
}

const getComponent = Component => isString(Component)
  ? getWrappedComponent(Component, wrappedNativeComponents)
  : getWrappedComponent(Component, wrappedCustomComponents)

const FormField = ({
  component,
  ...props
}) => (
  <ReduxFormField
    {...props}
    component={getComponent(component)}
  />
)

export default FormField
