import {
  get,
  find,
} from 'lodash'

import {
  selectEntities,
  selectEntity,
} from 'utils/api'

import {
  MODEL,
  ANONYMOUS_RESULT_ID,
} from './constants'

const selectResults = state => selectEntities(state, MODEL)

export const selectCourseLessonResult = (state, { courseLessonId }) => {
  const results = selectResults(state)

  return find(
    results,
    result => (`${result.courseLesson}` === courseLessonId) && result.id
  )
}

export const selectCourseLessonResultForTeacher = (state, { courseLessonId, studentId }) => {
  const results = selectResults(state)

  return find(
    results,
    result => (`${result.courseLesson}` === courseLessonId
                && `${result.student}` === studentId
                && result.id)
  )
}

export const selectDemoCourseLessonResult = state => selectEntity(
  state,
  MODEL,
  ANONYMOUS_RESULT_ID,
)

export const selectCurrentProblemStartTime = state => get(
  state,
  [MODEL, 'currentProblemStartTime']
)
