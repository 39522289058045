import cn from 'classnames'
import React from 'react'

import styles from './styles.module.scss'

const Paragraph = ({
  children,
  className,
}) => (
  <p className={cn(styles.P, className)}>{children}</p>
)

export default Paragraph
