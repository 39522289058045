import { castArray } from 'lodash'

import { API_ACTION_SUFFIXES, CLEAR_API_ACTION } from './constants'

const getApiActionSuffix = actionType => actionType.split('_').pop()

const updateEntitiesList = (
  state = {
    isFetching: false,
    ids       : [],
  },
  action,
) => {
  const { type } = action

  switch (getApiActionSuffix(type)) {
    case API_ACTION_SUFFIXES.REQUEST:
      return {
        ...state,
        isFetching: true,
        requested : Date.now(),
      }

    case API_ACTION_SUFFIXES.SUCCESS:
      return {
        ...state,
        isFetching: false,
        ids       : castArray(action.data.result),
        updated   : Date.now(),
      }

    case API_ACTION_SUFFIXES.FAIL:
      return {
        ...state,
        isFetching: false,
        failed    : true,
        updated   : Date.now(),
      }

    default:
      return state
  }
}

/**
 * В состоянии api хранится информация о запросах к бэкенд-апи. Имеет следующий вид:
 *
 * {
 *   [<имя сущности>]: {
 *     [<ключ запроса>]: {
 *        isFetching: bool, // состояние запроса (идет загрузка / загрузка окончена)
 *        requested : number, // timestamp начала запроса
 *        updated   : number, // timestamp конца запроса
 *        failed    : bool, // true если запрос завершился ошибкой
 *        ids       : Array<string | number> // id данныхб полученных в ответе
 *     }
 *   }
 *   ...
 * }
 */
const apiReducer = (state = {}, action) => {
  const {
    type,
    entity,
    key = 'all',
  } = action

  if (type === CLEAR_API_ACTION) return {}
  if (!entity) return state

  switch (getApiActionSuffix(type)) {
    case API_ACTION_SUFFIXES.REQUEST:
    case API_ACTION_SUFFIXES.SUCCESS:
    case API_ACTION_SUFFIXES.FAIL: {
      const entityList = state[action.entity] || {}
      entityList[key] = updateEntitiesList(entityList[key], action)

      return {
        ...state,
        [entity]: entityList,
      }
    }

    default:
      return state
  }
}

export default apiReducer
