import {
  UPLOAD_FILE_SUCCESS,
} from './constants'

export default {
  [UPLOAD_FILE_SUCCESS](state, action) {
    return {
      ...state,
      ...action.data,
    }
  },
}
