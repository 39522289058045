import { createAction } from 'utils/store'

import {
  MODEL,
  REACH_GOAL,
  USER_PARAMS,
} from './constants'

export const reachGoal = goalName => createAction(MODEL, REACH_GOAL, { goalName })
export const userParams = () => createAction(MODEL, USER_PARAMS)
