import { get } from 'lodash'
import { routerRedux } from 'dva/router'
import { normalize } from 'normalizr'
import HTTPStatusCodes from 'http-status-codes'

import { createAction } from 'utils/store'

import {
  FETCH_INFO_PAGE_REQUEST,
  FETCH_INFO_PAGE_SUCCESS,
  FETCH_INFO_PAGE_FAIL,
  CONFIRM_GROUP_SUBSCRIPTION,
  SUBSCRIPTION_RESULT_CODES,
  SET_CONFIRM_GROUP_SUBSCRIPTION_RESULT_CODE,
} from './constants'
import * as api from './api'
import infoPageSchema from './schema'

function* fetchInfoPage({
  pageSlug,
  entity,
  key,
}, {
  call,
  put,
}) {
  try {
    const response = yield call(api.getInfoPage, pageSlug)
    const infoPage = get(response, 'data')

    yield put(createAction(FETCH_INFO_PAGE_SUCCESS, {
      entity,
      key,
      data: normalize(infoPage, infoPageSchema),
    }))
  } catch (error) {
    yield put(routerRedux.replace('/404/'))
    yield put(createAction(FETCH_INFO_PAGE_FAIL, {
      entity,
      key,
      error,
    }))
  }
}

function* confirmGroupSubscription({ email, groupId }, { call, put }) {
  const response = yield call(api.confirmGroupSubscription, email, groupId)

  let resultCode

  switch (response.status) {
    case HTTPStatusCodes.OK:
      resultCode = SUBSCRIPTION_RESULT_CODES.SUCCESS
      break

    case HTTPStatusCodes.BAD_REQUEST:
      resultCode = response.data.errors === 'invalid email adress'
        ? SUBSCRIPTION_RESULT_CODES.EMAIL_ERROR
        : SUBSCRIPTION_RESULT_CODES.OTHER_ERROR
      break

    case HTTPStatusCodes.CONFLICT:
      resultCode = SUBSCRIPTION_RESULT_CODES.ALREADY_DONE
      break

    default:
      resultCode = SUBSCRIPTION_RESULT_CODES.OTHER_ERROR
  }

  yield put(createAction(SET_CONFIRM_GROUP_SUBSCRIPTION_RESULT_CODE, { resultCode }))
}

export default {
  [FETCH_INFO_PAGE_REQUEST]   : fetchInfoPage,
  [CONFIRM_GROUP_SUBSCRIPTION]: confirmGroupSubscription,
}
