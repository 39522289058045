import googleTagManager from 'react-gtm-module'

import { SITE_CODES } from 'environment'

export const SITE_CODE_TO_GTM_ID = {
  [SITE_CODES.PELIKAN]  : 'GTM-TDJLC84',
  [SITE_CODES.HELPEGE]  : 'GTM-ND7W8V4',
  [SITE_CODES.ROBOBORIK]: 'GTM-WT2MGLV',
}

export function initializeGoogleTagManager(gtmId) {
  const config = { gtmId }
  googleTagManager.initialize(config)
}
