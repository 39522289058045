import {
  SET_PURCHASE,
  SELECTED_PURCHASE,
} from './constants'

export default {
  [SET_PURCHASE](state, action) {
    return {
      ...state,
      [SELECTED_PURCHASE]: {
        ...(state ? state[SELECTED_PURCHASE] : {}),
        ...action.data,
      },
    }
  },
}
