import axios from 'utils/axios'

import urls from './urls'

export const getSchool = ({ schoolId, courseLineId }) => axios.get(
  urls.school(schoolId),
  {
    params: {
      course_line: courseLineId,
    },
  },
)
