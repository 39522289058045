import {
  START_PROBLEM,
} from './constants'

export default {
  [START_PROBLEM](state) {
    return {
      ...state,
      currentProblemStartTime: Date.now(),
    }
  },
}
