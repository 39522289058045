import React from 'react'
import { compose } from 'recompose'
import { reduxForm } from 'redux-form'
import cn from 'classnames'

import Button from 'uikit/components/Button'
import FormField from 'uikit/components/FormField'
import Input from 'uikit/components/Input'
import Link from 'uikit/components/Link'
import { formatText } from 'utils/validator'
import { login as loginActionCreator } from 'domains/user'
import {
  RESET_PASSWORD,
  REGISTER_USER_HASH,
  REGISTER_USER_ON_PAGE_HASH,
} from 'components/Auth/route-constants'
import { withRouter } from 'dva/router'
import { LANGUAGES } from 'utils/i18n'
import i18n, { t } from 'i18next'

import styles from './assets/Forms.module.scss'

const SignInForm = ({
  handleSubmit,
  submitting,
  error,
  submitFailed,
  location,
  className,
  stayOnPage,
}) => (
  <form className={cn(styles.Form, className)} onSubmit={handleSubmit}>
    <FormField
      component={Input}
      name="login"
      label={t('Auth:login')}
      fashion="slim"
      fullWidth
      format={formatText}
    />
    <br />
    <br />
    <FormField
      component={Input}
      name="password"
      label={t('Auth:password')}
      fashion="slim"
      type="password"
      fullWidth
      format={formatText}
    />
    <br />

    <div className={styles.ErrorMessage}>{error && submitFailed && <span>{error}</span>}</div>

    <Button
      type="submit" fullWidth
      onClick={handleSubmit} disabled={submitting}
    >
      {t('Auth:enter')}
    </Button>

    { i18n.language !== LANGUAGES.CHINESE
    && (
      <div className={styles.Links}>
        <Link to={RESET_PASSWORD} className={styles.Link}>
          {t('Auth:remind_password')}
        </Link>
        <Link
          to={`${location.search}${stayOnPage ? REGISTER_USER_ON_PAGE_HASH : REGISTER_USER_HASH}`}
          className={styles.Link}
        >
          {t('Auth:register')}
        </Link>
      </div>
    )
    }
  </form>
)

const validate = (values) => {
  const errors = {}
  const errorMessage = t('Auth:wrong_password_or_login')

  if (!values.login || !values.password) {
    errors._error = errorMessage
  }

  return errors
}

const onSubmit = (
  { login, password },
  dispatch,
  {
    form,
    location: {
      query: {
        code,
        next,
      },
    },
  }
) => {
  const formName = form
  const courseCode = code

  dispatch(loginActionCreator({
    formName, courseCode, login, password, next,
  }))
}

const enhance = compose(
  withRouter,

  reduxForm({
    form: 'signIn',
    validate,
    onSubmit,
  }),
)

export default enhance(SignInForm)
