export const MODEL = 'app'

export const INITIAL_STATE = {
  isLoading              : true,
  isI18NextInitialized   : false,
  isBackendAvailable     : true,
  backendUnavailableSince: null,
  scrollBarWidth         : null,
  cackleSSOString        : null,
}

export const BOOTSTRAP_APPLICATION = 'BOOTSTRAP_APPLICATION'
export const SET_APP_LOADING = 'SET_APP_LOADING'
export const LOAD_SITE_CONFIG = 'LOAD_SITE_CONFIG'
export const SITE_CONFIG_LOADED = 'SITE_CONFIG_LOADED'
export const SET_I18NEXT_INITIALIZED = 'SET_I18NEXT_INITIALIZED'
export const SET_BACKEND_UNAVAILABLE = 'SET_BACKEND_UNAVAILABLE'
export const SET_BACKEND_AVAILABLE = 'SET_BACKEND_AVAILABLE'
export const SET_SCROLLBAR_WIDTH = 'SET_SCROLLBAR_WIDTH'
export const FETCH_CACKLE_SSO_STRING = 'FETCH_CACKLE_SSO_STRING'
export const SET_CACKLE_SSO_STRING = 'SET_CACKLE_SSO_STRING'
