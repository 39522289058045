import { createAction } from 'utils/store'

import {
  MODEL,
  CHANGE_PASSWORD,
  CREATE_USER,
  FETCH_USER,
  LOGIN,
  LOGOUT,
  RESET_PASSWORD,
  RESET_PASSWORD_CONFIRM,
  SET_USER,
  UPDATE_USER,
  REMOVE_USER,
  REGISTER_CONFIRM,
  RESEND_CONFIRM,
  GET_AVAILABLE_LOGIN,
  ENTER_STUDENT_NAME,
  SEND_PURCHASE_REGISTER_CONFIRM,
  AUTHORIZE_WITH_GOOGLE,
  AUTHORIZE_WITH_FACEBOOK,
} from './constants'

export const login = payload => createAction(MODEL, LOGIN, payload)

export const logout = () => createAction(MODEL, LOGOUT)

export const enterStudentName = payload => createAction(MODEL, ENTER_STUDENT_NAME, payload)

export const changePassword = payload => createAction(MODEL, CHANGE_PASSWORD, payload)

export const resetPassword = payload => createAction(MODEL, RESET_PASSWORD, payload)

export const resetPasswordConfirm = payload => createAction(MODEL, RESET_PASSWORD_CONFIRM, payload)

export const createUser = payload => createAction(MODEL, CREATE_USER, payload)

export const fetchUser = () => createAction(MODEL, FETCH_USER)

export const updateUser = update => createAction(MODEL, UPDATE_USER, { update })

export const setUser = user => createAction(SET_USER, { user })

export const removeUser = () => createAction(MODEL, REMOVE_USER)

export const registerConfirm = (key, searchString) => createAction(MODEL, REGISTER_CONFIRM, { key, searchString })

export const resendConfirm = payload => createAction(MODEL, RESEND_CONFIRM, payload)

export const sendPurchaseRegisterConfirm = payload => createAction(MODEL, SEND_PURCHASE_REGISTER_CONFIRM, payload)

export const getFreeLogin = () => createAction(MODEL, GET_AVAILABLE_LOGIN)

export const authorizeWithGoogleCode = code => createAction(MODEL, AUTHORIZE_WITH_GOOGLE, { code })

export const authorizeWithFacebookCode = code => createAction(MODEL, AUTHORIZE_WITH_FACEBOOK, { code })
