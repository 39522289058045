import { compose } from 'recompose'

import snakeToCamel from 'utils/mappers/snakeToCamel'
import createMapper from 'utils/mappers/createMapper'

const readMapper = compose(
  createMapper({ templateName: 'theme' }),
  snakeToCamel,
)

export default readMapper
