import { createAction } from 'utils/store'
import { normalize } from 'normalizr'

import {
  MODEL,
  TEACHER_LESSON_MODEL,
  FETCH_COURSE_LESSON_REQUEST,
  FETCH_COURSE_LESSON_AND_JOIN_COURSE_REQUEST,
  ASSIGN_COURSE_LESSON_REQUEST,
  ALLOW_LOADING_VIDEO,
  PREVENT_LOADING_VIDEO,
  UPDATE_COURSE_LESSON_NAME,
  SAVE_COURSE_LESSON,
  getKeyForSaveCourseLesson,
  MOVE_LESSON_PROBLEM,
  REMOVE_LESSON_PROBLEM,
  CHANGE_LESSON_PROBLEM_OPTIONS,
  ADD_PROBLEMS_TO_COURSE_LESSON,
} from './constants'
import { teacherLessonSchema } from './schema'

export const fetchCourseLesson = (
  courseLessonId,
  teacher = false,
) => createAction(
  MODEL,
  FETCH_COURSE_LESSON_REQUEST,
  {
    entity: MODEL,
    key   : courseLessonId,
    courseLessonId,
    teacher,
  },
)

export const fetchCourseLessonAndJoinCourse = ({
  courseLessonId,
  courseId,
}) => createAction(
  MODEL,
  FETCH_COURSE_LESSON_AND_JOIN_COURSE_REQUEST,
  {
    entity: MODEL,
    key   : courseLessonId,
    courseLessonId,
    courseId,
  },
)

export const assignCourseLesson = courseLessonId => (
  createAction(
    MODEL,
    ASSIGN_COURSE_LESSON_REQUEST,
    {
      courseLessonId,
    }
  )
)

export const preventLoadVideo = () => createAction(
  MODEL,
  PREVENT_LOADING_VIDEO,
)


export const allowLoadVideo = () => createAction(
  MODEL,
  ALLOW_LOADING_VIDEO,
)

export const updateCourseLessonName = (courseLessonId, newName) => (
  createAction(
    MODEL,
    UPDATE_COURSE_LESSON_NAME,
    {
      courseLessonId,
      newName,
      entity: TEACHER_LESSON_MODEL,
      data  : normalize(
        {
          id    : courseLessonId,
          lesson: {
            name: newName,
          },
        },
        teacherLessonSchema,
      ),
    }
  )
)

export const addProblemsToCourseLesson = (courseLessonId, newProblems) => (
  createAction(
    MODEL,
    ADD_PROBLEMS_TO_COURSE_LESSON,
    {
      courseLessonId,
      newProblems,
      entity: TEACHER_LESSON_MODEL,
      key   : getKeyForSaveCourseLesson(courseLessonId),
    }
  )
)

export const removeLessonProblem = (courseLessonId, lessonProblemIndex) => (
  createAction(
    MODEL,
    REMOVE_LESSON_PROBLEM,
    {
      courseLessonId,
      lessonProblemIndex,
      entity: MODEL,
      key   : getKeyForSaveCourseLesson(courseLessonId),
    }
  )
)

export const moveLessonProblem = (courseLessonId, lessonProblemIndex, nextLessonProblemIndex) => (
  createAction(
    MODEL,
    MOVE_LESSON_PROBLEM,
    {
      courseLessonId,
      lessonProblemIndex,
      nextLessonProblemIndex,
      entity: MODEL,
      key   : getKeyForSaveCourseLesson(courseLessonId),
    }
  )
)

export const changeLessonProblemOptions = (courseLessonId, lessonProblemIndex, newOptions) => (
  createAction(
    MODEL,
    CHANGE_LESSON_PROBLEM_OPTIONS,
    {
      courseLessonId,
      lessonProblemIndex,
      newOptions,
      entity: MODEL,
      key   : getKeyForSaveCourseLesson(courseLessonId),
    }
  )
)

export const saveCourseLesson = courseLessonId => createAction(
  MODEL,
  SAVE_COURSE_LESSON,
  {
    courseLessonId,
    entity: MODEL,
    key   : getKeyForSaveCourseLesson(courseLessonId),
  },
)
