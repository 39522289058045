import dva from 'dva'
import createHistory from 'history/createBrowserHistory'
import qhistory from 'qhistory'
import thunk from 'redux-thunk'
import { parse, stringify } from 'qs'
import {
  partialRight,
  isArray,
  mergeWith,
} from 'lodash'

import model from './model'

import { onError } from './helpers'

const mergeOptions = partialRight(mergeWith, (objectA, objectB) => {
  if (isArray(objectA) && isArray(objectB)) {
    return [...objectA, ...objectB]
  }

  return undefined
})

const defaultOptions = {
  history : qhistory(createHistory(), stringify, parse),
  onAction: [thunk],
  onError,
}

const createApp = (options) => {
  const initOptions = mergeOptions(defaultOptions, options)
  const app = dva(initOptions)

  app.model(model)

  return app
}

export default createApp
