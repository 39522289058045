import { createAction } from 'utils/store'

import {
  MODEL,
  FETCH_INFO_PAGE_REQUEST,
  CONFIRM_GROUP_SUBSCRIPTION,
} from './constants'

export const fetchInfoPage = pageSlug => createAction(
  MODEL,
  FETCH_INFO_PAGE_REQUEST,
  {
    entity: MODEL,
    key   : pageSlug,
    pageSlug,
  }
)

export const confirmGroupSubscription = (email, groupId) => createAction(
  MODEL,
  CONFIRM_GROUP_SUBSCRIPTION,
  { email, groupId },
)
