import { createAction } from 'utils/store'

import {
  MODEL,
  FETCH_COURSE_LESSON_STATS_REQUEST,
} from './constants'

export const fetchCourseLessonStats = lessonId => createAction(
  MODEL,
  FETCH_COURSE_LESSON_STATS_REQUEST,
  {
    lessonId,
    entity: MODEL,
    key   : lessonId,
  },
)
