import { createAction } from 'utils/store'
import { getKeyForQuery } from 'utils/api'

import {
  MODEL,
  GET_ASSIGNMENT_LINK_REQUEST,
} from './constants'

export const assignmentLinkKeyForQuery = getKeyForQuery

export const getAssignmentLink = (assignmentPath, courseId) => createAction(
  MODEL,
  GET_ASSIGNMENT_LINK_REQUEST,
  {
    entity: MODEL,
    key   : assignmentLinkKeyForQuery({ assignmentPath, courseId }),
    assignmentPath,
    courseId,
  },
)
