export const MODEL = 'courseLessonResults'

export const INITIAL_STATE = {}

// Action types
export const FETCH_COURSE_LESSON_RESULT_REQUEST = 'FETCH_COURSE_LESSON_RESULT_REQUEST'
export const FETCH_COURSE_LESSON_RESULT_SUCCESS = 'FETCH_COURSE_LESSON_RESULT_SUCCESS'
export const FETCH_COURSE_LESSON_RESULT_FAIL = 'FETCH_COURSE_LESSON_RESULT_FAIL'

export const CREATE_COURSE_LESSON_RESULT_REQUEST = 'CREATE_COURSE_LESSON_RESULT_REQUEST'
export const CREATE_COURSE_LESSON_RESULT_SUCCESS = 'CREATE_COURSE_LESSON_RESULT_SUCCESS'
export const CREATE_COURSE_LESSON_RESULT_FAIL = 'CREATE_COURSE_LESSON_RESULT_FAIL'

export const UPDATE_COURSE_LESSON_RESULT_REQUEST = 'UPDATE_COURSE_LESSON_RESULT_REQUEST'
export const UPDATE_COURSE_LESSON_RESULT_SUCCESS = 'UPDATE_COURSE_LESSON_RESULT_SUCCESS'
export const UPDATE_COURSE_LESSON_RESULT_FAIL = 'UPDATE_COURSE_LESSON_RESULT_FAIL'

export const FETCH_OR_CREATE_ACTIVE_COURSE_LESSON_RESULT_REQUEST = 'FETCH_OR_CREATE_ACTIVE_COURSE_LESSON_RESULT_REQUEST'
export const FETCH_OR_CREATE_ACTIVE_COURSE_LESSON_RESULT_SUCCESS = 'FETCH_OR_CREATE_ACTIVE_COURSE_LESSON_RESULT_SUCCESS'
export const FETCH_OR_CREATE_ACTIVE_COURSE_LESSON_RESULT_FAIL = 'FETCH_OR_CREATE_ACTIVE_COURSE_LESSON_RESULT_FAIL'

export const CHECK_DEMO_COURSE_LESSON_RESULT_REQUEST = 'CHECK_DEMO_COURSE_LESSON_RESULT_REQUEST'
export const CHECK_DEMO_COURSE_LESSON_RESULT_SUCCESS = 'CHECK_DEMO_COURSE_LESSON_RESULT_SUCCESS'
export const CHECK_DEMO_COURSE_LESSON_RESULT_FAIL = 'CHECK_DEMO_COURSE_LESSON_RESULT_FAIL'

export const CLEAR_AND_CHECK_DEMO_COURSE_LESSON_RESULT_REQUEST = 'CLEAR_AND_CHECK_DEMO_COURSE_LESSON_RESULT_REQUEST'
export const CLEAR_AND_CHECK_DEMO_COURSE_LESSON_RESULT_SUCCESS = 'CLEAR_AND_CHECK_DEMO_COURSE_LESSON_RESULT_SUCCESS'
export const CLEAR_AND_CHECK_DEMO_COURSE_LESSON_RESULT_FAIL = 'CLEAR_AND_CHECK_DEMO_COURSE_LESSON_RESULT_FAIL'

export const FETCH_COURSE_LESSON_RESULT_FOR_TEACHER_REQUEST = 'FETCH_COURSE_LESSON_RESULT_FOR_TEACHER_REQUEST'
export const FETCH_COURSE_LESSON_RESULT_FOR_TEACHER_SUCCESS = 'FETCH_COURSE_LESSON_RESULT_FOR_TEACHER_SUCCESS'
export const FETCH_COURSE_LESSON_RESULT_FOR_TEACHER_FAIL = 'FETCH_COURSE_LESSON_RESULT_FOR_TEACHER_FAIL'

export const START_PROBLEM = 'START_PROBLEM'

export const ANONYMOUS_RESULT_ID = 'ANONYMOUS_RESULT'
