import {
  get,
  last,
  mapKeys,
  mapValues,
  size,
  filter,
} from 'lodash'

import { ANSWER_STATUSES } from 'legacy/constants'

export const getLastProblemAttempt = (courseLessonResult, lessonProblemId) => last(
  get(courseLessonResult, ['answers', lessonProblemId])
)

export const getLastAnswerAttemptFormValues = (courseLessonResult, problemId) => {
  const result = getLastProblemAttempt(courseLessonResult, problemId)

  if (!result) {
    return {}
  }

  return mapKeys(
    mapValues(
      result.markers,
      (markerResult, markerId) => ({ markerId, markerAnswer: { user_answer: markerResult.userAnswer } })
    ),
    (markerResult, markerId) => `marker_${markerId}`,
  )
}

export const getProblemAttemptsCount = (courseLessonResult, problemId) => size(filter(
  get(courseLessonResult, ['answers', problemId]),
  answerAttempt => get(answerAttempt, 'completed'),
))

export const getProblemRemainingPoints = (courseLessonResult, lessonProblemId) => get(
  courseLessonResult,
  ['remainingPoints', lessonProblemId],
)

export const isMarkerAnswerCorrect = (lessonProblemAttempt, markerId) => get(
  lessonProblemAttempt,
  ['markers', markerId, 'status']
) === ANSWER_STATUSES.CORRECT

export const isTheoryViewed = (courseLessonResult, problemId) => get(
  courseLessonResult,
  ['answers', problemId, 'viewed']
) === true
