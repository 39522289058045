import { get, assign } from 'lodash'
import { normalize } from 'normalizr'
import { routerRedux } from 'dva/router'
import HttpStatus from 'http-status-codes'
import { t } from 'i18next'

import { createAction } from 'utils/store'
import { notify as alemiraNotify } from 'uikit/alemiraComponents/Notification'

import {
  writeMapper,
} from './adapters'
import * as api from './api'
import {
  FETCH_COURSE_LESSON_REQUEST,
  FETCH_COURSE_LESSON_SUCCESS,
  FETCH_COURSE_LESSON_FAIL,
  FETCH_COURSE_LESSON_AND_JOIN_COURSE_REQUEST,
  FETCH_COURSE_LESSON_AND_JOIN_COURSE_SUCCESS,
  FETCH_COURSE_LESSON_AND_JOIN_COURSE_FAIL,
  ASSIGN_COURSE_LESSON_REQUEST,
  SAVE_COURSE_LESSON_REQUEST,
  SAVE_COURSE_LESSON_SUCCESS,
  SAVE_COURSE_LESSON_FAIL,
  UPDATE_COURSE_LESSON_DATE_ASSIGNMENT,
  MOVE_LESSON_PROBLEM,
  UPDATE_COURSE_LESSON_IN_STORE,
  TEACHER_LESSON_MODEL,
  REMOVE_LESSON_PROBLEM,
  CHANGE_LESSON_PROBLEM_OPTIONS,
  SAVE_COURSE_LESSON,
  ADD_PROBLEMS_TO_COURSE_LESSON,
} from './constants'
import {
  MODEL as COURSE_STATS_MODEL,
  SET_REFETCH_TEACHER_COURSE_STATS,
} from '../courseStats/constants'
import { courseLessonSchema, teacherLessonSchema } from './schema'
import {
  selectUpdatableCourseLessonData,
  selectTeacherCourseLesson,
} from './selectors'

function* fetchCourseLesson({
  courseLessonId,
  entity,
  key,
  teacher,
}, {
  call,
  put,
}) {
  try {
    const response = yield call(api.getCourseLesson, {
      courseLessonId,
      teacher,
    })
    const courseLesson = get(response, 'data')

    yield put(createAction(FETCH_COURSE_LESSON_SUCCESS, {
      entity,
      key,
      data: normalize(courseLesson, teacher ? teacherLessonSchema : courseLessonSchema),
    }))
  } catch (error) {
    yield put(createAction(FETCH_COURSE_LESSON_FAIL, {
      entity,
      key,
      error,
    }))
    if (!error.response || error.response.status >= HttpStatus.INTERNAL_SERVER_ERROR) {
      throw error
    }
    yield put(routerRedux.replace('/404/'))
  }
}

function* fetchCourseLessonAndJoinCourse({
  courseLessonId,
  courseId,
  entity,
  key,
}, {
  call,
  put,
}) {
  let joinCourse = false
  try {
    const response = yield call(api.getCourseLesson, {
      courseLessonId,
    })
    const courseLesson = get(response, 'data')

    yield put(createAction(FETCH_COURSE_LESSON_AND_JOIN_COURSE_SUCCESS, {
      entity,
      key,
      data: normalize(courseLesson, courseLessonSchema),
    }))
  } catch (error) {
    if (error.response && error.response.status === HttpStatus.FORBIDDEN) {
      joinCourse = true
    } else {
      yield put(createAction(FETCH_COURSE_LESSON_AND_JOIN_COURSE_FAIL, {
        entity,
        key,
        error,
      }))
      alemiraNotify()
    }
  }

  if (joinCourse) {
    try {
      yield call(api.postJoinAlemiraCourse, { courseId })
      const response = yield call(api.getCourseLesson, {
        courseLessonId,
      })
      const courseLesson = get(response, 'data')

      yield put(createAction(FETCH_COURSE_LESSON_AND_JOIN_COURSE_SUCCESS, {
        entity,
        key,
        data: normalize(courseLesson, courseLessonSchema),
      }))
    } catch (error) {
      yield put(createAction(FETCH_COURSE_LESSON_AND_JOIN_COURSE_FAIL, {
        entity,
        key,
        error,
      }))
      alemiraNotify()
    }
  }
}

function* assignCourseLesson({
  courseLessonId,
}, {
  call,
  put,
}) {
  try {
    const response = yield call(api.assignCourseLesson, {
      courseLessonId,
    })
    const courseLesson = get(response, 'data')

    yield put(createAction(
      COURSE_STATS_MODEL,
      SET_REFETCH_TEACHER_COURSE_STATS
    ))

    yield put(createAction(
      UPDATE_COURSE_LESSON_DATE_ASSIGNMENT,
      {
        data: normalize(courseLesson, teacherLessonSchema),
      }
    ))
  } catch (error) {
    throw Error(t('Course:error_message_try_reload'))
  }
}

function* saveCourseLessonRequest({
  courseLessonId,
  key,
  entity,
  data,
}, {
  call,
  put,
}) {
  yield put(createAction(SAVE_COURSE_LESSON_REQUEST, {
    entity,
    key,
  }))
  try {
    const response = yield call(api.updateCourseLesson, {
      courseLessonId,
      data,
    })
    const courseLesson = get(response, 'data')

    yield put(createAction(SAVE_COURSE_LESSON_SUCCESS, {
      entity,
      key,
      data: normalize(courseLesson, teacherLessonSchema),
    }))
  } catch (error) {
    yield put(createAction(SAVE_COURSE_LESSON_FAIL, {
      entity,
      key,
      error,
    }))
    if (error.response.status >= HttpStatus.INTERNAL_SERVER_ERROR) {
      throw error
    }
  }
}

function* saveCourseLesson({
  courseLessonId,
  key,
  entity,
}, {
  call,
  put,
  select,
}) {
  yield saveCourseLessonRequest(
    {
      courseLessonId,
      key,
      entity,
      data: writeMapper(yield select(selectUpdatableCourseLessonData, courseLessonId)),
    },
    {
      call,
      put,
    }
  )
}

function* moveLessonProblem({
  courseLessonId,
  lessonProblemIndex,
  nextLessonProblemIndex,
  entity,
  key,
}, {
  select,
  put,
  call,
}) {
  const courseLesson = yield select(selectTeacherCourseLesson, courseLessonId)
  const updatableCourseLesson = yield select(selectUpdatableCourseLessonData, courseLessonId)

  // обновляем локальные данные
  const problemToMove = get(courseLesson.lesson.lessonProblems, lessonProblemIndex)
  courseLesson.lesson.lessonProblems.splice(lessonProblemIndex, 1)
  courseLesson.lesson.lessonProblems.splice(nextLessonProblemIndex, 0, problemToMove)
  yield put(createAction(UPDATE_COURSE_LESSON_IN_STORE, {
    entity: TEACHER_LESSON_MODEL,
    data  : {
      lesson: {
        lessonProblems: courseLesson.lesson.lessonProblems,
      },
    },
  }))

  // сохраняем данные
  const updatableProblemToMove = get(updatableCourseLesson.lesson.lessonProblems, lessonProblemIndex)
  updatableCourseLesson.lesson.lessonProblems.splice(lessonProblemIndex, 1)
  updatableCourseLesson.lesson.lessonProblems.splice(nextLessonProblemIndex, 0, updatableProblemToMove)

  yield saveCourseLessonRequest({
    courseLessonId,
    key,
    entity,
    data: writeMapper(updatableCourseLesson),
  }, {
    call,
    put,
  })
}

function* removeLessonProblem({
  courseLessonId,
  lessonProblemIndex,
  entity,
  key,
}, {
  select,
  put,
  call,
}) {
  const courseLesson = yield select(selectTeacherCourseLesson, courseLessonId)
  const updatableCourseLesson = yield select(selectUpdatableCourseLessonData, courseLessonId)

  // обновляем локальные данные
  courseLesson.lesson.lessonProblems.splice(lessonProblemIndex, 1)
  yield put(createAction(UPDATE_COURSE_LESSON_IN_STORE, {
    entity: TEACHER_LESSON_MODEL,
    data  : {
      lesson: {
        lessonProblems: courseLesson.lesson.lessonProblems,
      },
    },
  }))

  // сохраняем данные
  updatableCourseLesson.lesson.lessonProblems.splice(lessonProblemIndex, 1)

  yield saveCourseLessonRequest({
    courseLessonId,
    key,
    entity,
    data: writeMapper(updatableCourseLesson),
  }, {
    call,
    put,
  })
}

export function* changeLessonProblemOptions({
  courseLessonId,
  lessonProblemIndex,
  entity,
  key,
  newOptions,
}, {
  select,
  put,
  call,
}) {
  const courseLesson = yield select(selectTeacherCourseLesson, courseLessonId)
  const updatableCourseLesson = yield select(selectUpdatableCourseLessonData, courseLessonId)

  // обновляем локальные данные
  assign(courseLesson.lesson.lessonProblems[lessonProblemIndex].options, newOptions)
  yield put(createAction(UPDATE_COURSE_LESSON_IN_STORE, {
    entity: TEACHER_LESSON_MODEL,
    data  : {
      lesson: {
        lessonProblems: courseLesson.lesson.lessonProblems,
      },
    },
  }))

  // сохраняем данные
  assign(updatableCourseLesson.lesson.lessonProblems[lessonProblemIndex].options, newOptions)
  yield saveCourseLessonRequest({
    courseLessonId,
    key,
    entity,
    data: writeMapper(updatableCourseLesson),
  }, {
    call,
    put,
  })
}

function* addProblemsToCourseLesson({
  courseLessonId,
  newProblems,
  entity,
  key,
}, {
  select,
  put,
  call,
}) {
  const updatableCourseLesson = yield select(selectUpdatableCourseLessonData, courseLessonId)
  updatableCourseLesson.lesson.lessonProblems = updatableCourseLesson.lesson.lessonProblems.concat(newProblems)

  yield saveCourseLessonRequest({
    courseLessonId,
    key,
    entity,
    data: writeMapper(updatableCourseLesson),
  }, {
    call,
    put,
  })
}

export default {
  [FETCH_COURSE_LESSON_REQUEST]                : fetchCourseLesson,
  [FETCH_COURSE_LESSON_AND_JOIN_COURSE_REQUEST]: fetchCourseLessonAndJoinCourse,
  [ASSIGN_COURSE_LESSON_REQUEST]               : assignCourseLesson,
  [SAVE_COURSE_LESSON]                         : saveCourseLesson,
  [MOVE_LESSON_PROBLEM]                        : moveLessonProblem,
  [REMOVE_LESSON_PROBLEM]                      : removeLessonProblem,
  [CHANGE_LESSON_PROBLEM_OPTIONS]              : changeLessonProblemOptions,
  [ADD_PROBLEMS_TO_COURSE_LESSON]              : addProblemsToCourseLesson,
}
