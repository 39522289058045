import React from 'react'
import {
  getFormMeta,
  formValueSelector,
  reduxForm,
} from 'redux-form'
import { compose } from 'recompose'
import { connect } from 'dva'
import { withRouter } from 'dva/router'
import cn from 'classnames'
import {
  get,
  isEmpty,
  noop,
} from 'lodash'
import { t } from 'i18next'
import { Trans } from 'react-i18next'
import Link from 'uikit/components/Link'

import {
  formatText,
  isEmail,
} from 'utils/validator'
import { login as loginActionCreator, createUser } from 'domains/user'
import { NEXT_PAGE_AFTER_SOCIAL_AUTH_KEY } from 'domains/user/constants'
import FormField from 'uikit/components/FormField'
import Input from 'uikit/alemiraComponents/Input'
import SelectionControlGroup from 'uikit/alemiraComponents/SelectionControlGroup'

import styles from './styles.module.scss'

const FORM_NAME = 'sitRegisterForm'
const LINKS = {
  TERMS_OF_SERVICE: '/pages/rules/',
  PRIVACY_POLICY  : '/pages/policy/',
}

const SignInForm = (props) => {
  const {
    handleSubmit,
    submitting,
    isCreateAccountSelected,
    error,
    formMeta,
    emailValue,
    passwordValue,
    acceptTermsValue,
    googleSignIn,
    facebookSignIn,
    firstNameValue,
    lastNameValue,
  } = props

  let errorMessage
  if (error) {
    if (error.email && get(formMeta, ['email', 'touched'])) {
      errorMessage = error.email
    } else if (error.password && get(formMeta, ['password', 'touched'])) {
      errorMessage = error.password
    } else if (!error.email) {
      errorMessage = error
    }
  }

  return (
    <div className={styles.SignInWrapper}>
      <form className={styles.Form} onSubmit={handleSubmit}>
        <FormField
          component={SelectionControlGroup}
          controlType="radio"
          simpleValue
          valueKey="value"
          name="createAccount"
          options={[
            {
              label: t('Alemira:create_account'),
              value: true,
            },
            {
              label: t('Alemira:log_in'),
              value: false,
            },
          ]}
        />
        <FormField
          className={styles.EmailFormField}
          component={Input}
          name="email"
          type="email"
          label={t('Alemira:email')}
          fashion="slim"
          fullWidth
          format={formatText}
        />
        <FormField
          className={styles.FormField}
          component={Input}
          name="password"
          label={t(isCreateAccountSelected ? 'Alemira:make_a_password' : 'Alemira:password')}
          fashion="slim"
          type="password"
          fullWidth
          format={formatText}
        />
        {isCreateAccountSelected
      && (
        <>
          <FormField
            className={styles.FormField}
            component={Input}
            name="firstName"
            label={t('Alemira:name')}
            fashion="slim"
            fullWidth
            format={formatText}
          />
          <FormField
            className={styles.FormField}
            component={Input}
            name="lastName"
            label={t('Alemira:surname')}
            fashion="slim"
            fullWidth
            format={formatText}
          />
          <div className={styles.AgreementCheckboxWrapper}>
            <FormField
              component={SelectionControlGroup}
              name="acceptTerms"
              controlType="checkbox"
              valueKey="value"
              simpleValue
              options={[
                {
                  value: true,
                },
              ]}
            />
            <p>
              <Trans i18nKey="Alemira:accept_personal">
                <Link
                  className={styles.Link}
                  theme="custom"
                  to={LINKS.TERMS_OF_SERVICE}
                  isRouterLink={false}
                  target="_blank"
                />
                <Link
                  className={styles.Link}
                  theme="custom"
                  to={LINKS.PRIVACY_POLICY}
                  isRouterLink={false}
                  target="_blank"
                />
              </Trans>
            </p>
          </div>
        </>
      )}
        <div className={styles.SubmitWrapper}>
          { errorMessage
            ? (
              <div
                className={cn(styles.Message, { [styles.Warning]: true })}
              >
                {errorMessage}
              </div>
            )
            : (emailValue && passwordValue
              && (!isCreateAccountSelected
                  || (isCreateAccountSelected && !isEmpty(acceptTermsValue) && firstNameValue && lastNameValue)))
              ? (
                <button
                  type="submit"
                  className={styles.SubmitButton}
                  onClick={handleSubmit}
                  disabled={submitting}
                >
                  <span>{isCreateAccountSelected ? t('Alemira:create_account') : t('Alemira:log_in')}</span>
                </button>
              )
              : (
                <div
                  className={styles.Message}
                >
                  {isCreateAccountSelected && emailValue && passwordValue && firstNameValue && lastNameValue
                    && isEmpty(acceptTermsValue)
                    ? t('Alemira:error_accept_to_continue')
                    : t('Alemira:error_fill_fields_to_continue')
                  }
                </div>
              )

          }
        </div>
      </form>
      <p className={styles.OrText}>
        {t('Alemira:or')}
      </p>
      <div
        className={styles.GoogleSignIn}
        onClick={() => googleSignIn()}
      >
        <div className={styles.GoogleLogo} />
        {t('Alemira:log_in_via_google')}
      </div>
      <div
        className={styles.FacebookSignIn}
        onClick={() => facebookSignIn()}
      >
        <div className={styles.FacebookLogo} />
        {t('Alemira:log_in_via_facebook')}
      </div>
    </div>
  )
}

const selectFormValues = formValueSelector(FORM_NAME)
const selectFormMeta = getFormMeta(FORM_NAME)

const validate = ({ email }) => {
  if (!(email && isEmail(email))) {
    return { _error: { email: t('Alemira:error_email') } }
  }
  return null
}

const onSubmit = ({
  email,
  password,
  acceptTerms,
  firstName,
  lastName,
}, dispatch, {
  form, isCreateAccountSelected, location: { query: { next } },
}) => {
  if (isCreateAccountSelected) {
    dispatch(
      createUser({
        formName    : form,
        personalInfo: {
          email,
          password,
          accept_terms: acceptTerms[0],
          first_name  : firstName,
          last_name   : lastName,
        },
        onSuccess    : noop,
        errorMessages: {
          'Пользователь с такой эл. почтой уже существует.': {
            field: '_error',
            text : t('Alemira:error_account_with_email_exists'),
          },
        },
        next,
        isSitLogin: true,
      })
    )
  } else {
    dispatch(loginActionCreator({
      formName  : form,
      login     : email,
      isSitLogin: true,
      password,
      next,
    }))
  }
}

const enhance = compose(
  withRouter,

  connect(state => ({
    initialValues: {
      createAccount: false,
    },
    isCreateAccountSelected: selectFormValues(state, 'createAccount'),
    emailValue             : selectFormValues(state, 'email'),
    passwordValue          : selectFormValues(state, 'password'),
    firstNameValue         : selectFormValues(state, 'firstName'),
    lastNameValue          : selectFormValues(state, 'lastName'),
    acceptTermsValue       : selectFormValues(state, 'acceptTerms'),
    formMeta               : selectFormMeta(state),
  }),
  (dispatch, { location: { query, pathname } }) => ({
    googleSignIn: () => {
      window.localStorage.setItem(NEXT_PAGE_AFTER_SOCIAL_AUTH_KEY, query.next || pathname)
      window.location = '/accounts/google/login/'
    },
    facebookSignIn: () => {
      window.localStorage.setItem(NEXT_PAGE_AFTER_SOCIAL_AUTH_KEY, query.next || pathname)
      window.location = '/accounts/facebook/login/'
    },
  })),

  reduxForm({
    form: FORM_NAME,
    validate,
    onSubmit,
  }),
)

export default enhance(SignInForm)
