import { createAction } from 'utils/store'

import {
  MODEL,
  COURSE_WITH_GROUP_MODEL,
  FETCH_COURSE_REQUEST,
  FETCH_TEACHER_COURSE_REQUEST,
  FETCH_COURSE_WITH_GROUP_REQUEST,
  JOIN_COURSE,
  SEND_PROMO_CODE_REQUEST,
  SET_COURSE_LESSON_HINT_ID,
} from './constants'

export const fetchCourse = courseId => createAction(
  MODEL,
  FETCH_COURSE_REQUEST,
  {
    courseId,
    entity: MODEL,
    key   : courseId,
  },
)

export const fetchTeacherCourse = courseId => createAction(
  MODEL,
  FETCH_TEACHER_COURSE_REQUEST,
  {
    courseId,
    entity: 'teacherCourse',
    key   : courseId,
  },
)

export const fetchCourseWithGroup = courseId => createAction(
  MODEL,
  FETCH_COURSE_WITH_GROUP_REQUEST,
  {
    courseId,
    entity: COURSE_WITH_GROUP_MODEL,
    key   : courseId,
  }
)

export const joinCourse = ({ formName, courseCode }) => createAction(MODEL, JOIN_COURSE, { formName, courseCode })

export const sendPromoCode = ({
  formName, course, code,
}) => createAction(
  MODEL,
  SEND_PROMO_CODE_REQUEST,
  {
    formName,
    course,
    code,
    entity: 'purchaseOffers',
    key   : course,
  },
)

export const setCourseLessonHintId = value => createAction(MODEL, SET_COURSE_LESSON_HINT_ID, { value })
