import PropTypes from 'prop-types'
import React from 'react'

import Modal from 'uikit/components/Modal'

import Form from './ResetPasswordForm'
import styles from './assets/Modals.module.scss'

const ResetPasswordModal = ({ isOpen = true, onClose }) => (
  <Modal
    isOpen={isOpen}
    className={styles.Modal}
    contentClassName={styles.ModalContent}
    onClose={onClose}
  >
    <Form />
  </Modal>
)

ResetPasswordModal.propTypes = {
  isOpen : PropTypes.bool,
  onClose: PropTypes.func,
}

export default ResetPasswordModal
