import { isPlainObject } from 'lodash'
import cn from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import RModal from 'react-modal'

import styles from './styles.module.scss'
import { ReactComponent as CloseIcon } from './close.svg'

RModal.setAppElement('#root')

const Modal = ({
  children,
  className,
  overlayClassName,
  headerClassName,
  contentClassName,
  closeButtonClassName,
  isOpen,
  onClose,
  hideCloseButton,
  closeTimeoutMS,
  preHeader,
  innerWrapperClassname,
  bodyOpenClassName,
  htmlOpenClassName,
  contentRef,
}) => (
  <RModal
    isOpen={isOpen}
    onRequestClose={onClose}
    className={
      isPlainObject(className)
        ? {
          base       : cn(styles.Modal, className.base),
          afterOpen  : className.afterOpen,
          beforeClose: className.beforeClose,
        }
        : cn(styles.Modal, className)
    }
    overlayClassName={
      isPlainObject(overlayClassName)
        ? {
          base       : cn(styles.Overlay, overlayClassName.base),
          afterOpen  : overlayClassName.afterOpen,
          beforeClose: overlayClassName.beforeClose,
        }
        : cn(styles.Overlay, overlayClassName)
    }
    closeTimeoutMS={closeTimeoutMS}
    {...(bodyOpenClassName ? { bodyOpenClassName } : {})}
    {...(htmlOpenClassName ? { htmlOpenClassName } : {})}
    {...(contentRef ? { contentRef } : {})}
  >
    {preHeader}

    <div className={cn(styles.InnerWrapper, innerWrapperClassname)}>
      <div className={cn(styles.Header, headerClassName)}>
        {!hideCloseButton && (
          <button
            type="button"
            className={cn(styles.CloseButton, closeButtonClassName)}
            onClick={onClose}
          >
            <CloseIcon />
          </button>
        )}
      </div>
      <div
        className={cn(styles.ContentWrapper, contentClassName)}
      >
        {children}
      </div>
    </div>
  </RModal>
)

Modal.propTypes = {
  children             : PropTypes.node.isRequired,
  preHeader            : PropTypes.node,
  isOpen               : PropTypes.bool.isRequired,
  className            : PropTypes.oneOfType([PropTypes.string, PropTypes.objectOf(PropTypes.string)]),
  overlayClassName     : PropTypes.oneOfType([PropTypes.string, PropTypes.objectOf(PropTypes.string)]),
  headerClassName      : PropTypes.string,
  innerWrapperClassname: PropTypes.string,
  bodyOpenClassName    : PropTypes.string,
  htmlOpenClassName    : PropTypes.string,
  contentClassName     : PropTypes.string,
  hideCloseButton      : PropTypes.bool,
  closeTimeoutMS       : PropTypes.number,
}

export default Modal
