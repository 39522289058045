import keyMirror from 'utils/keyMirror'

export const ALL = 'all'

export const API_STATE_NAME = 'api'

export const ENTITIES_STATE_NAME = 'entities'

export const API_ACTION_SUFFIXES = keyMirror(['REQUEST', 'SUCCESS', 'FAIL'])

export const CLEAR_ENTITIES_ACTION = 'CLEAR_ENTITIES_ACTION'

export const CLEAR_ENTITY_ACTION = 'CLEAR_ENTITY_ACTION'

export const CLEAR_API_ACTION = 'CLEAR_API_ACTION'
