import { getEnvVariable } from 'utils/env'

export const API_BASE = getEnvVariable('REACT_APP_API_BASE', '/api')

export const isProduction = getEnvVariable('NODE_ENV') === 'production'

export const SITE_CODES = {
  PELIKAN   : 'pelican',
  HELPEGE   : 'helpege',
  ROBOBORIK : 'roboborik',
  EN_PELIKAN: 'en-pelican',
  CH_PELIKAN: 'ch-pelican',
}
