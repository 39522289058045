export const MODEL = 'courseLineStat'
export const CLASS_MODEL = `${MODEL}/class`
export const COURSE_LINES_MODEL = `${MODEL}/courseLines`
export const COURSE_LINE_DATA_MODEL = `${MODEL}/courseLineData`
export const STUDENTS_MODEL = `${MODEL}/students`
export const TOPICS_MODEL = `${MODEL}/topics`
export const LESSONS_MODEL = `${MODEL}/lessons`
export const NUMBERS_MODEL = `${MODEL}/numbers`
export const BY_LESSON_MODEL = `${MODEL}/byLesson`
export const BY_NUMBER_MODEL = `${MODEL}/byNumber`
export const BY_TOPIC_MODEL = `${MODEL}/byTopic`
export const LESSON_MARKS_MODEL = `${MODEL}/lessonMarks`
export const TOPIC_MARKS_MODEL = `${MODEL}/topicMarks`
export const NUMBER_MARKS_MODEL = `${MODEL}/numberMarks`

// Action types
export const FETCH_COURSE_LINE_STAT_REQUEST = 'FETCH_COURSE_LINE_STAT_REQUEST'
export const FETCH_COURSE_LINE_STAT_REQUEST_SUCCESS = 'FETCH_COURSE_LINE_STAT_REQUEST_SUCCESS'
export const FETCH_COURSE_LINE_STAT_REQUEST_FAIL = 'FETCH_COURSE_LINE_STAT_REQUEST_FAIL'
