import { createAction } from 'utils/store'
import { getKeyForQuery } from 'utils/api'

import {
  MODEL,
  FETCH_COURSE_LESSON_RESULT_REQUEST,
  CREATE_COURSE_LESSON_RESULT_REQUEST,
  UPDATE_COURSE_LESSON_RESULT_REQUEST,
  FETCH_OR_CREATE_ACTIVE_COURSE_LESSON_RESULT_REQUEST,
  CHECK_DEMO_COURSE_LESSON_RESULT_REQUEST,
  START_PROBLEM,
  CLEAR_AND_CHECK_DEMO_COURSE_LESSON_RESULT_REQUEST,
  FETCH_COURSE_LESSON_RESULT_FOR_TEACHER_REQUEST,
} from './constants'

export const resultKeyForQuery = ({ courseLessonId, studentId }) => `result#${courseLessonId}#${studentId}`

export const fetchOrCreateCourseLessonResult = ({
  courseLessonId, studentId, isAuthorized, demoResult, isAlemira,
}) => createAction(
  MODEL,
  FETCH_OR_CREATE_ACTIVE_COURSE_LESSON_RESULT_REQUEST,
  {
    entity: MODEL,
    key   : resultKeyForQuery({ courseLessonId, studentId }),
    courseLessonId,
    studentId,
    isAuthorized,
    demoResult,
    isAlemira,
  },
)

export const fetchCourseLessonResult = courseLessonId => createAction(
  MODEL,
  FETCH_COURSE_LESSON_RESULT_REQUEST,
  {
    entity: MODEL,
    key   : courseLessonId,
    courseLessonId,
  },
)

export const fetchCourseLessonResultForTeacher = ({
  courseLessonId, studentId,
}) => createAction(
  MODEL,
  FETCH_COURSE_LESSON_RESULT_FOR_TEACHER_REQUEST,
  {
    entity: MODEL,
    key   : resultKeyForQuery({ courseLessonId, studentId }),
    courseLessonId,
    studentId,
  },
)

export const createCourseLessonAttempt = courseLessonId => createAction(
  MODEL,
  CREATE_COURSE_LESSON_RESULT_REQUEST,
  {
    entity: MODEL,
    key   : courseLessonId,
    courseLessonId,
  },
)

export const updateCourseLessonResult = (courseLessonResultId, data, isAlemira) => createAction(
  MODEL,
  UPDATE_COURSE_LESSON_RESULT_REQUEST,
  {
    entity: MODEL,
    key   : getKeyForQuery({
      courseLessonResultId, data,
    }),
    data,
    courseLessonResultId,
    isAlemira,
  },
)

export const startProblem = () => createAction(
  MODEL,
  START_PROBLEM,
)

export const demoResultKeyForQuery = ({ courseLessonId }) => `demoResult#${courseLessonId}`

export const checkDemoCourseLessonResult = ({
  courseLessonId,
  data,
}) => createAction(
  MODEL,
  CHECK_DEMO_COURSE_LESSON_RESULT_REQUEST,
  {
    entity: MODEL,
    key   : courseLessonId,
    data,
    courseLessonId,
  }
)

export const clearAndCheckDemoCourseLessonResult = ({
  courseLessonId, isAuthorized,
}) => createAction(
  MODEL,
  CLEAR_AND_CHECK_DEMO_COURSE_LESSON_RESULT_REQUEST,
  {
    entity: MODEL,
    key   : demoResultKeyForQuery({ courseLessonId }),
    courseLessonId,
    isAuthorized,
  }
)
