import axios from 'utils/axios'

import urls from './urls'

export const getAssignedCourses = () => axios.get(urls.assignedCourses)
export const exitFromCourse = courseId => axios.post(urls.exitFromCourse, { course: courseId })
export const createSelectedCourse = courseId => axios.post(
  urls.createSelectedCourse,
  { course: courseId },
)
