import PropTypes from 'prop-types'
import React from 'react'

import Link from 'uikit/components/Link'
import Modal from 'uikit/components/Modal'
import { Trans } from 'react-i18next'

import RegisterTeacherForm from './RegisterTeacherForm'
import styles from './assets/Modals.module.scss'
import { SIGN_IN_HASH } from './route-constants'

const RegisterTeacherModal = ({ isOpen, onClose }) => (
  <Modal
    isOpen={isOpen}
    className={styles.Modal}
    contentClassName={styles.ModalContent}
    onClose={onClose}
    preHeader={(
      <div className={styles.PreHeader}>
        <Trans i18nKey="Auth:enter_if_with_us">
          <Link className={styles.PreHeaderLink} to={SIGN_IN_HASH} />
        </Trans>
      </div>
    )}
  >
    <RegisterTeacherForm />
  </Modal>
)

RegisterTeacherModal.propTypes = {
  isOpen : PropTypes.bool,
  onClose: PropTypes.func,
}

export default RegisterTeacherModal
