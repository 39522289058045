import React from 'react'
import PropTypes from 'prop-types'
import { t } from 'i18next'
import { noop } from 'lodash'

import Modal from 'uikit/components/Modal'
import { ReactComponent as CloseIcon } from 'uikit/components/Modal/close.svg'
import modalStyles from 'uikit/components/Modal/styles.module.scss'

import Header1 from 'uikit/components/Typography/Header1'
import Link from 'uikit/components/Link'

import cn from 'classnames'
import EnterNameForm from './EnterNameForm'
import styles from './styles.module.scss'

/*
* пришлось вынести кнопку закрытия из окна, чтобы расположить кнопку вне круга.
* position: fixed внутри элемента с transform не работает, а без transform не получилось
* круг расположить по центру и с правильным увеличением от размеров окна
* */

const EnterNameModal = ({ isOpen, onClose }) => (
  <>
    <button
      type="button"
      className={cn(modalStyles.CloseButton, styles.CloseButton)}
      onClick={onClose}
    >
      <CloseIcon />
    </button>
    <Modal
      isOpen={isOpen}
      className={styles.Modal}
      contentClassName={styles.CircularModalContent}
      onClose={onClose}
      innerWrapperClassname={styles.EnterNameModalInnerWrapper}
      overlayClassName={styles.EnterNameModalOverlay}
      bodyOpenClassName={styles.ReactModalBodyOpen}
      htmlOpenClassName={styles.ReactModalHtmlOpen}
      hideCloseButton
    >
      <div className={styles.Header}>
        <Header1>{t('Auth:enter_name_form_header')}</Header1>
      </div>

      <div className={styles.Content}>
        <EnterNameForm />
      </div>
      <div className={styles.Footer}>
        <Link
          to={{ hash: '' }}
          className={styles.DismissLink}
        >
          {t('Auth:enter_name_modal_dismiss')}
        </Link>
      </div>
    </Modal>
  </>
)


EnterNameModal.propTypes = {
  isOpen : PropTypes.bool,
  onClose: PropTypes.func,
}

EnterNameModal.defaultProps = {
  onClose: noop,
}

export default EnterNameModal
