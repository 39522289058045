import { has, get } from 'lodash'

import { isProduction } from 'environment'
import {
  selectSiteCode,
} from 'domains/app'
import {
  selectUser,
} from 'domains/user'

import {
  REACH_GOAL,
  USER_PARAMS,
  SITE_CODE_TO_YM_COUNTER,
  GOAL_NAME_TO_SITE_SPECIFIC_GOAL_NAME,
} from './constants'


function* reachGoal({ goalName }, { select }) {
  if (!isProduction) {
    return
  }

  if (!has(window, 'ym')) {
    return
  }

  const siteCode = yield select(selectSiteCode)

  const ymCounter = get(SITE_CODE_TO_YM_COUNTER, siteCode)

  if (!ymCounter) {
    return
  }

  const siteSpecificGoalName = get(GOAL_NAME_TO_SITE_SPECIFIC_GOAL_NAME, [goalName, siteCode])

  if (!siteSpecificGoalName) {
    return
  }

  try {
    window.ym(ymCounter, 'reachGoal', siteSpecificGoalName)
  } catch (err) {
    // do nothing
  }
}

function* userParams(_, { select }) {
  if (!isProduction) {
    return
  }

  if (!has(window, 'ym')) {
    return
  }

  const siteCode = yield select(selectSiteCode)

  const ymCounter = get(SITE_CODE_TO_YM_COUNTER, siteCode)

  if (!ymCounter) {
    return
  }

  const user = yield select(selectUser)
  if (!user) {
    return
  }

  try {
    window.ym(ymCounter, 'setUserID', `${user.id}`)
    window.ym(ymCounter, 'userParams', {
      UserID            : user.id,
      isTeacher         : user.isTeacher ? 1 : 0,
      isContentManager  : user.isContentManager ? 1 : 0,
      isUnderage        : user.isUnderage ? 1 : 0,
      registeredByParent: user.registeredByParent ? 1 : 0,
    })
  } catch (err) {
    // do nothing
  }
}

export default {
  [REACH_GOAL] : reachGoal,
  [USER_PARAMS]: userParams,
}
