import { put as globalPut } from 'redux-saga/effects'
import { get } from 'lodash'

import { createAction } from 'utils/store'
import { hasAuthorizationToken } from 'utils/axios'
import { fetchUser } from 'domains/user'

import { getCackleSSOString } from './api'

import {
  BOOTSTRAP_APPLICATION,
  LOAD_SITE_CONFIG,
  SITE_CONFIG_LOADED,
  SET_APP_LOADING,
  FETCH_CACKLE_SSO_STRING,
} from './constants'
import {
  setScrollbarWidth,
  setCackleSSOString,
} from './actions'
import { detectScrollbarWidth } from './helpers'

function* bootstrapApplication(_, { put }) {
  yield put(setScrollbarWidth(detectScrollbarWidth()))

  try {
    if (hasAuthorizationToken()) {
      yield globalPut.resolve(fetchUser())
    }

    yield put(createAction(SET_APP_LOADING, { isLoading: false }))
  } catch (error) {
    // skip
  }
}

function* loadSiteConfig(_, { put }) {
  const siteConfig = window.APPEARANCE
  yield put(createAction(SITE_CONFIG_LOADED, {
    code      : siteConfig.code,
    secondLogo: get(siteConfig, 'header.secondLogo'),
    promoLink : get(siteConfig, ['header', 'promoLink']),
  }))
}

function* fetchCackleSSOString(_, { call, put }) {
  try {
    const response = yield call(getCackleSSOString)
    const ssoString = get(response, 'data')
    yield put(setCackleSSOString(ssoString))
  } catch (err) {
    // skip
  }
}

export default {
  [BOOTSTRAP_APPLICATION]  : bootstrapApplication,
  [LOAD_SITE_CONFIG]       : loadSiteConfig,
  [FETCH_CACKLE_SSO_STRING]: fetchCackleSSOString,
}
