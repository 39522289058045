import { get } from 'lodash'

import { COURSE_LESSON_MODES } from './adapters'

export const isWebinar = courseLesson => courseLesson.mode === COURSE_LESSON_MODES.WEBINAR
export const isDialog = courseLesson => courseLesson.mode === COURSE_LESSON_MODES.DIALOG

export const isNumberOfLastProblem = (courseLesson, problemNumber) => {
  const problems = get(courseLesson, ['lesson', 'problems'])
  return problems.length === problemNumber
}

export const getCourseId = courseLesson => get(courseLesson, ['course', 'id'])

export const isDemoCourseLesson = courseLesson => get(courseLesson, 'isDemo')
