import axios from 'utils/axios'

import urls from './urls'

export const getCourseLesson = params => axios.get(
  urls.courseLesson(params),
)

export const assignCourseLesson = courseLessonId => axios.post(
  urls.assignCourseLesson(courseLessonId),
)

export const updateCourseLesson = ({ courseLessonId, data }) => axios.patch(
  urls.saveCourseLesson(courseLessonId),
  data,
)

export const postJoinAlemiraCourse = ({ courseId }) => axios.post(
  urls.joinAlemiraCourse(courseId),
)
