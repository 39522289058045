/**
 * Monkey-patch метода CanvasRenderingContext2D.prototype.drawImage.
 * В safari есть баг при вызове drawImage с координатами вне исходного изображения.
 */
function needPolyfill() {
  if (!CanvasRenderingContext2D.prototype.drawImage) return false

  // Создаём canvas, рисуем на нём квадрат 40x40, рисуем на нём часть этого же canvas,
  // но указываем область источника частично вне canvas'а.
  const canvasContext = document.createElement('canvas').getContext('2d')
  canvasContext.fillRect(0, 0, 40, 40)
  canvasContext.drawImage(canvasContext.canvas, -40, -40, 80, 80, 50, 50, 20, 20)

  // Берём область где как ожидается должен был отрисоваться маленький квадрат + 10px вокруг квадрата
  // (сам квадрат находится в (60,60) и имеет размер 10x10)
  const img = canvasContext.getImageData(50, 50, 30, 30)
  const data = new Uint32Array(img.data.buffer)
  const colorAt = (x, y) => data[y * img.width + x]

  // Проверяем соответвует ли отрисованная область ожиданиям
  const transparents = [[9, 9], [20, 9], [9, 20], [20, 20]]
  const blacks = [[10, 10], [19, 10], [10, 19], [19, 19]]
  return transparents.some(([x, y]) => colorAt(x, y) !== 0x00000000)
      || blacks.some(([x, y]) => colorAt(x, y) === 0x00000000)
}

// eslint-disable-next-line consistent-return
function getSourceDimensions(source) {
  const sourceIs = (type) => {
    const constructor = globalThis[type] // eslint-disable-line no-undef
    return constructor && (source instanceof constructor)
  }
  if (sourceIs('HTMLImageElement')) {
    return { width: source.naturalWidth, height: source.naturalHeight }
  }
  if (sourceIs('HTMLVideoElement')) {
    return { width: source.videoWidth, height: source.videoHeight }
  }
  if (sourceIs('SVGImageElement')) {
    throw new TypeError("SVGImageElement isn't yet supported as source image.", 'UnsupportedError')
  } else if (sourceIs('HTMLCanvasElement') || sourceIs('ImageBitmap')) {
    return source
  }
}

/**
 * Преобразует параметры для эмуляции работы как в браузерах без этой проблемы.
 */
function getSafeRect(image, sx, sy, sw, sh, dx, dy, dw, dh) {
  const { width, height } = getSourceDimensions(image)

  if (sw < 0) {
    sx += sw
    sw = Math.abs(sw)
  }
  if (sh < 0) {
    sy += sh
    sh = Math.abs(sh)
  }
  if (dw < 0) {
    dx += dw
    dw = Math.abs(dw)
  }
  if (dh < 0) {
    dy += dh
    dh = Math.abs(dh)
  }
  const x1 = Math.max(sx, 0)
  const x2 = Math.min(sx + sw, width)
  const y1 = Math.max(sy, 0)
  const y2 = Math.min(sy + sh, height)
  const wRatio = dw / sw
  const hRatio = dh / sh

  return [
    image,
    x1,
    y1,
    x2 - x1,
    y2 - y1,
    sx < 0 ? dx - (sx * wRatio) : dx,
    sy < 0 ? dy - (sy * hRatio) : dy,
    (x2 - x1) * wRatio,
    (y2 - y1) * hRatio,
  ]
}

function isEmptyRect(args) {
  // sw, sh, dw, dh
  return [3, 4, 7, 8].some(index => !args[index])
}

if (needPolyfill()) {
  const original = CanvasRenderingContext2D.prototype.drawImage

  // eslint-disable-next-line consistent-return
  CanvasRenderingContext2D.prototype.drawImage = function drawImage(...args) {
    // Баг проявляется только при вызове метода drawImage с 9 параметрами
    const withCrop = args.length === 9
    if (!withCrop) {
      return original.apply(this, args)
    }

    const safeRect = getSafeRect(...args)
    if (!isEmptyRect(safeRect)) {
      return original.apply(this, safeRect)
    }
  }
}
