import { schema } from 'normalizr'

import { readMapper } from './adapters'
import { MODEL } from './constants'

export const courseStatsSchema = new schema.Entity(
  MODEL,
  {},
  {
    processStrategy: readMapper,
  },
)

export const teacherCourseStatsSchema = new schema.Entity(
  'teacherCourseStats',
  {},
  {
    processStrategy: readMapper,
  },
)
