import React from 'react'
import { compose } from 'recompose'
import { reduxForm } from 'redux-form'
import { isEmpty } from 'lodash'
import { t } from 'i18next'

import Input from 'uikit/components/Input'
import Button from 'uikit/components/Button'
import FormField from 'uikit/components/FormField'
import { formatText } from 'utils/validator'
import { enterStudentName } from 'domains/user'

import styles from './styles.module.scss'

const FORM_NAME = 'enterName'

const EnterNameForm = ({
  handleSubmit,
  submitting,
}) => (
  <form
    onSubmit={handleSubmit}
    className={styles.Form}
  >
    <div className={styles.FieldSet}>
      <FormField
        component={Input}
        className={styles.Field}
        name="firstName"
        label={t('Auth:name')}
        format={formatText}
      />
      <FormField
        component={Input}
        className={styles.Field}
        name="lastName"
        label={t('Auth:surname')}
        format={formatText}
      />
    </div>
    <Button
      type="submit"
      fullWidth
      onClick={handleSubmit}
      disabled={submitting}
    >
      {t('Auth:enter_name_submit')}
    </Button>
  </form>
)

const validate = ({ firstName, lastName }) => {
  const errors = {}

  if (isEmpty(firstName)) {
    errors.firstName = t('Auth:enter_name')
  }

  if (isEmpty(lastName)) {
    errors.lastName = t('Auth:enter_surname')
  }

  return errors
}

const onSubmit = (values, dispatch) => {
  dispatch(enterStudentName(values))
}

const enhance = compose(
  reduxForm({
    form: FORM_NAME,
    onSubmit,
    validate,
  })
)

export default enhance(EnterNameForm)
