import { createAction } from 'utils/store'

import {
  MODEL,
  FETCH_ALEMIRA_COURSE_REQUEST,
} from './constants'

export const fetchAlemiraCourse = courseId => createAction(
  MODEL,
  FETCH_ALEMIRA_COURSE_REQUEST,
  {
    courseId,
    entity: MODEL,
    key   : courseId,
  },
)
