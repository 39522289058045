import axios from 'utils/axios'

import urls from './urls'

export const getCourseLineStat = ({
  schoolId,
  classId,
  courseLineId,
}) => axios.get(
  urls.school(schoolId),
  {
    params: {
      class      : classId,
      course_line: courseLineId,
    },
  },
)
