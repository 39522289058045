import { createAction } from 'utils/store'

import {
  CREATE_INVOICE,
  CREATE_INVOICE_AND_REDIRECT_TO_COURSE,
  MODEL,
  SET_PURCHASE,
} from './constants'

export const createInvoice = data => createAction(MODEL, CREATE_INVOICE, data)
export const createInvoiceAndRedirectToCourse = data => createAction(
  MODEL,
  CREATE_INVOICE_AND_REDIRECT_TO_COURSE,
  data,
)
export const setPurchase = data => createAction(MODEL, SET_PURCHASE, { data })
