import { get } from 'lodash'

import { MODEL } from './constants'

export const selectUser = state => get(state, [MODEL, 'user'])

export const isAuthorized = state => Boolean(selectUser(state))

export const selectIsContentManager = state => Boolean(get(selectUser(state), 'isContentManager'))

export const selectRegisterConfirm = state => get(state, [MODEL, 'registerConfirm'])

export const selectResendConfirm = state => get(state, [MODEL, 'resendConfirm'])

export const selectFreeLogin = state => get(state, [MODEL, 'freeLogin', 'result'])
