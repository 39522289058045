import { MODEL } from './constants'
import effects from './effects'

export default {
  namespace: MODEL,

  state: null,

  effects,

  reducers: null,
}
