import React from 'react'
import ReactDOM from 'react-dom'

/**
 * Расширяет апи оборачиваемого компонента пропом autoFocus.
 * При передаче autoFocus=true фокус в браузере переключается на компоненту.
 */
const withAutoFocus = (WrappedComponent) => {
  class AutoFocused extends React.Component {
    static displayName = `withAutoFocus(${WrappedComponent.displayName})`

    componentDidMount() {
      const { autoFocus } = this.props

      if (autoFocus) {
        this.focus()
      }
    }

    componentDidUpdate(prevProps) {
      const { autoFocus: prevAutoFocus } = prevProps
      const { autoFocus } = this.props

      if (!prevAutoFocus && autoFocus) {
        this.focus()
      }
    }

    refComponent = (component) => {
      this.element = ReactDOM.findDOMNode(component)
    }

    focus() {
      if (this.element && this.element.focus) {
        this.element.focus()
      }
    }

    render() {
      return (
        <WrappedComponent
          ref={this.refComponent}
          {...this.props}
        />
      )
    }
  }

  return AutoFocused
}

export default withAutoFocus
