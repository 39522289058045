import { createAction } from '../../utils/store'
import {
  SET_BLOCK_HEIGHT, MODEL, GET_BLOCKS, SET_BLOCKS,
} from './constants'

export const setBlockHeight = () => createAction(MODEL, SET_BLOCK_HEIGHT)

export const getBlocks = () => createAction(MODEL, GET_BLOCKS)

export const setBlocks = blocks => createAction(MODEL, SET_BLOCKS, { blocks })
