import { compose } from 'lodash/fp'
import { assign, map } from 'lodash'

import getStudentName from 'domains/user/utils/getStudentName'
import snakeToCamel from 'utils/mappers/snakeToCamel'
import createMapper from 'utils/mappers/createMapper'

const readMapper = compose(
  createMapper({
    course: course => ({
      course: assign({}, course, course.group && {
        group: {
          ...course.group,
          students: map(course.group.students, student => ({
            ...student,
            fullName: getStudentName(student),
          })),
        },
      }),
    }),
  }),

  snakeToCamel,
)

export default readMapper
