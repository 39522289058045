import { createAction } from 'utils/store'
import { getKeyForQuery } from 'utils/api'

import {
  MODEL,
  FETCH_COURSE_LINE_STAT_REQUEST,
} from './constants'

export const fetchCourseLineStat = ({
  schoolId,
  classId,
  courseLineId,
}) => createAction(
  MODEL,
  FETCH_COURSE_LINE_STAT_REQUEST,
  {
    entity: MODEL,
    key   : getKeyForQuery({
      schoolId, classId, courseLineId,
    }),
    schoolId,
    classId,
    courseLineId,
  },
)
