import { normalize } from 'normalizr'
import { routerRedux } from 'dva/router'
import { get } from 'lodash'

import { createAction } from 'utils/store'
import { notify } from 'uikit/alemiraComponents/Notification'

import * as api from './api'
import {
  FETCH_ALEMIRA_COURSE_REQUEST,
  FETCH_ALEMIRA_COURSE_SUCCESS,
  FETCH_ALEMIRA_COURSE_FAIL,
} from './constants'
import {
  alemiraCourseSchema,
} from './schema'

function* fetchAlemiraCourse({
  entity,
  key,
  courseId,
}, {
  call,
  put,
}) {
  try {
    const response = yield call(api.getAlemiraCourse, courseId)
    const course = get(response, 'data')
    if (!get(course, 'is_sit_course', false)) {
      yield put(routerRedux.replace('/404/'))
    }

    yield put(createAction(FETCH_ALEMIRA_COURSE_SUCCESS, {
      entity,
      key,
      data: normalize(course, alemiraCourseSchema),
    }))
  } catch (error) {
    yield put(createAction(FETCH_ALEMIRA_COURSE_FAIL, {
      entity,
      key,
      error,
    }))
    notify()
  }
}


export default {
  [FETCH_ALEMIRA_COURSE_REQUEST]: fetchAlemiraCourse,
}
