import { normalize } from 'normalizr'
import { routerRedux } from 'dva/router'
import HttpStatus from 'http-status-codes'
import { get } from 'lodash'
import { t } from 'i18next'

import {
  stopSubmit,
  startSubmit,
  setSubmitSucceeded,
} from 'redux-form'

import { createAction } from 'utils/store'
import { fetchAssignedCourses } from '../assignedCourse'

import * as api from './api'
import {
  FETCH_COURSE_REQUEST,
  FETCH_COURSE_SUCCESS,
  FETCH_COURSE_FAIL,

  FETCH_TEACHER_COURSE_REQUEST,
  FETCH_TEACHER_COURSE_SUCCESS,
  FETCH_TEACHER_COURSE_FAIL,

  FETCH_COURSE_WITH_GROUP_REQUEST,
  FETCH_COURSE_WITH_GROUP_SUCCESS,
  FETCH_COURSE_WITH_GROUP_FAIL,

  JOIN_COURSE,
  JOIN_COURSE_REQUEST,
  JOIN_COURSE_SUCCESS,
  JOIN_COURSE_FAIL,

  SEND_PROMO_CODE_REQUEST,
  SEND_PROMO_CODE_SUCCESS,
  SEND_PROMO_CODE_FAIL,
} from './constants'
import {
  courseSchema,
  courseWithGroupSchema,
  teacherCourseSchema,
  applyPromoCodeSchema,
} from './schema'

function* fetchCourse({
  entity,
  key,
  courseId,
}, {
  call,
  put,
}) {
  try {
    const response = yield call(api.getStudentCourse, courseId)
    const course = get(response, 'data')
    if (get(course, 'is_sit_course', false)) {
      yield put(routerRedux.replace('/404/'))
    }

    yield put(createAction(FETCH_COURSE_SUCCESS, {
      entity,
      key,
      data: normalize(course, courseSchema),
    }))
  } catch (error) {
    yield put(routerRedux.replace('/404/'))
    yield put(createAction(FETCH_COURSE_FAIL, {
      entity,
      key,
      error,
    }))
    if (error.response.status >= HttpStatus.INTERNAL_SERVER_ERROR) {
      throw error
    }
  }
}

function* fetchTeacherCourse({
  entity,
  key,
  courseId,
}, {
  call,
  put,
}) {
  try {
    const response = yield call(api.getTeacherCourse, courseId)
    const course = get(response, 'data')

    yield put(createAction(FETCH_TEACHER_COURSE_SUCCESS, {
      entity,
      key,
      data: normalize(course, teacherCourseSchema),
    }))
  } catch (error) {
    yield put(routerRedux.replace('/404/'))
    yield put(createAction(FETCH_TEACHER_COURSE_FAIL, {
      entity,
      key,
      error,
    }))
    if (error.response.status >= HttpStatus.INTERNAL_SERVER_ERROR) {
      throw error
    }
  }
}


function* fetchCourseWithGroup({
  entity,
  key,
  courseId,
}, {
  call,
  put,
}) {
  try {
    const response = yield call(api.getCourseWithGroup, courseId)
    const courseWithGroup = get(response, 'data')
    yield put(createAction(FETCH_COURSE_WITH_GROUP_SUCCESS, {
      entity,
      key,
      data: normalize(courseWithGroup, courseWithGroupSchema),
    }))
  } catch (error) {
    yield put(routerRedux.replace('/404/'))
    yield put(createAction(FETCH_COURSE_WITH_GROUP_FAIL, {
      entity,
      key,
      error,
    }))
    if (error.response.status >= HttpStatus.INTERNAL_SERVER_ERROR) {
      throw error
    }
  }
}

function* joinCourse(
  { formName, courseCode },
  {
    call, put,
  },
) {
  try {
    if (formName) {
      yield startSubmit(formName)
    }
    yield put(createAction(JOIN_COURSE_REQUEST))
    yield call(api.joinCourse, courseCode)
    if (window.location.pathname !== '/classroom/') {
      yield put(routerRedux.push('/classroom/'))
    } else {
      yield put(fetchAssignedCourses())
    }

    yield put(createAction(JOIN_COURSE_SUCCESS))
    if (formName) {
      yield setSubmitSucceeded(formName)
    }
  } catch (error) {
    const responseStatus = error.response.status
    if (responseStatus === HttpStatus.NOT_FOUND) {
      const errorMessage = t('Course:error_message_not_found')
      if (formName) {
        yield put(stopSubmit(formName, { code: errorMessage }))
      }
      yield put(createAction(JOIN_COURSE_FAIL, {
        data: errorMessage,
      }))
    } else {
      throw error
    }
  }
}

function* sendPromoCode(
  {
    formName,
    course,
    code,
    key,
    entity,
  },
  {
    call, put,
  },
) {
  try {
    yield put(startSubmit(formName))
    const response = yield call(api.sendPromoCode, { course, code })
    const data = get(response, 'data')
    yield put(
      createAction(
        SEND_PROMO_CODE_SUCCESS, {
          entity,
          key,
          data: normalize(data, applyPromoCodeSchema),
        }
      )
    )
    yield put(stopSubmit(formName))
    yield put(setSubmitSucceeded(formName))
  } catch (error) {
    const { response } = error
    const data = get(response, 'data')
    yield put(stopSubmit(formName, { code: t(`PromoCode:${data.code}`) }))

    yield put(createAction(
      SEND_PROMO_CODE_FAIL,
      {
        entity,
        key,
        data: normalize(data, applyPromoCodeSchema),
      }
    ))
  }
}

export default {
  [FETCH_COURSE_REQUEST]           : fetchCourse,
  [FETCH_COURSE_WITH_GROUP_REQUEST]: fetchCourseWithGroup,
  [FETCH_TEACHER_COURSE_REQUEST]   : fetchTeacherCourse,
  [JOIN_COURSE]                    : joinCourse,
  [SEND_PROMO_CODE_REQUEST]        : sendPromoCode,
}
