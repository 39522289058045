import { has, merge } from 'lodash'

import snakeToCamel from 'utils/mappers/snakeToCamel'

const readMapper = (course) => {
  // Если у курса есть группа, то это курс, принадлежащий учителю (или же школе)
  // Для плашки школы передается псевдокурс в id === 'school'
  // остальные курсы - это курсы ученика
  let isForTeacher = false
  let isForStudent = false
  let isSchoolCard = false
  if (has(course, 'group')) {
    isForTeacher = true
  } else if (course.id === 'school') {
    isForTeacher = true
    isSchoolCard = true
  } else {
    isForStudent = true
  }
  return merge(
    snakeToCamel(course),
    {
      isForTeacher,
      isForStudent,
      isSchoolCard,
    }
  )
}

export default readMapper
