import {
  Route,
} from 'dva/router'
import { connect } from 'dva'

import { isAuthorized } from 'domains/user'

import RedirectToClassroom from './RedirectToClassroom'

const AnonymousRoute = connect((state, { component }) => ({
  component: isAuthorized(state) ? RedirectToClassroom : component,
}))(Route)

export default AnonymousRoute
