import { createAction } from 'utils/store'

import {
  MODEL,
  FETCH_COURSE_PROBLEMS_USAGE_REQUEST,
} from './constants'

export const fetchCourseProblemsUsage = ({
  courseId,
  problemIds,
}) => createAction(
  MODEL,
  FETCH_COURSE_PROBLEMS_USAGE_REQUEST,
  {
    entity: MODEL,
    key   : courseId,
    courseId,
    problemIds,
  }
)
