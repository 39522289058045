import PropTypes from 'prop-types'
import React from 'react'

import Modal from 'uikit/components/Modal'

import SignInByQR from './SignInByQR'
import SignInForm from './SignInForm'
import styles from './assets/Modals.module.scss'

const Signin = ({
  isOpen, onClose, stayOnPage,
}) => (
  <Modal
    isOpen={isOpen}
    className={styles.Modal}
    contentClassName={styles.ModalContent}
    onClose={onClose}
  >
    <SignInByQR />
    <SignInForm stayOnPage={stayOnPage} />
  </Modal>
)

Signin.propTypes = {
  isOpen    : PropTypes.bool,
  onClose   : PropTypes.func,
  stayOnPage: PropTypes.bool,
}

export default Signin
