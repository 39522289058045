import { get, find } from 'lodash'

import { selectEntities } from 'utils/api'

import { MODEL } from './constants'

export const selectInfoPage = (state, infoPageSlug) => find(
  selectEntities(state, MODEL),
  ['slug', infoPageSlug],
)

export const selectConfirmGroupSubscriptionResultCode = state => get(
  state,
  [MODEL, 'confirmGroupSubscriptionResultCode'],
)
