import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import { values } from 'lodash'

import {
  BUTTON_THEMES,
  BUTTON_SIZES,
  BUTTON_DISABLED_THEMES,
} from './constants'
import styles from './styles.module.scss'

const THEME_TO_CLASSNAME = {
  [BUTTON_THEMES.CLEAN]            : styles.ThemeClean,
  [BUTTON_THEMES.BLACK]            : styles.ThemeBlack,
  [BUTTON_THEMES.RED]              : styles.ThemeRed,
  [BUTTON_THEMES.BLANK_RED]        : styles.ThemeBlankRed,
  [BUTTON_THEMES.UNIMPORTANT_LIGHT]: styles.ThemeUnimportantLight,
  [BUTTON_THEMES.UNIMPORTANT_DARK] : styles.ThemeUnimportantDark,
  [BUTTON_THEMES.GREEN]            : styles.ThemeGreen,
  [BUTTON_THEMES.YELLOW]           : styles.ThemeYellow,
  [BUTTON_THEMES.WHITE_BORDERED]   : styles.ThemeBorderedWhite,
  [BUTTON_THEMES.WHITE]            : styles.ThemeWhite,
}

const SIZE_TO_CLASSNAME = {
  [BUTTON_SIZES.SMALL] : styles.SizeSmall,
  [BUTTON_SIZES.MEDIUM]: styles.SizeMedium,
  [BUTTON_SIZES.LARGE] : styles.SizeLarge,
}

const DISABLED_THEME_TO_CLASSNAME = {
  [BUTTON_DISABLED_THEMES.LIGHT]: styles.DisabledThemeLight,
  [BUTTON_DISABLED_THEMES.DARK] : styles.DisabledThemeDark,
}

/* eslint-disable react/button-has-type, react/prefer-stateless-function */
class Button extends React.Component {
  static displayName = 'Button'

  static propTypes = {
    size: PropTypes.oneOf(values(BUTTON_SIZES)),

    // Стиль кнопки, влияет на цвет фона и текста кнопки
    theme: PropTypes.oneOf(values(BUTTON_THEMES)),

    // Убрать отступы по бокам надписи на кнопке
    trimLabel: PropTypes.bool,

    // Стиль задизейбленной кнопки
    // dark для случая, когда кнопка на темном фоне
    // light для случая, когда кнопка на светолом фоне
    disabledTheme: PropTypes.oneOf(values(BUTTON_DISABLED_THEMES)),
    fullWidth    : PropTypes.bool,
    icon         : PropTypes.func,
    rightIcon    : PropTypes.func,
  }

  static defaultProps = {
    size         : 'medium',
    theme        : BUTTON_THEMES.YELLOW,
    disabledTheme: 'light',
    fullWidth    : false,
    trimLabel    : false,
  }

  render() {
    const {
      children,
      className,
      disabled,
      disabledTheme,
      fullWidth,
      size,
      theme,
      href,
      trimLabel,
      icon: Icon,
      rightIcon: RightIcon,
      ...props
    } = this.props

    const ControlSpecificProps = {}
    const Control = !href ? 'button' : 'a'
    if (href) {
      ControlSpecificProps.href = href
    }

    return (
      <Control
        {...props}
        {...ControlSpecificProps}
        className={cn(
          styles.Button,
          className,
          THEME_TO_CLASSNAME[theme],
          SIZE_TO_CLASSNAME[size],
          {
            [DISABLED_THEME_TO_CLASSNAME[disabledTheme]]: disabled,
            [styles.FullWidth]                          : fullWidth,
          },
        )}
        disabled={disabled}
      >
        {Icon && <Icon />}
        {children && <span className={cn(trimLabel ? styles.TrimmedLabel : styles.Label)}>{children}</span>}
        {RightIcon && <RightIcon />}
      </Control>
    )
  }
}

export default Button
