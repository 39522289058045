import { get } from 'lodash'

import { MODEL } from './constants'

export const selectIsLoading = state => get(state, [MODEL, 'isLoading'])

export const selectSiteCode = state => get(state, [MODEL, 'code'])

export const selectPromoLink = state => get(state, [MODEL, 'promoLink'])

export const selectSecondLogo = state => get(state, [MODEL, 'secondLogo'])

export const selectI18NextInitialized = state => get(state, [MODEL, 'isI18NextInitialized'])

export const selectIsBackendAvailable = state => get(state, [MODEL, 'isBackendAvailable'])

export const selectBackendUnavailableSince = state => get(state, [MODEL, 'backendUnavailableSince'])

export const selectScrollbarWidth = state => get(state, [MODEL, 'scrollbarWidth'])

export const selectCackleSSOString = state => get(state, [MODEL, 'cackleSSOString'])
