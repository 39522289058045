import {
  get,
  isUndefined,
} from 'lodash'
import HttpStatus from 'http-status-codes'

import { createAction } from 'utils/store'
import * as api from './api'
import {
  GET_ASSIGNMENT_LINK_REQUEST,
  GET_ASSIGNMENT_LINK_FAIL,
  GET_ASSIGNMENT_LINK_SUCCESS,
} from './constants'

function* getAssignmentLink({
  entity,
  key,
  assignmentPath,
  courseId,
}, {
  call,
  put,
}) {
  try {
    const response = yield call(api.getAssignmentLink, assignmentPath, courseId)
    const url = get(response, ['data', 'url'])

    yield put(createAction(GET_ASSIGNMENT_LINK_SUCCESS, {
      entity,
      key,
      data: { [key]: url },
    }))

    window.open(url, '_self')
  } catch (error) {
    if (isUndefined(error.response) || error.response.status >= HttpStatus.INTERNAL_SERVER_ERROR) {
      yield put(createAction(GET_ASSIGNMENT_LINK_FAIL, {
        entity,
        key,
        error,
      }))
      throw error
    }
    yield put(createAction(GET_ASSIGNMENT_LINK_FAIL, {
      entity,
      key,
      error: error.response.data,
    }))
  }
}

export default {
  [GET_ASSIGNMENT_LINK_REQUEST]: getAssignmentLink,
}
