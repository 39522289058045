import {
  selectEntity,
  selectEntities,
} from 'utils/api'
import {
  get,
  isUndefined,
  find,
} from 'lodash'
import {
  MODEL,
  COURSE_LINES_MODEL,
  COURSE_LINE_DATA_MODEL,
  WEEKS_MODEL,
} from './constants'

export const selectSchool = (state, { schoolId }) => selectEntity(state, MODEL, schoolId)

export const selectCourseLine = (state, { courseLineId }) => {
  let defaultCourseLineId
  if (isUndefined(courseLineId)) {
    const currentCourseLine = find(selectEntities(state, COURSE_LINES_MODEL), 'data')
    defaultCourseLineId = get(currentCourseLine, 'id')
  }

  return selectEntity(state, COURSE_LINE_DATA_MODEL, courseLineId || defaultCourseLineId)
}

export const selectCourseLines = state => selectEntities(state, COURSE_LINES_MODEL)
export const selectWeeks = state => selectEntities(state, WEEKS_MODEL)
