import cn from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import {
  Link as RouterLink,
  withRouter,
} from 'dva/router'

import styles from './styles.module.scss'

const THEME_CLASSNAME = {
  clean: styles.ThemeClean,
  dark : styles.ThemeDark,
  light: styles.ThemeLight,
}

const Link = ({
  className,
  isRouterLink,
  keepQuerystring,
  pseudo,
  theme,
  to,
  location: {
    search,
  },
  // деструктуризирую параметр, чтобы он дальше не передавался и не вызывал варнинг
  // смотри https://github.com/ReactTraining/react-router/issues/4683
  staticContext,
  ...props
}) => {
  if (isRouterLink) {
    const destination = keepQuerystring
      ? {
        pathname: to,
        search,
      }
      : to

    return (
      <RouterLink
        {...props}
        to={destination}
        className={cn(
          styles.Link,
          className,
          THEME_CLASSNAME[theme],
        )}
      />
    )
  }

  let Tag

  if (pseudo) {
    Tag = 'span'
  } else {
    Tag = 'a'
    props.href = to
  }

  return (
    <Tag
      {...props}
      className={cn(
        styles.Link,
        className,
        THEME_CLASSNAME[theme],
      )}
    />
  )
}

Link.propTypes = {
  isRouterLink   : PropTypes.bool,
  keepQuerystring: PropTypes.bool,
  pseudo         : PropTypes.bool,
  theme          : PropTypes.oneOf([
    'clean',
    'dark',
    'light',
    'custom',
  ]),
}

Link.defaultProps = {
  isRouterLink   : true,
  keepQuerystring: false,
  theme          : 'dark',
}

export default withRouter(Link)
