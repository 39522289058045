import { FETCH_ASSIGNED_COURSES_SUCCESS, EXIT_FROM_COURSE_SUCCESS } from './constants'

export default {
  [EXIT_FROM_COURSE_SUCCESS](state, action) {
    return {
      ...state,
      coursesForStudent: state.coursesForStudent.filter(id => id !== action.courseId),
    }
  },
  [FETCH_ASSIGNED_COURSES_SUCCESS](state, action) {
    return {
      ...state,
      coursesForStudent: action.coursesForStudent,
      coursesForTeacher: action.coursesForTeacher,
    }
  },
}
