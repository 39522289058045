import {
  concat,
  get,
  map,
} from 'lodash'

import { readMapper as problemReadMapper } from 'domains/problem/adapters'
import { fetchCourseProblemsUsage } from 'domains/courseProblemsUsage'

import * as api from './api'
import {
  FETCH_LIBRARY_FILTERS,
  FETCH_LIBRARY_PROBLEMS,
  FETCH_MODES,
} from './constants'
import { readMapper } from './adapters'
import { setLibraryData } from './actions'
import { selectLibraryProblems } from './selectors'

function* fetchLibraryFilters({ courseId }, { call, put }) {
  let response = yield call(api.getCourses)
  const courses = readMapper(response.data)
  response = yield call(api.getCourse, courseId)
  const lessons = map(
    get(readMapper(response.data), 'courseLessons', []),
    courseLesson => courseLesson.lesson
  )
  yield put(setLibraryData({
    courses,
    lessons,
  }))
}

function* fetchLibraryProblems({
  currentCourseId,
  currentLessonId,
  selectedLesson,
  mode,
  page,
}, {
  call,
  put,
  select,
}) {
  try {
    if (mode === FETCH_MODES.APPEND) {
      yield put(setLibraryData({
        loadingNext: true,
      }))
    } else {
      yield put(setLibraryData({
        loading: true,
      }))
    }
    const response = yield call(api.getProblems, currentCourseId, currentLessonId, selectedLesson, page)
    let newProblems = map(response.data.results, problemReadMapper)
    if (mode === FETCH_MODES.APPEND) {
      newProblems = concat(yield select(selectLibraryProblems), newProblems)
    }
    yield put(setLibraryData({
      problems   : newProblems,
      total      : response.data.count,
      next       : response.data.next,
      loadingNext: false,
      loading    : false,
    }))

    yield put(fetchCourseProblemsUsage({
      courseId  : currentCourseId,
      problemIds: map(newProblems, problem => problem.id),
    }))
  } catch (error) {
    yield put(setLibraryData({
      loadingNext: false,
      loading    : false,
    }))
    throw error
  }
}

export default {
  [FETCH_LIBRARY_FILTERS] : fetchLibraryFilters,
  [FETCH_LIBRARY_PROBLEMS]: fetchLibraryProblems,
}
