import { isUndefined, get } from 'lodash'
import React from 'react'
import cn from 'classnames'
import { compose } from 'recompose'
import { connect } from 'dva'
import { withRouter } from 'dva/router'
import {
  reduxForm,
  getFormMeta,
} from 'redux-form'
import { t } from 'i18next'
import { Trans } from 'react-i18next'

import Button from 'uikit/components/Button'
import camelToSnake from 'utils/mappers/camelToSnake'
import FormField from 'uikit/components/FormField'
import Input from 'uikit/components/Input'
import Link from 'uikit/components/Link'
import SelectionControlGroup from 'uikit/components/SelectionControlGroup'
import Spinner from 'uikit/components/Spinner'
import { createUser } from 'domains/user'
import {
  MediumHeader,
  Paragraph,
} from 'uikit/components/Typography'
import {
  formatText,
  isEmail,
} from 'utils/validator'

import styles from './assets/Forms.module.scss'

const LINKS = {
  TERMS_OF_SERVICE: '/pages/policy/',
  RULES           : '/pages/rules',
  OFERTA          : '/pages/oferta/',
}

export const FORM_NAME = 'registerTeacher'

const RegisterTeacherForm = ({
  handleSubmit,
  submitting,
  error,
  submitFailed,
  submitSucceeded,
}) => (
  <React.Fragment>
    {submitting && (
      <div className={cn(styles.SpinnerContainer, styles.FullHeightSpinner)}>
        <Spinner />
      </div>
    )}

    {submitSucceeded
    && !submitting && (
      <div className={styles.Form}>
        <MediumHeader className={styles.Header}>
          {t('Auth:header_almost_done')}
        </MediumHeader>
        <Paragraph>
          {t('Auth:almost_done_text')}
        </Paragraph>
      </div>
    )}

    {!submitSucceeded && (
      <form className={styles.Form} onSubmit={handleSubmit}>
        <MediumHeader>
          {t('Auth:teacher_registration')}
        </MediumHeader>
        <div className={styles.ErrorMessage}>{error && submitFailed && <span>{error}</span>}</div>
        <FormField
          component={Input}
          name="firstName"
          label={t('Auth:name')}
          fashion="slim"
          fullWidth
          format={formatText}
        />
        <br />
        <br />
        <FormField
          component={Input}
          name="lastName"
          label={t('Auth:surname')}
          fashion="slim"
          fullWidth
          format={formatText}
        />
        <br />
        <br />
        <FormField
          component={Input}
          name="username"
          label={t('Auth:login')}
          fashion="slim"
          fullWidth
          format={formatText}
        />
        <br />
        <br />
        <FormField
          component={Input}
          name="email"
          type="email"
          label={t('Auth:email')}
          fashion="slim"
          fullWidth
          format={formatText}
        />
        <br />
        <br />
        <FormField
          component={Input}
          name="password"
          label={t('Auth:password')}
          fashion="slim"
          type="password"
          fullWidth
          format={formatText}
        />
        <div className={styles.AgreementCheckboxWrapper}>
          <FormField
            className={styles.AgreementCheckbox}
            component={SelectionControlGroup}
            controlType="checkbox"
            valueKey="value"
            name="acceptTerms"
            simpleValue
            options={
              [{
                value: true,
              }]
            }
            errorTopMargin="37px"
          />
          <p>
            <Trans i18nKey="Auth:accept_personal">
              <Link to={LINKS.TERMS_OF_SERVICE} />
              <Link to={LINKS.RULES} />
              <Link to={LINKS.OFERTA} />
            </Trans>
          </p>
        </div>

        <Button
          type="submit" fullWidth
          onClick={handleSubmit} disabled={submitting}
        >
          {t('Auth:register')}
        </Button>
      </form>
    )}
  </React.Fragment>
)

const validate = ({
  firstName, lastName, email, password, acceptTerms, username,
}, { formMeta }) => {
  const errors = {}
  let errorChosen = false

  if (!firstName) {
    errors.firstName = {
      text: t('Auth:enter_name'),
      show: !errorChosen && get(formMeta, 'firstName.touched'),
    }
    errorChosen = errorChosen || get(formMeta, 'firstName.touched')
  }
  if (!lastName) {
    errors.lastName = {
      text: t('Auth:enter_surname'),
      show: !errorChosen && get(formMeta, 'lastName.touched'),
    }
    errorChosen = errorChosen || get(formMeta, 'lastName.touched')
  }
  if (!username) {
    errors.username = {
      text: t('Auth:enter_login'),
      show: !errorChosen && get(formMeta, 'username.touched'),
    }
    errorChosen = errorChosen || get(formMeta, 'username.touched')
  }
  if (!(email && isEmail(email))) {
    errors.email = {
      text: t('Auth:enter_email'),
      show: !errorChosen && get(formMeta, 'email.touched'),
    }
    errorChosen = errorChosen || get(formMeta, 'email.touched')
  }
  if (!password) {
    errors.password = {
      text: t('Auth:enter_password'),
      show: !errorChosen && get(formMeta, 'password.touched'),
    }
    errorChosen = errorChosen || get(formMeta, 'password.touched')
  }
  if (isUndefined(acceptTerms) || acceptTerms.length === 0) {
    errors.acceptTerms = {
      text: t('Auth:enter_accept'),
      show: !errorChosen && (get(formMeta, 'acceptTerms.visited') || get(formMeta, 'acceptTerms.touched')),
    }
    errorChosen = errorChosen || get(formMeta, 'acceptTerms.visited') || get(formMeta, 'acceptTerms.touched')
  }

  return errors
}

const onSubmit = (values, dispatch, { form, location: { query: { next } } }) => {
  const credentials = {
    ...values,
    acceptTerms: values.acceptTerms[0],
  }
  dispatch(
    createUser({
      meta        : { form },
      personalInfo: camelToSnake(credentials),
      next,
    }),
  )
}

const selectFormMeta = getFormMeta(FORM_NAME)

const enhance = compose(
  withRouter,
  connect(state => ({
    formMeta: selectFormMeta(state),
  })),
  reduxForm({
    form: FORM_NAME,
    validate,
    onSubmit,
  }),
)

export default enhance(RegisterTeacherForm)
