import { createAction } from 'utils/store'

import {
  MODEL,
  DISPLAY_ADD_POINTS_ANIMATION,
  HIDE_ADD_POINTS_ANIMATION,
  DISPLAY_LOSE_POINTS_ANIMATION,
  HIDE_LOSE_POINTS_ANIMATION,
} from './constants'

export const displayAddPointsAnimation = points => createAction(
  MODEL,
  DISPLAY_ADD_POINTS_ANIMATION,
  { points }
)

export const hideAddPointsAnimation = () => createAction(MODEL, HIDE_ADD_POINTS_ANIMATION)

export const displayLosePointsAnimation = points => createAction(
  MODEL,
  DISPLAY_LOSE_POINTS_ANIMATION,
  { points }
)

export const hideLosePointsAnimation = () => createAction(MODEL, HIDE_LOSE_POINTS_ANIMATION)
