import { sumBy, values } from 'lodash'
import { compose } from 'lodash/fp'

import snakeToCamel from 'utils/mappers/snakeToCamel'
import createMapper from 'utils/mappers/createMapper'

const readMapper = compose(
  createMapper({
    totalPoints: 'maxPoints',
    lessons    : lessons => ({
      lessons,
      points: sumBy(values(lessons), 'points'),
    }),
  }),

  snakeToCamel,
)

export default readMapper
