import {
  get,
  find,
  isUndefined,
} from 'lodash'

import {
  selectEntity,
  selectEntities,
  selectEntitiesSubstate,
} from 'utils/api'
import {
  MODEL,
  CLASS_MODEL,
  COURSE_LINES_MODEL,
  COURSE_LINE_DATA_MODEL,
} from './constants'

export const selectCourseLineStat = (
  state, { schoolId, classId }
) => selectEntity(state, MODEL, `${schoolId}-${classId}`)

export const selectCourseLineEntities = state => selectEntitiesSubstate(state)

export const selectCourseLines = state => selectEntities(state, COURSE_LINES_MODEL)

export const selectCurrentCourseLineStat = (state, {
  schoolId, classId, courseLineId,
}) => {
  let defaultCourseLineId
  if (isUndefined(courseLineId)) {
    const currentCourseLine = find(selectEntities(state, COURSE_LINES_MODEL), 'data')
    defaultCourseLineId = get(currentCourseLine, 'id')
  }
  const currentCourseLineKey = `${schoolId}-${classId}-${courseLineId || defaultCourseLineId}`

  return selectEntity(state, COURSE_LINE_DATA_MODEL, currentCourseLineKey)
}

export const selectCurrentClass = (state, {
  classId,
}) => selectEntity(state, CLASS_MODEL, classId)
