import { createAction } from 'utils/store'

import {
  MODEL,
  UPLOAD_FILE_REQUEST,
} from './constants'

export const uploadFile = (key, file) => createAction(
  MODEL,
  UPLOAD_FILE_REQUEST,
  {
    file,
    key,
    entity: MODEL,
  },
)
