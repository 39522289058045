import axios from 'utils/axios'

import urls from './urls'

export const getCourseFindProblems = params => axios.get(
  urls.courseFindProblems(params),
  {
    params: {
      problems: params.problemIds.join(),
    },
  }
)
