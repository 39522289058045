import { compose } from 'recompose'
import snakeToCamel from 'utils/mappers/snakeToCamel'
import createMapper from 'utils/mappers/createMapper'

import {
  COURSE_LINES_MODEL,
  WEEKS_MODEL,
} from '../constants'

const mapKeys = createMapper({
  courseLines: COURSE_LINES_MODEL,
  weeks      : WEEKS_MODEL,
})

const readMapper = compose(
  mapKeys,
  snakeToCamel
)

export default readMapper
