import {
  setBackendUnavailable,
  setBackendAvailable,
} from 'domains/app'

import * as api from './api'
import { UPDATE_USER_STATUS } from './constants'

function* updateUserStatus(data, { call, put }) {
  try {
    yield call(api.updateUserStatus, data)
    yield put(setBackendAvailable())
  } catch (err) {
    yield put(setBackendUnavailable())
  }
}

export default {
  [UPDATE_USER_STATUS]: updateUserStatus,
}
