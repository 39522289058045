export const MODEL = 'courseLessonStats'

export const STUDENT_RESULT_MEDALS = {
  BRONZE: 'bronze',
  GOLD  : 'gold',
  SILVER: 'silver',
}

// Action types
export const FETCH_COURSE_LESSON_STATS_REQUEST = 'FETCH_COURSE_LESSON_STATS_REQUEST'
export const FETCH_COURSE_LESSON_STATS_SUCCESS = 'FETCH_COURSE_LESSON_STATS_SUCCESS'
export const FETCH_COURSE_LESSON_STATS_FAIL = 'FETCH_COURSE_LESSON_STATS_FAIL'
