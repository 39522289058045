import { reducer as formReducer } from 'redux-form'
import * as Sentry from '@sentry/browser'
import ReactDOM from 'react-dom'
import smoothscroll from 'smoothscroll-polyfill'
import 'utils/canvasDrawImageFix'
import 'normalize.css'
import { isTouchDevice } from 'utils/device'

import {
  API_STATE_NAME,
  ENTITIES_STATE_NAME,
  apiReducer,
  entitiesReducer,
} from 'utils/api'
import { createApp } from 'domains/app'
import { model as animationModel } from 'domains/animation'
import { model as audioModel } from 'domains/audio'
import { model as courseModel } from 'domains/course'
import { model as courseStatsModel } from 'domains/courseStats'
import { model as userModel } from 'domains/user'
import { model as courseLessonModel } from 'domains/courseLesson'
import { model as courseLessonStatsModel } from 'domains/courseLessonStats'
import { model as courseLessonResultModel } from 'domains/courseLessonResult'
import { model as promoBlocksModel } from 'domains/blocks'
import { model as AssignedCourseModel } from 'domains/assignedCourse'
import { model as infoPageModel } from 'domains/infoPage'
import { model as payment } from 'domains/payment'
import { model as userStatus } from 'domains/userStatus'
import { model as schoolModel } from 'domains/school'
import { model as courseLineStat } from 'domains/courseLineStat'
import { model as ymModel } from 'domains/ym'
import { model as courseProblemsUsageModel } from 'domains/courseProblemsUsage'
import { model as problemsLibraryModel } from 'domains/problemsLibrary'
import { model as alemiraCourseModel } from 'domains/alemiraCourse'
import { model as uploadModel } from 'domains/upload'
import { model as cocalcModel } from 'domains/cocalc'
import { applyAppearance } from 'utils/appearance'

import TouchBackend from 'react-dnd-touch-backend'
import HTML5Backend from 'react-dnd-html5-backend'
import { DragDropContextProvider } from 'react-dnd'
import React from 'react'
import createRouter from './router'
import { isProduction } from './environment'

smoothscroll.polyfill()

if (isProduction) {
  Sentry.init({ dsn: 'https://52c1c7d45df34d42805bdb3b34826a46@sentry.io/1472404' })
}

const app = createApp({
  extraReducers: {
    [API_STATE_NAME]     : apiReducer,
    [ENTITIES_STATE_NAME]: entitiesReducer,
    form                 : formReducer,
  },
})

app.router(createRouter(app))

app.model(animationModel)
app.model(audioModel)
app.model(courseModel)
app.model(courseStatsModel)
app.model(userModel)
app.model(courseLessonModel)
app.model(courseLessonStatsModel)
app.model(courseLessonResultModel)
app.model(promoBlocksModel)
app.model(AssignedCourseModel)
app.model(infoPageModel)
app.model(payment)
app.model(userStatus)
app.model(schoolModel)
app.model(courseLineStat)
app.model(ymModel)
app.model(courseProblemsUsageModel)
app.model(problemsLibraryModel)
app.model(alemiraCourseModel)
app.model(uploadModel)
app.model(cocalcModel)

applyAppearance()

const App = app.start()

// https://github.com/react-dnd/react-dnd/issues/186#issuecomment-453990887
// https://github.com/dvajs/dva/blob/master/docs/API.md#appstartselector
ReactDOM.render(
  <DragDropContextProvider
    backend={isTouchDevice ? TouchBackend : HTML5Backend}
  >
    <App />
  </DragDropContextProvider>,
  document.querySelector('#root')
)
