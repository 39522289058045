import { merge } from 'lodash/fp'

import {
  HIDE_ADD_POINTS_ANIMATION,
  DISPLAY_ADD_POINTS_ANIMATION,
  HIDE_LOSE_POINTS_ANIMATION,
  DISPLAY_LOSE_POINTS_ANIMATION,
} from './constants'

export default {
  [DISPLAY_ADD_POINTS_ANIMATION](state, { points }) {
    return merge(
      state,
      {
        lessonRun: {
          pointsAnimations: {
            addPoints: {
              display: true,
              points,
            },
          },
        },
      }
    )
  },

  [HIDE_ADD_POINTS_ANIMATION](state) {
    return merge(
      state,
      {
        lessonRun: {
          pointsAnimations: {
            addPoints: {
              display: false,
              points : 0,
            },
          },
        },
      }
    )
  },

  [DISPLAY_LOSE_POINTS_ANIMATION](state, { points }) {
    return merge(
      state,
      {
        lessonRun: {
          pointsAnimations: {
            losePoints: {
              display: true,
              points,
            },
          },
        },
      }
    )
  },

  [HIDE_LOSE_POINTS_ANIMATION](state) {
    return merge(
      state,
      {
        lessonRun: {
          pointsAnimations: {
            losePoints: {
              display: false,
              points : 0,
            },
          },
        },
      }
    )
  },
}
