export const MODEL = 'courses'
export const PURCHASE_MODEL = 'purchaseOffers'
export const PROMOCODE_MODEL = 'promoCodeData'
export const COURSE_WITH_GROUP_MODEL = 'coursesWithGroup'

export const INITIAL_STATE = {
  сourseLessonHintId: null,
}

export const PROMOCODE_KEY = 'tempKey'

// Action types
export const SET_COURSE_LESSON_HINT_ID = 'SET_SHOW_COURSE_LESSON_HINT'

export const FETCH_COURSE_REQUEST = 'FETCH_COURSE_REQUEST'
export const FETCH_COURSE_SUCCESS = 'FETCH_COURSE_SUCCESS'
export const FETCH_COURSE_FAIL = 'FETCH_COURSE_FAIL'

export const FETCH_TEACHER_COURSE_REQUEST = 'FETCH_TEACHER_COURSE_REQUEST'
export const FETCH_TEACHER_COURSE_SUCCESS = 'FETCH_TEACHER_COURSE_SUCCESS'
export const FETCH_TEACHER_COURSE_FAIL = 'FETCH_TEACHER_COURSE_FAIL'

export const JOIN_COURSE = 'JOIN_COURSE'
export const JOIN_COURSE_REQUEST = 'JOIN_COURSE_REQUEST'
export const JOIN_COURSE_SUCCESS = 'JOIN_COURSE_SUCCESS'
export const JOIN_COURSE_FAIL = 'JOIN_COURSE_FAIL'

export const COURSE_HAS_NOT_GROUP = 'COURSE_HAS_NOT_GROUP'

export const SEND_PROMO_CODE_REQUEST = 'SEND_PROMO_CODE_REQUEST'
export const SEND_PROMO_CODE_SUCCESS = 'SEND_PROMO_CODE_SUCCESS'
export const SEND_PROMO_CODE_FAIL = 'SEND_PROMO_CODE_FAIL'

export const FETCH_COURSE_WITH_GROUP_REQUEST = 'FETCH_COURSE_WITH_GROUP_REQUEST'
export const FETCH_COURSE_WITH_GROUP_SUCCESS = 'FETCH_COURSE_WITH_GROUP_SUCCESS'
export const FETCH_COURSE_WITH_GROUP_FAIL = 'FETCH_COURSE_WITH_GROUP_FAIL'

export const SET_REFETCH_COURSE = 'SET_REFETCH_COURSE'
