import cn from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import ReactDOM from 'react-dom'
import {
  get,
  find,
  findIndex,
  has,
  map,
  noop,
} from 'lodash'

import SelectionControl from 'uikit/components/SelectionControl'
import controlStyles from 'uikit/components/SelectionControl/styles.module.scss'

import styles from './styles.module.scss'

const KIND_TO_CLASSNAME = {
  button: styles.KindButton,
}

const ERROR_BOX_WIDTH = 250

class SelectionControlGroup extends React.Component {
  static propTypes = {
    controlType: PropTypes.oneOf([
      'checkbox',
      'radio',
    ]),
    onChange: PropTypes.func,
  }

  static defaultProps = {
    onChange: noop,
  }

  state = {
    errorOnLeft: true,
  }

  componentDidMount() {
    const element = this.control
    const position = element.getBoundingClientRect()
    const isSpaceEnough = (position.x - ERROR_BOX_WIDTH) > 0
    this.setState({
      errorOnLeft: isSpaceEnough,
    })
  }

  refControl = (component) => {
    this.control = ReactDOM.findDOMNode(component)
  }

  onItemChecked = (checkedValue) => {
    const {
      controlType,
      disabled,
      onChange,
      simpleValue,
      valueKey,
      value: prevValue,
    } = this.props

    if (disabled) return

    let newValue

    switch (controlType) {
      case 'checkbox': {
        newValue = [...(prevValue || [])]
        const index = findIndex(prevValue, valueItem => (
          (simpleValue ? get(valueItem, valueKey, valueItem) : valueItem) === checkedValue
        ))

        if (index > -1) {
          newValue.splice(index, 1)
        } else {
          newValue.push(checkedValue)
        }
        break
      }

      case 'radio':
        newValue = simpleValue ? get(checkedValue, valueKey, checkedValue) : checkedValue
        break
    }

    onChange(newValue)
  }

  render() {
    const {
      className,
      controlType,
      disabled,
      inline,
      options,
      value,
      kind,
      simpleValue,
      valueKey,
      meta,
      errorTopMargin,
      ...selectionControlProps
    } = this.props
    const { errorOnLeft } = this.state

    const touched = get(meta, 'touched') || get(meta, 'visited')
    const error = get(meta, 'error')

    return (
      <span
        className={cn(
          styles.SelectionControlGroup,
          KIND_TO_CLASSNAME[kind],
          {
            [styles.Inline]: inline,
          },
          className,
        )}
        ref={this.refControl}
      >
        {map(options, (option, index) => {
          const optionValue = simpleValue
            ? get(option, valueKey, option)
            : option

          let checked = false

          switch (controlType) {
            case 'checkbox':
              checked = find(value, valueItem => (
                (simpleValue ? get(valueItem, valueKey, valueItem) : valueItem) === optionValue
              ))
              break

            case 'radio':
              checked = (simpleValue ? get(value, valueKey, value) : value) === optionValue
              break
          }

          return (
            <SelectionControl
              {...selectionControlProps}
              key={index}
              kind={kind}
              controlType={controlType}
              checked={checked}
              className={cn(
                styles.Item,
                {
                  [controlStyles.Error]: touched && !!error,
                },
              )}
              inline={inline}
              simpleValue={simpleValue}
              // FIXME: Warning: A component is changing an uncontrolled input of type checkbox to be controlled.
              // hint: option -> optionValue
              value={option}
              valueKey={valueKey}
              onChange={this.onItemChecked}
            />
          )
        })}
        {touched
          && ((has(error, 'show') ? error.show : error)) && (
          <span
            className={cn(styles.ErrorMessage, { [styles.LeftPosition]: errorOnLeft })}
            style={{ marginTop: errorOnLeft ? 0 : errorTopMargin }}
          >
            {has(error, 'show') ? error.text : error}
          </span>
        )}
      </span>
    )
  }
}

export default SelectionControlGroup
