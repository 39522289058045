import {
  GET_ASSIGNMENT_LINK_FAIL,
  ERROR_KEY,
} from './constants'

export default {
  [GET_ASSIGNMENT_LINK_FAIL](state, action) {
    return {
      ...state,
      [ERROR_KEY]: action.error,
    }
  },
}
