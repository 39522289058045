import { createAction } from 'utils/store'
import { notify as baseNotify } from 'uikit/components/Notification'

import {
  MODEL,
  BOOTSTRAP_APPLICATION,
  LOAD_SITE_CONFIG,
  SET_I18NEXT_INITIALIZED,
  SET_BACKEND_AVAILABLE,
  SET_BACKEND_UNAVAILABLE,
  SET_SCROLLBAR_WIDTH,
  FETCH_CACKLE_SSO_STRING,
  SET_CACKLE_SSO_STRING,
} from './constants'

export const notify = options => baseNotify(options)

export const bootstrapApplication = () => createAction(MODEL, BOOTSTRAP_APPLICATION)

export const loadSiteConfig = () => createAction(MODEL, LOAD_SITE_CONFIG)

export const setI18NextInitialized = () => createAction(MODEL, SET_I18NEXT_INITIALIZED, { isI18NextInitialized: true })

export const setBackendUnavailable = () => createAction(
  MODEL,
  SET_BACKEND_UNAVAILABLE,
  { backendUnavailableSince: Date.now() }
)

export const setBackendAvailable = () => createAction(MODEL, SET_BACKEND_AVAILABLE)

export const setScrollbarWidth = scrollbarWidth => createAction(SET_SCROLLBAR_WIDTH, { scrollbarWidth })

export const fetchCackleSSOString = () => createAction(MODEL, FETCH_CACKLE_SSO_STRING)

export const setCackleSSOString = ssoString => createAction(MODEL, SET_CACKLE_SSO_STRING, { ssoString })
