import { t } from 'i18next'
import { get } from 'lodash'
import { routerRedux } from 'dva/router'

import { createAction } from 'utils/store'
import {
  MODEL as COURSE_MODEL,
  SET_REFETCH_COURSE,
} from '../course/constants'
import {
  MODEL as COURSE_STATS_MODEL,
  SET_REFETCH_COURSE_STATS,
} from '../courseStats/constants'
import * as api from './api'
import {
  CREATE_INVOICE,
  CREATE_INVOICE_AND_REDIRECT_TO_COURSE,
} from './constants'

function* createInvoice({
  purchase,
  email,
  promocode,
  onError,
  course,
}, {
  call,
  put,
}) {
  const data = (
    promocode
      ? {
        purchase,
        email,
        promocode,
        course,
      }
      : {
        purchase,
        email,
        course,
      }
  )
  try {
    const response = yield call(api.createInvoice, data)
    if (!get(response, ['data', 'redirect_url'])) {
      yield put(createAction(
        COURSE_MODEL,
        SET_REFETCH_COURSE
      ))
      yield put(createAction(
        COURSE_STATS_MODEL,
        SET_REFETCH_COURSE_STATS
      ))
    } else {
      window.open(response.data.redirect_url, '_self')
    }
  } catch {
    if (onError) {
      onError()
    } else {
      throw Error(t('Course:error_message_try_reload'))
    }
  }
}

function* createInvoiceAndRedirectToCourse(data, { call, put }) {
  try {
    yield call(api.createInvoice, data)
  } catch {
    throw Error(t('Course:error_message_try_reload'))
  }
  yield put(createAction(
    COURSE_MODEL,
    SET_REFETCH_COURSE
  ))
  yield put(createAction(
    COURSE_STATS_MODEL,
    SET_REFETCH_COURSE_STATS
  ))
  yield put(routerRedux.push(`/classroom/${data.course}/`))
}

export default {
  [CREATE_INVOICE]                       : createInvoice,
  [CREATE_INVOICE_AND_REDIRECT_TO_COURSE]: createInvoiceAndRedirectToCourse,
}
