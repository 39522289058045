import { MODEL } from './constants'
import effects from './effects'
import reducers from './reducers'

export default {
  namespace: MODEL,

  state: null,

  reducers,

  effects,
}
