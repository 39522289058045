import {
  Route,
} from 'dva/router'
import { connect } from 'dva'

import { isAuthorized } from 'domains/user'

import RedirectToHome from './RedirectToHome'

const ProtectedRoute = connect((state, { component }) => ({
  component: isAuthorized(state) ? component : RedirectToHome,
}))(Route)

export default ProtectedRoute
