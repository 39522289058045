import {
  SET_BLOCK_HEIGHT, SET_BLOCKS, BLOCK_MARGIN_BOTTOM,
} from './constants'

export default {
  [SET_BLOCK_HEIGHT](state) {
    return { ...state, blockHeight: window.innerHeight - BLOCK_MARGIN_BOTTOM }
  },
  [SET_BLOCKS](state, action) {
    return { ...state, blocks: [...state.blocks, ...action.blocks] }
  },
}
