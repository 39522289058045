import PropTypes from 'prop-types'
import React from 'react'
import { withRouter } from 'dva/router'

import Link from 'uikit/components/Link'
import Modal from 'uikit/components/Modal'
import { Trans } from 'react-i18next'

import SignUpForm from './RegisterUserForm'
import styles from './assets/Modals.module.scss'
import { SIGN_IN_HASH } from './route-constants'

const RegisterUserModal = ({
  isOpen, onClose, location, notifyWithMessage,
}) => (
  <Modal
    isOpen={isOpen}
    className={styles.Modal}
    contentClassName={styles.ModalContent}
    onClose={onClose}
    preHeader={(
      <div className={styles.PreHeader}>
        <Trans i18nKey="Auth:enter_if_with_us">
          <Link theme="light" to={`${location.search}${SIGN_IN_HASH}`} />
        </Trans>
      </div>
    )}
  >
    <SignUpForm
      notifyWithMessage={notifyWithMessage}
    />
  </Modal>
)

RegisterUserModal.propTypes = {
  isOpen           : PropTypes.bool,
  onClose          : PropTypes.func,
  notifyWithMessage: PropTypes.bool,
}

export default withRouter(RegisterUserModal)
