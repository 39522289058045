export const MODEL = 'user'

export const OUTPUT_IMAGE_EXTENSION = 'png'

export const INITIAL_STATE = {
  user: null,
}

// Action types
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD'
export const FETCH_USER = 'FETCH_USER'
export const LOGIN = 'LOGIN'
export const LOGOUT = 'LOGOUT'
export const CREATE_USER = 'CREATE_USER'
export const ENTER_STUDENT_NAME = 'ENTER_STUDENT_NAME'
export const RESET_PASSWORD = 'RESET_PASSWORD'
export const RESET_PASSWORD_CONFIRM = 'RESET_PASSWORD_CONFIRM'
export const SET_USER = 'SET_USER'
export const REMOVE_USER = 'REMOVE_USER'
export const UPDATE_USER = 'UPDATE_USER'
export const RESEND_CONFIRM = 'RESEND_CONFIRM'
export const RESEND_CONFIRM_REQUEST = 'RESEND_CONFIRM_REQUEST'
export const RESEND_CONFIRM_SUCCESS = 'RESEND_CONFIRM_SUCCESS'
export const RESEND_CONFIRM_FAIL = 'RESEND_CONFIRM_FAIL'
export const REGISTER_CONFIRM = 'REGISTER_CONFIRM'
export const REGISTER_CONFIRM_REQUEST = 'REGISTER_CONFIRM_REQUEST'
export const REGISTER_CONFIRM_SUCCESS = 'REGISTER_CONFIRM_SUCCESS'
export const REGISTER_CONFIRM_EXPIRED = 'REGISTER_CONFIRM_EXPIRED'

export const GET_AVAILABLE_LOGIN = 'GET_AVAILABLE_LOGIN'
export const AVAILABLE_LOGIN_REQUEST = 'AVAILABLE_LOGIN_REQUEST'
export const AVAILABLE_LOGIN_SUCCESS = 'AVAILABLE_LOGIN_SUCCESS'
export const AVAILABLE_LOGIN_FAIL = 'AVAILABLE_LOGIN_FAIL'

export const SEND_PURCHASE_REGISTER_CONFIRM = 'SEND_PURCHASE_REGISTER_CONFIRM'
export const AUTHORIZE_WITH_GOOGLE = 'AUTHORIZE_WITH_GOOGLE'
export const AUTHORIZE_WITH_FACEBOOK = 'AUTHORIZE_WITH_FACEBOOK'

// Other constants
export const NEXT_PAGE_AFTER_SOCIAL_AUTH_KEY = 'nextPageAfterSocialAuth'
